import { FC, useEffect, useRef, useState } from 'react';
import { defaultFn } from 'destiny/dist/constants';
import { COLORS } from 'destiny/dist/constants/colors';
import { useOnClickOutside } from 'hooks';
import SelectedAccounts from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedAccounts';
import AccountsByEntitySelector from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/accountsByEntitySelector/AccountsByEntitySelector';
import SelectSourceAccountSkeleton from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/SelectSourceAccountSkeleton';
import { MapAny } from 'types';
import { InternalTransferAccountType, InternalTransferToEntityType } from 'types/internalTransferApi.types';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

interface AccountSelectorProps {
  entityAccounts?: InternalTransferToEntityType[];
  selectedAccount?: any;
  onAccountSelect?: (payload: MapAny) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isZeroState?: boolean;
  isRecipientAccount?: boolean;
  isSourceAccount?: boolean;
  openDropdown?: boolean;
  openDropdownId?: string;
  accountCardWrapperClassName?: string;
  skeletonClassName?: string;
}

const AccountSelector: FC<AccountSelectorProps> = ({
  entityAccounts,
  selectedAccount,
  onAccountSelect = defaultFn,
  isLoading,
  isDisabled,
  isZeroState,
  isRecipientAccount,
  isSourceAccount,
  openDropdown,
  openDropdownId,
  accountCardWrapperClassName,
  skeletonClassName = '',
}) => {
  const [showAccountsDropdown, setShowAccountsDropdown] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setShowAccountsDropdown(false));

  const handleAccountSelect = (
    selectedEntityData: InternalTransferToEntityType,
    selectedAccountData: InternalTransferAccountType
  ) => {
    setShowAccountsDropdown(false);
    onAccountSelect({
      ...selectedAccountData,
      name: selectedAccountData?.display_name,
      masked_number: selectedAccountData?.masked_account_number,
      parentEntity: { id: selectedEntityData?.id, name: selectedEntityData?.display_name },
    });
  };

  const handleShowAccountsDropdown = () => setShowAccountsDropdown((prevView) => !prevView);

  useEffect(() => {
    if (openDropdown && !isZeroState) setShowAccountsDropdown(true);
  }, [openDropdown, openDropdownId]);

  return (
    <CommonWrapper
      isLoading={isLoading}
      skeletonType={SkeletonTypes.CUSTOM}
      skeleton={<SelectSourceAccountSkeleton showDropdown={false} className={skeletonClassName} />}
    >
      <div className='tw-relative' ref={ref}>
        <SelectedAccounts
          recipientAccount={isRecipientAccount ? selectedAccount ?? true : undefined}
          sourceAccount={isSourceAccount ? selectedAccount ?? true : undefined}
          isRecipientAccount={isRecipientAccount}
          recipientProps={{
            wrapperStyle: 'tw-mb-2',
            labelProps: { title: isRecipientAccount ? 'Transfer to' : '' },
            ...(!selectedAccount
              ? {
                  cardProps: {
                    title: selectedAccount ? '' : 'Select to account',
                    onClick: handleShowAccountsDropdown,
                    dropdownIconColor: '',
                    showIcon: false,
                  },
                  cardWrapperStyleClassName: isDisabled ? '!tw-pointer-events-none' : undefined,
                }
              : {}),
          }}
          sourceProps={{
            wrapperStyle: 'tw-mb-2',
            labelProps: { title: isSourceAccount ? 'Transfer from' : '' },
            ...(!selectedAccount
              ? {
                  cardProps: {
                    title: isZeroState ? 'No accounts found' : selectedAccount ? '' : 'Select from account',
                    titleClassName: isDisabled ? '!tw-text-TEXT_TERTIARY f-14-400' : '!tw-text-TEXT_PRIMARY f-14-400',
                    onClick: handleShowAccountsDropdown,
                    showIcon: false,
                  },
                  cardWrapperStyleClassName: isDisabled || isZeroState ? '!tw-pointer-events-none' : undefined,
                  dropdownIconColor: isDisabled ? COLORS.TEXT_TERTIARY : isZeroState ? 'transparent' : undefined,
                }
              : {}),
          }}
          onRecipientClick={isDisabled ? defaultFn : handleShowAccountsDropdown}
          onSourceClick={isDisabled ? defaultFn : handleShowAccountsDropdown}
          recipientAccountCardWrapperClassName={accountCardWrapperClassName}
          counterPartyCardWrapperClassName={accountCardWrapperClassName}
        />

        {showAccountsDropdown && !isZeroState && (
          <div className='tw-absolute tw-top-[103px] tw-z-50 tw-bg-white tw-border tw-border-DIVIDER_GRAY tw-rounded-2.5 tw-shadow-menu tw-overflow-y-auto tw-w-full tw-max-h-[260px] tw-flex tw-flex-col'>
            {entityAccounts?.map((entity) => (
              <AccountsByEntitySelector
                entity={entity}
                key={entity?.id}
                defaultOpen
                onAccountSelect={(accountData) =>
                  handleAccountSelect(entity, accountData as InternalTransferAccountType)
                }
              />
            ))}
          </div>
        )}
      </div>
    </CommonWrapper>
  );
};

export default AccountSelector;
