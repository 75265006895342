import { type FC } from 'react';
import { useGetCreateFiatBeneFormQuery } from 'api/beneficiary-v2';
import { BENE_FORM_STEPS, defaultConfig, useBeneficiaryContextStore } from 'modules/beneficiary-v2/beneficiary.context';
import BeneficiaryFormWrapper from 'modules/beneficiary-v2/BeneficiaryFormWrapper';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const BeneficiaryCreateFiatForm: FC = () => {
  const {
    state: { formStateData = defaultConfig },
  } = useBeneficiaryContextStore();

  const destinationCurrency = formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.destination_currency?.value || 'USD';
  const beneBankCountry = formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.[
    `beneficiary_bank_country_code_${destinationCurrency}`
  ] || { value: 'US' };

  const { data, isError, isFetching, refetch } = useGetCreateFiatBeneFormQuery({
    recipient_bank_country: beneBankCountry?.value,
    destination_currency: destinationCurrency,
  });

  return (
    <CommonWrapper isError={isError} isLoading={isFetching} refetchFunnction={refetch}>
      <BeneficiaryFormWrapper
        data={data?.form?.stepper_form_sections}
        prefilledData={{
          country: JSON.stringify(beneBankCountry),
        }}
      />
    </CommonWrapper>
  );
};

export default BeneficiaryCreateFiatForm;
