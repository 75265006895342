import { FC, useEffect, useMemo } from 'react';
import { useLazyGetSourceInternalTransferAccountsQuery } from 'api/internal-transfer';
import AccountSelector from 'modules/single-transfer/internalTransferV2/steps/AccountSelector';
import { MapAny } from 'types';
import { InternalTransferToEntityType } from 'types/internalTransferApi.types';
import { ErrorCardTypes } from 'components/banners/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

interface SelectAccountsProps {
  recipientEntityAccounts?: any;
  recipientAccount?: MapAny;
  sourceAccount?: MapAny;
  isRecipientEdit?: boolean;
  isSourceEdit?: boolean;
  onRecipientSelect?: (payload: MapAny) => void;
  onSourceSelect?: (payload?: MapAny) => void;
  accountCardWrapperClassName?: string;
  filterSourceAccounts?: (payload?: InternalTransferToEntityType[]) => InternalTransferToEntityType[];
  isOtc?: boolean;
}

const SelectAccounts: FC<SelectAccountsProps> = ({
  recipientEntityAccounts,
  recipientAccount,
  sourceAccount,
  isRecipientEdit,
  isSourceEdit,
  onRecipientSelect,
  onSourceSelect,
  accountCardWrapperClassName,
  filterSourceAccounts,
  isOtc,
}) => {
  const [getSourceInternalTransferAccounts, { data: sourceEntityAccounts, isFetching, isError }] =
    useLazyGetSourceInternalTransferAccountsQuery();

  const sourceZeroState = !!recipientAccount && !isFetching && !sourceEntityAccounts?.length;
  const openRecipientDropdown = isRecipientEdit || !recipientAccount;
  const openSourceDropdown =
    isSourceEdit || (!!recipientAccount && !!sourceEntityAccounts?.length && !sourceAccount && !sourceZeroState);

  const handleFetchSourceAccounts = () => {
    if (recipientAccount?.id && !sourceAccount) getSourceInternalTransferAccounts(recipientAccount?.id);
  };

  useEffect(() => {
    handleFetchSourceAccounts();
  }, [recipientAccount?.id]);

  const handleRecipientSelect = (payload: MapAny) => {
    onRecipientSelect?.(payload);
    onSourceSelect?.(undefined);
  };

  const sourceAccounts = useMemo(
    () => (filterSourceAccounts ? filterSourceAccounts(sourceEntityAccounts) : sourceEntityAccounts),
    [sourceEntityAccounts]
  );

  return (
    <>
      <CommonWrapper
        isError={isError}
        errorCardType={ErrorCardTypes.API_FAIL}
        refetchFunnction={handleFetchSourceAccounts}
      >
        <AccountSelector
          entityAccounts={recipientEntityAccounts}
          selectedAccount={recipientAccount}
          onAccountSelect={handleRecipientSelect}
          isDisabled={isFetching}
          isRecipientAccount
          openDropdown={openRecipientDropdown}
          accountCardWrapperClassName={accountCardWrapperClassName}
        />
        {isOtc && <div className='f-18-500 tw-my-4'>Which account are you paying from?</div>}
        <AccountSelector
          entityAccounts={sourceAccounts}
          selectedAccount={sourceAccount}
          onAccountSelect={onSourceSelect}
          isDisabled={!recipientAccount}
          isLoading={isFetching}
          isZeroState={sourceZeroState}
          isSourceAccount
          openDropdown={openSourceDropdown}
          openDropdownId={openSourceDropdown ? `${recipientAccount?.id}_${sourceEntityAccounts?.length}` : undefined}
          accountCardWrapperClassName={accountCardWrapperClassName}
          skeletonClassName={isOtc ? 'tw-bg-DIVIDER_GRAY' : ''}
        />
      </CommonWrapper>
    </>
  );
};

export default SelectAccounts;
