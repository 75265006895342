import { FC } from 'react';
import { MapAny } from 'types';

interface BeneficiaryAmountProps {
  data: MapAny;
}

const BeneficiaryAmount: FC<BeneficiaryAmountProps> = ({ data }) => <span>{data?.amount?.source_amount}</span>;

export default BeneficiaryAmount;
