import { createContext, Dispatch, FC, ReactElement, useContext, useReducer } from 'react';
import { captureException } from '@sentry/browser';
import { MapAny } from 'destiny/dist/types';
import { Beneficiary, CounterParty } from 'types/beneficiary-v2';

enum beneficiaryActions {
  SET_SEARCH = 'SET_SEARCH',
  SET_COUNTER_PARTIES = 'SET_COUNTER_PARTIES',
  SET_IS_FETCHING_COUNTER_PARTIES_WITH_SEARCH = 'SET_IS_FETCHING_COUNTER_PARTIES_WITH_SEARCH',
  SET_FORM_STATE_DATA = 'SET_FORM_STATE_DATA',
  SET_FORM_CONFIG = 'SET_FORM_CONFIG',
  SET_FORM_ERRORS = 'SET_FORM_ERRORS',
  SET_STEP = 'SET_STEP',
  SET_RE_RENDER_LIST_COUNTER = 'SET_RE_RENDER_LIST_COUNTER',
  RESET_FORM_CONTEXT = 'RESET_FORM_CONTEXT',
  SET_SHOW_MOVE_MONEY = 'SET_SHOW_MOVE_MONEY',
  SET_SHOW_BENE_DETAILS = 'SET_SHOW_BENE_DETAILS',
  SET_ELIGIBILTY_ERROR = 'SET_ELIGIBILTY_ERROR',
}

export enum BENE_FORM_STEPS {
  STEP_1,
  STEP_2,
  STEP_3,
}

export const defaultError: Record<BENE_FORM_STEPS, MapAny> = {
  [BENE_FORM_STEPS.STEP_1]: {},
  [BENE_FORM_STEPS.STEP_2]: {},
  [BENE_FORM_STEPS.STEP_3]: {},
};

export const defaultConfig: Record<BENE_FORM_STEPS, MapAny[]> = {
  [BENE_FORM_STEPS.STEP_1]: [],
  [BENE_FORM_STEPS.STEP_2]: [],
  [BENE_FORM_STEPS.STEP_3]: [],
};

interface InitialStateType {
  search: string;
  counterParties: CounterParty[];
  isFetchingCounterPartiesWithSearch: boolean;
  formStateData: Record<BENE_FORM_STEPS, MapAny[]>;
  formConfig: Record<BENE_FORM_STEPS, MapAny[]>;
  formErrors: Record<BENE_FORM_STEPS, MapAny>;
  currentStep: BENE_FORM_STEPS;
  reRenderListCounter: number;
  showMoveMoney: boolean;
  selectedBeneficiary?: Beneficiary;
  selectedCounterParty?: CounterParty;
  showBeneDetails: boolean;
  eligibilityError: string;
}

interface ActionType {
  type: keyof typeof beneficiaryActions;
  payload?: MapAny;
}

const initialState: InitialStateType = {
  search: '',
  counterParties: [],
  isFetchingCounterPartiesWithSearch: false,
  formStateData: defaultConfig,
  formConfig: defaultConfig,
  formErrors: defaultError,
  currentStep: BENE_FORM_STEPS.STEP_1,
  reRenderListCounter: 0,
  showMoveMoney: false,
  showBeneDetails: false,
  eligibilityError: '',
};

const context = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null,
});

const { Provider } = context;

/* eslint-disable react/display-name */
export const BeneficiaryStateProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer((state = {}, action: ActionType) => {
    switch (action.type) {
      case beneficiaryActions.SET_SEARCH:
        return { ...state, search: action?.payload?.search };
      case beneficiaryActions.SET_COUNTER_PARTIES: {
        const page = action?.payload?.page ?? 1;
        const oldCounterPartiesList = state?.counterParties ?? [];
        const newCounterPartiesList = action?.payload?.newCounterParties ?? [];
        const counterParties =
          page === 1 ? [...newCounterPartiesList] : [...oldCounterPartiesList, ...newCounterPartiesList];

        return { ...state, counterParties };
      }
      case beneficiaryActions.SET_IS_FETCHING_COUNTER_PARTIES_WITH_SEARCH:
        return { ...state, isFetchingCounterPartiesWithSearch: action?.payload?.isFetchingCounterPartiesWithSearch };
      case beneficiaryActions.SET_FORM_STATE_DATA:
        return { ...state, formStateData: action?.payload?.formStateData, eligibilityError: '' };
      case beneficiaryActions.SET_FORM_CONFIG:
        return { ...state, formConfig: action?.payload?.formConfig };
      case beneficiaryActions.SET_FORM_ERRORS:
        return { ...state, formErrors: action?.payload?.formErrors };
      case beneficiaryActions.SET_STEP:
        return { ...state, currentStep: action?.payload?.currentStep };
      case beneficiaryActions.SET_RE_RENDER_LIST_COUNTER:
        return { ...state, reRenderListCounter: state.reRenderListCounter + 1, counterParties: [] };
      case beneficiaryActions.RESET_FORM_CONTEXT:
        return {
          ...state,
          formStateData: initialState.formStateData,
          formConfig: initialState.formConfig,
          formErrors: initialState.formErrors,
          currentStep: initialState.currentStep,
          prefillData: undefined,
          eligibilityError: initialState.eligibilityError,
        };
      case beneficiaryActions.SET_SHOW_MOVE_MONEY:
        return {
          ...state,
          showMoveMoney: action?.payload?.showMoveMoney,
          selectedBeneficiary: action?.payload?.selectedBeneficiary,
          selectedCounterParty: action?.payload?.selectedCounterParty,
        };
      case beneficiaryActions.SET_SHOW_BENE_DETAILS:
        return {
          ...state,
          showBeneDetails: action?.payload?.showBeneDetails,
          selectedBeneficiary: action?.payload?.selectedBeneficiary,
          selectedCounterParty: action?.payload?.selectedCounterParty,
        };
      case beneficiaryActions.SET_ELIGIBILTY_ERROR:
        return { ...state, eligibilityError: action?.payload?.eligibilityError };
      default:
        captureException(new Error('Beneficiary unhandled action called'));

        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const withBeneficiaryContext = (WrappedComponent: FC) => {
  return (props: MapAny) => (
    <BeneficiaryStateProvider>
      <WrappedComponent {...props} />
    </BeneficiaryStateProvider>
  );
};

const useBeneficiaryContextStore = () => useContext(context);

export { beneficiaryActions, useBeneficiaryContextStore, withBeneficiaryContext };
