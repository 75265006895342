import { FC, useState } from 'react';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { StatusType } from 'constants/kyb';
import { NO_BENEFIARIES } from 'constants/lottie/no_beneficiaries';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { useAppSelector } from 'hooks/toolkit';
import useConfiguration, { CONFIG_DEFINITIONS } from 'hooks/useConfiguration';
import useIsOtcPage from 'hooks/useIsOtcPage';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import BeneficiaryCreateWithContext from 'modules/beneficiary-v2/BeneficiaryCreateWithContext';
import { RootState } from 'store';
import { CounterParty } from 'types/beneficiary-v2';
import ZeroListData from 'components/banners/ZeroListData';

const BeneficiaryListingZeroData: FC<{ selectedCounterParty?: CounterParty }> = ({ selectedCounterParty }) => {
  const [showAddBeneForm, setShowAddBeneForm] = useState(false);
  const isOtcPage = useIsOtcPage();

  const { bankingKybStatus } = useAppSelector((state: RootState) => state.kyb);

  const canManageRecepient = useUserAccessFromPermission(
    FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId,
    FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope
  );

  const { checkConfigEnabled } = useConfiguration();

  const canAddBeneficiary = checkConfigEnabled(CONFIG_DEFINITIONS.ADD_RECIPIENTS);

  const toggleAddBene = () => setShowAddBeneForm((prev) => !prev);

  const getPreKybSubtitle = () => {
    if (isOtcPage) return 'Activate your account and start transacting.';

    return [StatusType.INITIATED, StatusType.INFO_REQUESTED, StatusType.FAILED].includes(bankingKybStatus)
      ? 'You can send money to vendors, contractors or employees once your account is activated'
      : 'Activate your account and send money to vendors, contractors or employees';
  };

  const getPreKybCtaText = () => {
    return [StatusType.INITIATED, StatusType.INFO_REQUESTED, StatusType.FAILED].includes(bankingKybStatus)
      ? 'Check Status'
      : '';
  };

  return (
    <>
      <ZeroListData
        handleClick={toggleAddBene}
        wrapperClassName='tw-my-8'
        title='No Recipients'
        subtitle='Add your first recipient'
        preKybSubtitle={getPreKybSubtitle()}
        buttonText='Add Recipient'
        preKybButtonText={getPreKybCtaText()}
        lottieJson={NO_BENEFIARIES}
        showButton={canManageRecepient && canAddBeneficiary}
        destinyButtonProps={{
          customLeadingIcon: (
            <SvgSpriteLoader id='plus' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={20} height={20} />
          ),
          showLeadingIcon: true,
        }}
        id='RECIPIENTS'
        productKybStatus={bankingKybStatus}
      />
      {showAddBeneForm && <BeneficiaryCreateWithContext onClose={toggleAddBene} counterParty={selectedCounterParty} />}
    </>
  );
};

export default BeneficiaryListingZeroData;
