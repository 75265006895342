import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_KEY, PLATFORM_IDEMPOTENCY_HEADER_KEY } from 'constants/config';
import { REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { VaultsForPayoutsResponseType } from 'types/accountsApi.types';
import { BulkPayoutIdPayload, BulkPayoutResponse, GetPayoutsByBulkIdResponse } from 'types/api';
import { InitiateBulkPaymentPayload } from 'types/bulkPaymentApi.types';
import { accessBasedQueryWrapper } from 'utils/access';

const BulkPayment = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    initiateBulkPayment: builder.mutation<BulkPayoutResponse, InitiateBulkPaymentPayload>({
      query: ({ data, idempotencyHeader }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId, {
          url: 'b2b-transfer/v2/bulk-payout',
          method: REQUEST_TYPES.POST,
          body: data,
          headers: {
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getBulkPaymentById: builder.query<GetPayoutsByBulkIdResponse, BulkPayoutIdPayload>({
      query: ({ id }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId, {
          url: `b2b-transfer/v2/bulk-payout/${id}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getVaultsForBulkPayouts: builder.query<VaultsForPayoutsResponseType, string>({
      query: (sourceCurrency) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId, {
          url: `v2/vaults/bulk_payout/${sourceCurrency}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useInitiateBulkPaymentMutation,
  useLazyGetBulkPaymentByIdQuery,
  useGetVaultsForBulkPayoutsQuery,
  useGetBulkPaymentByIdQuery,
} = BulkPayment;
