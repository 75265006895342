import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_KEY, PLATFORM_IDEMPOTENCY_HEADER_KEY } from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import { MapAny } from 'destiny/dist/types';
import baseApi from 'services/api';
import {
  BeneForm,
  CounterParty,
  CounterPartyById,
  CounterPartyEditPayload,
  CounterPartyEligibility,
  CounterPartyEligibilityForDestCurrencyPayload,
  CounterPartyListingParams,
  CounterPartyUpdatePayload,
  CreateCounterPartyPayload,
  DeleteCounterPartyPayload,
} from 'types/beneficiary-v2';
import { accessBasedQueryWrapper } from 'utils/access';

const Beneficiary = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCounterParties: builder.query<CounterParty[], CounterPartyListingParams>({
      query: (params) =>
        accessBasedQueryWrapper(null, {
          url: 'v1/counterparties/',
          params,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      providesTags: [APITags.GET_BENEFICIARY],
      transformResponse: ({ data }) => data,
    }),
    getCounterPartyById: builder.query<CounterPartyById, string>({
      query: (id) =>
        accessBasedQueryWrapper(null, {
          url: `v1/counterparties/${id}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      providesTags: [APITags.GET_COUNTER_PARTY_BY_ID],
      transformResponse: ({ data }) => data,
    }),
    deleteCounterParty: builder.mutation<void, DeleteCounterPartyPayload>({
      query: ({ idempotencyHeader, id }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `v1/counterparties/${id}`,
          method: REQUEST_TYPES.DELETE,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
          },
        }),
      invalidatesTags: [APITags.GET_BENEFICIARY, APITags.GET_COUNTER_PARTY_BY_ID],
    }),
    getCounterPartiesWithSearch: builder.query<CounterPartyById[], { search_key: string }>({
      query: (params) =>
        accessBasedQueryWrapper(null, {
          url: 'v1/counterparties/search',
          params,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      providesTags: [APITags.GET_BENEFICIARY],
      transformResponse: ({ data }) => data,
    }),
    deleteBankAccountWallet: builder.mutation<void, string>({
      query: (id) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `b2b-transfer/v1/beneficiaries/${id}`,
          method: REQUEST_TYPES.DELETE,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      invalidatesTags: [APITags.GET_COUNTER_PARTY_BY_ID, APITags.GET_BENEFICIARY],
    }),
    editCounterParty: builder.mutation<void, CounterPartyEditPayload>({
      query: ({ id, idempotencyHeader, data }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `v1/counterparties/${id}`,
          method: REQUEST_TYPES.PATCH,
          body: data,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
          },
        }),
      invalidatesTags: [APITags.GET_BENEFICIARY, APITags.GET_COUNTER_PARTY_BY_ID],
    }),
    getCreateCounterPartyForm: builder.query<BeneForm, { destination_currency?: string; entity_id?: string }>({
      query: (params) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: 'v3/form/recipient/config/create_recipient',
          params,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getCreateFiatBeneForm: builder.query<BeneForm, { recipient_bank_country: string; destination_currency?: string }>({
      query: (params) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: 'v3/form/recipient/config/create_fiat',
          params,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getSelectFiatCryptoForm: builder.query<BeneForm, { is_add_to_existing_counter_party?: boolean }>({
      query: (params) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: 'v3/form/recipient/config/select_fiat_crypto',
          params,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getCreateCryptoBeneForm: builder.query<BeneForm, { custody_provider: string; recipient_type: string }>({
      query: (params) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: 'v3/form/recipient/config/create_crypto',
          params,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    createCounterParty: builder.mutation<void, CreateCounterPartyPayload>({
      query: ({ idempotencyHeader, data }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: 'b2b-transfer/v1/counterparty',
          method: REQUEST_TYPES.POST,
          body: data,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
          },
        }),
      invalidatesTags: [APITags.GET_BENEFICIARY, APITags.GET_COUNTER_PARTY_BY_ID],
    }),
    addBankAccountWalletToCounterParty: builder.mutation<void, CreateCounterPartyPayload>({
      query: ({ idempotencyHeader, data, counterPartyId }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `b2b-transfer/v1/counterparty/${counterPartyId}/beneficiary`,
          method: REQUEST_TYPES.POST,
          body: data,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
          },
        }),
      invalidatesTags: [APITags.GET_BENEFICIARY, APITags.GET_COUNTER_PARTY_BY_ID],
    }),
    getCounterPartyEligibility: builder.query<CounterPartyEligibility, string>({
      query: (id) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `/b2b-transfer/v1/counterparty/eligibility/${id}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    updateCounterParty: builder.mutation<void, CounterPartyUpdatePayload>({
      query: ({ id, idempotencyHeader, data }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `v1/counterparties/${id}/additional-info`,
          method: REQUEST_TYPES.PATCH,
          body: data,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
          },
        }),
    }),
    updateBeneficiaryAdditionalDetails: builder.mutation<MapAny, MapAny>({
      query: ({ payload, id }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `b2b-transfer/v1/beneficiaries/additional/${id}`,
          method: REQUEST_TYPES.PATCH,
          body: payload,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      invalidatesTags: [APITags.GET_BENEFICIARY, APITags.GET_COUNTER_PARTY_BY_ID],
      transformResponse: ({ data }) => data,
    }),
    getCounterPartyEligibilityForDestCurrency: builder.query<boolean, CounterPartyEligibilityForDestCurrencyPayload>({
      query: ({ id, data }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `/b2b-transfer/v1/beneficiary/create/eligibility/${id}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
          params: data,
        }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useLazyGetCounterPartiesQuery,
  useGetCounterPartyByIdQuery,
  useDeleteCounterPartyMutation,
  useLazyGetCounterPartiesWithSearchQuery,
  useDeleteBankAccountWalletMutation,
  useEditCounterPartyMutation,
  useGetCreateCounterPartyFormQuery,
  useGetCreateFiatBeneFormQuery,
  useGetSelectFiatCryptoFormQuery,
  useGetCreateCryptoBeneFormQuery,
  useCreateCounterPartyMutation,
  useAddBankAccountWalletToCounterPartyMutation,
  useGetCounterPartyEligibilityQuery,
  useUpdateCounterPartyMutation,
  useUpdateBeneficiaryAdditionalDetailsMutation,
  useLazyGetCounterPartyEligibilityForDestCurrencyQuery,
} = Beneficiary;
