import { IMAGE_PLACEHOLDER } from 'constants/icons';
import { PaymentType } from 'constants/index';
import { FormFieldType } from 'constants/kyb';
import { FIAT_TXN, NETWORK } from 'constants/transfer';
import { store } from 'store';

export const formatBeneData = (data: any, beneficiary: any) => {
  let formatedData: any = {};

  Object.keys(data).forEach((key: string) => {
    if (data[key] && typeof data[key] === 'object') formatedData = { ...formatedData, [key]: data[key].value };
    else formatedData = { ...formatedData, [key]: data[key] };
  });

  return {
    payout_method: formatedData?.currency_type?.toUpperCase() || '',
    ...(beneficiary?.id ? beneficiary : getOtherFields(formatedData)),
    ...formatedData,
  };
};

const getOtherFields = (formatedData: any) => {
  if (formatedData.currency_type === FIAT_TXN)
    return {
      destination_country: formatedData.beneficiary_country_code,
    };

  return {
    routing_code_type_1: NETWORK,
    routing_code_value_1: formatedData.network,
  };
};

export const constructBeneFormState = (form: any, currentState: any, beneficiary: any, apiTriggerStates: string[]) => {
  const formState: any = {};

  form.forEach((item: any) => {
    if (item.type === FormFieldType.INFO) {
      formState[item.key] = item.display_name;
    } else if (beneficiary) {
      if (item.type === FormFieldType.SELECT)
        formState[item.key] = { label: beneficiary[item.key], value: beneficiary[item.key] };
      else formState[item.key] = beneficiary[item.key];
    } else if (item.type === FormFieldType.SELECT && item.default) {
      const data = item.options.find((option: any) => option.id === item.default);

      formState[item.key] = data ? { label: data.value, value: data.id } : null;
    } else {
      formState[item.key] = null;
    }
  });

  Object.keys(currentState).forEach((key: string) => {
    if (apiTriggerStates.includes(key) && currentState[key]) formState[key] = currentState[key];
  });

  return formState;
};

export const getBeneIcon = (recipient: any) => {
  const { config } = store.getState();
  const { currencyCodeAndIconMap, networkCodeAndIconMap } = config;
  let icon = '';

  if (recipient?.payout_method === PaymentType.FIAT)
    icon = recipient?.destination_currency && currencyCodeAndIconMap[recipient.destination_currency];
  else icon = recipient?.routing_code_value_1 && networkCodeAndIconMap[recipient.routing_code_value_1];

  return icon || IMAGE_PLACEHOLDER;
};
