import { CRYPTO_WALLET } from 'constants/icons';
import { tableHeaderStyle } from 'constants/transactions';
import BeneficiaryAmount from 'modules/beneficiary-v2/BeneficiaryAmount';
import BeneficiaryCurrency from 'modules/beneficiary-v2/BeneficiaryCurrency';
import TransactionStatus from 'modules/transactions/v2/TransactionStatus';
import { AccountType, ActionTypes } from 'types/beneficiary';
import { getMonDayFormat } from 'utils/common';
import { DataAlign, DataComponents } from 'components/table/types';

export const beneficiaryListActions = [
  { label: 'Send Money', value: ActionTypes.SEND_MONEY },
  { label: 'Edit', value: ActionTypes.EDIT },
  { label: 'Delete', value: ActionTypes.DELETE, color: '#D64141' },
];

export const accountTypes = [
  { label: 'Individual', value: AccountType.INDIVIDUAL, icon: CRYPTO_WALLET },
  { label: 'Business', value: AccountType.BUSINESS, icon: CRYPTO_WALLET },
];

export const BeneTransactionColumns = [
  {
    label: 'Date',
    value: 'created_at',
    formatFunction: getMonDayFormat,
    className: 'tw-text-TEXT_TERTIARY',
    headerClassName: tableHeaderStyle,
  },
  {
    label: 'Amount',
    value: 'receiving_amount',
    align: DataAlign.RIGHT,
    dependentValue: 'transfer_type',
    renderComponent: DataComponents.TRANSACTION_TYPE,
    headerClassName: tableHeaderStyle,
  },
  {
    label: 'Currency',
    value: 'dest_currency_code',
    headerClassName: tableHeaderStyle,
    renderComponent: DataComponents.CURRENCY,
  },
  {
    label: 'Status',
    value: 'status',
    renderComponent: DataComponents.TRANSACTION_STATUS_TAG,
    className: 'tw-p-0 tw-pl-6 tw-w-[23%]',
    headerClassName: tableHeaderStyle,
    wrapperStyle: { justifyContent: 'flex-start' },
  },
];

export const BENEFICIARY_TRANSACTIONS_COLUMNS = [
  {
    label: 'DATE',
    id: 'created_at',
    keyNames: ['created_at'],
    headerClassName: 'tw-py-3 tw-uppercase f-10-600 tw-text-TEXT_PRIMARY',
    IsHeaderItemCustomStyle: true,
    formatFunction: getMonDayFormat,
    className: 'f-14-300 tw-text-TEXT_SECONDARY',
  },
  {
    label: 'AMOUNT',
    id: 'source_amount',
    keyNames: ['*'],
    headerClassName: 'tw-py-3 tw-pl-6 tw-uppercase f-10-600 tw-text-TEXT_PRIMARY',
    IsHeaderItemCustomStyle: true,
    className: 'f-14-300',
    align: DataAlign.RIGHT,
    Component: BeneficiaryAmount,
  },
  {
    label: 'CURRENCY',
    id: 'dest_currency_code',
    keyNames: ['*'],
    headerClassName: 'tw-py-3 tw-pl-6 tw-uppercase f-10-600 tw-text-TEXT_PRIMARY',
    IsHeaderItemCustomStyle: true,
    Component: BeneficiaryCurrency,
  },
  {
    label: 'STATUS',
    id: 'status',
    keyNames: ['status'],
    headerClassName: 'tw-py-3 tw-pl-6 tw-uppercase f-10-600 tw-text-TEXT_PRIMARY',
    IsHeaderItemCustomStyle: true,
    Component: TransactionStatus,
  },
];

export const KYB_ERROR_CODES = ['KYB_PENDING', 'KYB_NOT_INITIATED'];

export const BENE_ADDITION_ALERTS = {
  KYB_ERROR: 'KYB is not completed',
  SUCCESS: 'Recipient has been added successfully',
  ERROR: 'Failed to add Recipient, Please try again',
};

export const RECIPIENT_ADDITION_KEYS = {
  DESTINATION_CURRENCY: 'destinationCurrency',
  NETWORK: 'network',
};

export const THIRD_PARTY_PAYOUTS_STEPS_TYPES = {
  SELECT_RECIPIENT_ACCOUNT: 1,
  SELECT_SOURCE_ACCOUNT: 2,
  TRANSFER_DETAILS: 3,
  ADDITIONAL_BENE_DETAILS: 3.5,
  REVIEW_AND_PAY: 4,
};

export const THIRD_PARTY_PAYOUTS_STEP_TITLE = {
  [THIRD_PARTY_PAYOUTS_STEPS_TYPES.SELECT_RECIPIENT_ACCOUNT]: 'Who are you paying?',
  [THIRD_PARTY_PAYOUTS_STEPS_TYPES.SELECT_SOURCE_ACCOUNT]: 'Which account are you paying from?',
  [THIRD_PARTY_PAYOUTS_STEPS_TYPES.TRANSFER_DETAILS]: 'Enter Amount',
  [THIRD_PARTY_PAYOUTS_STEPS_TYPES.REVIEW_AND_PAY]: 'Review & Pay',
  [THIRD_PARTY_PAYOUTS_STEPS_TYPES.ADDITIONAL_BENE_DETAILS]: 'Additional Information',
};
