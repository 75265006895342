import { FC, ReactElement } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { defaultFn } from 'destiny/dist/constants/index';
import { defaultFnType } from 'types';

interface SelectorListItemProps {
  children: ReactElement | string;
  selected?: boolean;
  onClick: defaultFnType;
  className?: string;
  wrapperClassName?: string;
}

const SelectorListItem: FC<SelectorListItemProps> = ({
  children = '',
  selected = false,
  onClick = defaultFn,
  className = '',
  wrapperClassName = '',
}) => {
  return (
    <div
      className={`tw-cursor-pointer tw-flex tw-items-center tw-justify-between tw-px-3 tw-py-4 ${
        selected ? 'tw-bg-BASE_PRIMARY' : 'hover:tw-bg-BASE_SECONDARY'
      } ${wrapperClassName}`}
      role='presentation'
      onClick={onClick}
    >
      <Text
        textClass={
          className ? className : selected ? 'f-12-400 tw-text-TEXT_PRIMARY' : 'f-12-300 tw-text-TEXT_SECONDARY'
        }
      >
        {children}
      </Text>
      {selected && (
        <SvgSpriteLoader id='chevron-right' iconCategory={ICON_SPRITE_TYPES.ARROWS} width={14} height={14} />
      )}
    </div>
  );
};

export default SelectorListItem;
