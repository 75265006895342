import SkeletonElement from 'components/skeletons/SkeletonElement';

const VaultSelectorSkeleton = () => (
  <div className='tw-mb-3'>
    <SkeletonElement className='tw-mb-3 tw-w-[70px] tw-h-3.5' />
    <SkeletonElement className='tw-w-full tw-h-[68px] tw-rounded-2.5' />
  </div>
);

export default VaultSelectorSkeleton;
