import { useEffect, useRef, useState } from 'react';
import { useGetRecipientInternalTransferAccountsQuery } from 'api/internal-transfer';
import { useGetCorridorsQuery } from 'api/moveMoney';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import { Text } from 'destiny/dist/components/atoms/text';
import { MenuOption } from 'destiny/dist/components/molecules/menuOption';
import { OptionsType } from 'destiny/dist/types/dropdown';
import { useOnClickOutside } from 'hooks';
import { useAppSelector } from 'hooks/toolkit';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import BulkPayment from 'modules/bulk-payment/BulkPayment';
import InternalTransfer from 'modules/single-transfer/internalTransferV2/InternalTransfer';
import SingleTransfer from 'modules/single-transfer/SingleTransferOverlay';
import { RootState } from 'store';
import { getVariation } from 'utils/launchDarkly';
import { trackMixpanel } from 'utils/mixpanel';
import { isCryptoCorridorAvailableUtil } from 'utils/transactions';
import TransferButton from 'components/button/TransferButton';
import { MOVE_MONEY_OPTIONS, MOVE_MONEY_VALUES } from 'components/move-money/moveMoney.constants';
import TransferButtonV2 from 'components/move-money/TransferButtonV2';

const MoveMoney: React.FC = () => {
  const [open, toggleOpen] = useState<boolean>(false);
  const [menuOptions, setMenuOptions] = useState<OptionsType[]>(MOVE_MONEY_OPTIONS);
  const [showThirdPartyPayouts, setShowThirdPartyPayouts] = useState<boolean | null>(false);
  const [showBulkTransfer, setShowBulkTransfer] = useState(false);
  const [showInternalTransfer, setShowInternalTransfer] = useState(false);
  const { isBankingKybSuccess } = useAppSelector((state: RootState) => state.kyb);

  const canDoTransfer =
    useUserAccessFromPermission(
      FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId,
      FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope
    ) && isBankingKybSuccess;

  const { data: internalTransferEntityAccounts, isError: isInternalTransferEntityAccountsErr } =
    useGetRecipientInternalTransferAccountsQuery(undefined, {
      skip: !isBankingKybSuccess || !canDoTransfer,
    });

  const ref = useRef(null);

  const { data: corridors } = useGetCorridorsQuery();
  const isOtcEnabled = getVariation(LAUNCH_DARKLY_FLAGS.OTC);

  const isMoveMoneyAllowed = true;

  useOnClickOutside(ref, () => {
    toggleOpen(false);
  });

  const handleMenuVisibility = () => toggleOpen((prevValue) => !prevValue);

  const handleTransfer = () => {
    menuOptions.length > 1 ? handleMenuVisibility() : setShowThirdPartyPayouts(true);
  };

  const onMenuItemClick = (menuValue: string | number) => {
    switch (menuValue) {
      case MOVE_MONEY_VALUES.SINGLE:
        setShowThirdPartyPayouts(true);
        break;
      case MOVE_MONEY_VALUES.SELF:
        setShowInternalTransfer(true);
        break;
      case MOVE_MONEY_VALUES.BULK:
        setShowBulkTransfer(true);
        break;
    }
    trackMixpanel(`MOVE_MONEY_${menuValue}_TRANSFER_CLICK`);
    handleMenuVisibility();
  };

  const renderMenuItem = (menu: OptionsType, index: number) => (
    <MenuOption
      data={menu}
      key={index}
      contentWrapper=''
      wrapperClass='tw-flex tw-items-center tw-p-2'
      label={<Text textClass='f-16-300 tw-ml-4'>{`${menu.label}`}</Text>}
      onClick={() => onMenuItemClick(menu.value)}
      eventCallback={trackMixpanel}
      innerProps={{ id: menu.value }}
    />
  );

  useEffect(() => {
    let menuOptionsFiltered = MOVE_MONEY_OPTIONS;

    if (
      (internalTransferEntityAccounts && !internalTransferEntityAccounts?.length) ||
      isInternalTransferEntityAccountsErr ||
      !isBankingKybSuccess ||
      !canDoTransfer
    )
      menuOptionsFiltered = menuOptionsFiltered.filter((item) => item.value !== MOVE_MONEY_VALUES.SELF);

    if (!isCryptoCorridorAvailableUtil(corridors))
      menuOptionsFiltered = menuOptionsFiltered.filter((item) => item.value !== MOVE_MONEY_VALUES.BULK);

    setMenuOptions(menuOptionsFiltered);
  }, [corridors, internalTransferEntityAccounts, isInternalTransferEntityAccountsErr]);

  if (!canDoTransfer) return null;

  return (
    <div className='tw-relative' ref={ref} data-testid='move-money-button'>
      {isOtcEnabled ? (
        <TransferButtonV2 setShowMoveMoney={handleTransfer} open={open} />
      ) : (
        <TransferButton setShowMoveMoney={handleTransfer} path='Topbar' isDisabled={!isMoveMoneyAllowed} />
      )}

      {showThirdPartyPayouts && <SingleTransfer onClose={setShowThirdPartyPayouts} />}
      {showInternalTransfer && (
        <InternalTransfer onClose={setShowInternalTransfer} recipientEntityAccounts={internalTransferEntityAccounts} />
      )}
      {showBulkTransfer && <BulkPayment onClose={setShowBulkTransfer} />}
      {menuOptions.length > 1 && (
        <div
          className={`tw-w-60 tw-mt-2.5 tw-absolute  tw-border-DIVIDER_GRAY tw-rounded-2.5 tw-shadow-menu tw-overflow-y-hidden tw-z-10 tw-bg-white tw-cursor-pointer tw-transition-all tw-duration-500 ${
            open ? 'tw-max-h-[250px] tw-border' : 'tw-max-h-0'
          }`}
        >
          {menuOptions.map(renderMenuItem)}
        </div>
      )}
    </div>
  );
};

export default MoveMoney;
