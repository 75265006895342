import { type FC } from 'react';
import { MASKED_DOTS } from 'constants/index';
import { Label } from 'destiny/dist/components/molecules/label';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { defaultFnType } from 'destiny/dist/types';
import { Beneficiary, CounterParty } from 'types/beneficiary-v2';
import { PaymentMethod } from 'types/transactions';
import Avatar from 'components/avatar/Avatar';

type SelectedRecipientProps = {
  recipientAccount: Beneficiary;
  selectedCounterParty: CounterParty;
  onRecipientClick?: defaultFnType;
  showEdit?: boolean;
  className?: string;
};

const SelectedRecipient: FC<SelectedRecipientProps> = ({
  recipientAccount,
  selectedCounterParty,
  onRecipientClick,
  showEdit,
  className = '',
}) => {
  const isFiat = recipientAccount?.payout_method === PaymentMethod.FIAT;

  return (
    <div className={`tw-flex tw-justify-between tw-items-center ${className}`}>
      <div className='tw-flex tw-gap-4'>
        <Avatar name={selectedCounterParty?.name} className='tw-min-w-[24px] tw-h-6' />
        <Label
          title={selectedCounterParty?.name}
          description={
            <div className='tw-flex tw-items-center'>
              <SvgSpriteLoader
                id={isFiat ? 'bank' : 'wallet-04'}
                iconCategory={ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE}
                width={16}
                height={16}
                className='tw-mr-0.5'
                color={COLORS.TEXT_PRIMARY}
              />
              <div>
                {isFiat ? recipientAccount?.beneficiary_bank_name : recipientAccount?.beneficiary_alias} {MASKED_DOTS}{' '}
                {recipientAccount?.beneficiary_account_number.slice(-4)}
              </div>
            </div>
          }
          titleClass='f-14-400 tw-mb-2'
        />
      </div>
      {showEdit && (
        <div className='tw-text-ZAMP_PRIMARY f-12-400 tw-cursor-pointer' onClick={onRecipientClick}>
          Edit
        </div>
      )}
    </div>
  );
};

export default SelectedRecipient;
