import React, { useEffect, useState } from 'react';
import TransferDetails, {
  TransferDetailsProps,
} from 'modules/single-transfer/internalTransferV2/steps/TransferDetails';
import TransferDetailsSkeleton from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/TransferDetailsSkeleton';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const TransferDetailsWrapper: React.FC<TransferDetailsProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { selectedRecipientAccount, selectedSourceAccount } = props ?? {};

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [selectedRecipientAccount?.id, selectedSourceAccount?.id]);

  return (
    <div className='tw-w-full tw-mt-4'>
      <CommonWrapper isLoading={isLoading} skeletonType={SkeletonTypes.CUSTOM} skeleton={<TransferDetailsSkeleton />}>
        <TransferDetails {...props} />
      </CommonWrapper>
    </div>
  );
};

export default TransferDetailsWrapper;
