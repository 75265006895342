import { useEffect } from 'react';
import { useGetQuoteFiatQuery } from 'api/moveMoney';
import { INFO_ROUND } from 'constants/icons';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { useAppSelector } from 'hooks/toolkit';
import AmountDetails from 'modules/single-transfer/fiat/AmountDetails';
import RfqSummarySkeleton from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/RfqSummarySkeleton';
import { EXPECTED_TRANSACTION_TIME } from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.constants';
import Image from 'next/image';
import { RootState } from 'store';
import { ThirdPartyReviewAndPayProps } from 'types/transactions';
import { ErrorCardTypes } from 'components/banners/types';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const ReviewAndPay: React.FC<ThirdPartyReviewAndPayProps> = ({
  selectedSourceCurrency,
  selectedDestCurrency,
  quoteId = '',
  isQuoteAutoUpdated = false,
  setFiatQuoteData,
  recipientAccount,
}) => {
  const { currencyCodeAndNameMap } = useAppSelector((state: RootState) => state.config);

  const { data, isLoading, isError, refetch } = useGetQuoteFiatQuery({ quoteId }, { skip: !quoteId });

  useEffect(() => {
    data && setFiatQuoteData?.(data);
  }, [data]);

  return (
    <div className='tw-mt-6'>
      <CommonWrapper
        isLoading={isLoading}
        skeletonType={SkeletonTypes.CUSTOM}
        skeleton={<RfqSummarySkeleton isQuoteAutoUpdated={isQuoteAutoUpdated} />}
        isError={isError}
        errorCardType={ErrorCardTypes.API_FAIL}
        refetchFunnction={refetch}
      >
        <>
          <div className='tw-mb-3'>
            <div className='tw-flex tw-items-center tw-justify-between tw-mb-3.5'>
              <div className='tw-text-TEXT_PRIMARY f-12-300'>You are sending</div>
              <div className='tw-text-TEXT_BLACK_1 f-16-400'>{`${data?.source_amount} ${
                selectedSourceCurrency ? currencyCodeAndNameMap[selectedSourceCurrency?.code] : ''
              }`}</div>
            </div>

            {selectedSourceCurrency && selectedDestCurrency && (
              <AmountDetails
                transferDetails={data}
                corridor={selectedSourceCurrency?.corridor_type}
                destinationCurrency={currencyCodeAndNameMap?.[selectedDestCurrency?.code]}
                sourceCurr={currencyCodeAndNameMap?.[selectedSourceCurrency?.code]}
                className='tw-mb-1'
                itemsParentWrapperClassName='tw-flex-row-reverse tw-justify-between tw-mb-2'
                itemsWrapperClassName='tw-justify-end'
                currencyTextClassName='f-12-300'
                labelClassName='f-12-300'
                showIcon={false}
                feeBreakupItemClassName='tw-flex-row-reverse tw-justify-between'
                feeBreakupValueClassName='f-12-400 tw-text-TEXT_SECONDARY'
                feeBreakupWrapperClassName='tw-mb-2'
              />
            )}

            <div className='tw-flex tw-items-center tw-justify-between'>
              <div className='tw-text-TEXT_PRIMARY f-12-300'>Recipient gets</div>
              <div className='tw-text-TEXT_BLACK_1 f-16-400'>{`${data?.receiving_amount} ${
                selectedDestCurrency ? currencyCodeAndNameMap?.[selectedDestCurrency?.code] : ''
              }`}</div>
            </div>
          </div>

          <div className='tw-flex tw-items-center tw-gap-1.5 f-12-300 tw-px-2.5 tw-py-2 tw-bg-GRAY_2 tw-mb-3'>
            <SvgSpriteLoader
              id='clock-fast-forward'
              iconCategory={ICON_SPRITE_TYPES.TIME}
              width={16}
              height={16}
              color={COLORS.TEXT_PRIMARY}
            />
            <div>Estimated Time: {EXPECTED_TRANSACTION_TIME[recipientAccount?.routing_code_type_1 ?? 'ACH CODE']}</div>
          </div>

          {isQuoteAutoUpdated && (
            <div className='tw-flex tw-justify-between tw-px-4 tw-py-3 tw-bg-BASE_PRIMARY tw-rounded-2.5 tw-mb-3'>
              <div className='tw-shrink-0 tw-relative tw-mr-4'>
                <Image src={INFO_ROUND} alt='info' width={13} height={13} />
              </div>
              <div className='f-12-300 tw-text-TEXT_SECONDARY' data-testid='single-transfer-exchange-rate-update-alert'>
                The exchange rate has been updated as it has been over 2:00 minutes. Please review the updated amount
                before proceeding
              </div>
            </div>
          )}
        </>
      </CommonWrapper>
    </div>
  );
};

export default ReviewAndPay;
