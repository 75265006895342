import { FC } from 'react';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import Image from 'next/image';
import { MenuItem } from 'types';
import { trackMixpanel } from 'utils/mixpanel';

type OptionMenuItemProps = {
  menu: MenuItem;
  index: number;
  isDisabled: boolean;
  toggleOpen: (flag: boolean) => void;
  onMenuItemClick: (menu: MenuItem) => void;
  optionClassName?: string;
  eventParentId?: string;
  id: string;
};

const OptionMenuItem: FC<OptionMenuItemProps> = ({
  menu,
  index,
  isDisabled,
  toggleOpen,
  onMenuItemClick,
  optionClassName = '',
  eventParentId,
  id,
}) => {
  const hasAccess = useUserAccessFromPermission(
    menu?.permissionId ?? FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId,
    menu?.scope ?? FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope
  );

  const checkAccess = menu?.permissionId && menu?.scope ? hasAccess : true;

  return (
    <>
      {checkAccess && (
        <div
          className={`f-16-300 tw-h-11 tw-px-4 tw-flex tw-items-center tw-text-TEXT_PRIMARY hover:tw-bg-LAVENDER_2 tw-min-w-[120px] tw-w-max tw-whitespace-nowrap ${
            isDisabled && 'tw-opacity-50'
          } ${optionClassName}`}
          key={index}
          onClick={(e) => {
            if (!isDisabled) {
              e.stopPropagation();
              toggleOpen(false);
              onMenuItemClick(menu);
              menu?.id && eventParentId && trackMixpanel(menu.id, { id: eventParentId });
            }
          }}
          style={menu.color ? { color: menu.color } : {}}
          data-testid={`${id}-${menu?.value}`}
        >
          {menu?.icons && <Image src={menu.icons} alt='action' className='tw-mr-4' width={20} height={20} />}
          {menu?.iconCategory && menu?.iconId && (
            <SvgSpriteLoader
              id={menu.iconId}
              iconCategory={menu.iconCategory}
              width={24}
              height={24}
              className='tw-mr-2.5'
            />
          )}
          {menu.label}
        </div>
      )}
    </>
  );
};

export default OptionMenuItem;
