import { FC, useMemo, useState } from 'react';
import mixpanelEvents from 'constants/mixpanel';
import { Button } from 'destiny/dist/components/molecules/button';
import { Label } from 'destiny/dist/components/molecules/label';
import { Table } from 'destiny/dist/components/molecules/table';
import { Tabs } from 'destiny/dist/components/molecules/tabs';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { MenuItem } from 'destiny/dist/types';
import { Column } from 'destiny/dist/types/table';
import { useAppSelector } from 'hooks/toolkit';
import {
  BULK_PAYMENT_REVIEW_TAB_TYPES,
  BULK_PAYMENT_REVIEW_TABS,
  BULK_PAYMENT_STAGES,
} from 'modules/bulk-payment/BulkPayment.constants';
import { BulkPaymentStagesCommonProps } from 'modules/bulk-payment/BulkPayment.types';
import BulkPaymentReviewDataCell from 'modules/bulk-payment/BulkPaymentReviewDataCell';
import BulkPaymentUploadPopup from 'modules/bulk-payment/BulkPaymentUploadPopup';
import { RootState } from 'store';
import { pascalCaseToCapitalizedUpperCase, pascalCaseToSnakeLowercase } from 'utils/common';
import { trackMixpanel } from 'utils/mixpanel';
import ConfirmPopup from 'components/popup/ConfirmPopup';

const BulkPaymentReview: FC<BulkPaymentStagesCommonProps> = ({ setCurrentStage }) => {
  const [currentTab, setCurrentTab] = useState(BULK_PAYMENT_REVIEW_TAB_TYPES.ALL);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);

  const { payouts, errorRows, columnList } = useAppSelector((state: RootState) => state.bulkPayout);

  const errorLength = errorRows?.length ?? 0;
  const dataLength = payouts?.length;

  const columns: Column[] = useMemo(() => {
    const formattedColumns: Column[] = columnList?.map((column) => ({
      id: pascalCaseToSnakeLowercase(column),
      label: pascalCaseToCapitalizedUpperCase(column),
      keyNames: [column],
      Component: BulkPaymentReviewDataCell,
      className: 'first:tw-pl-2',
      headerClassName: 'tw-whitespace-nowrap',
    }));

    return formattedColumns;
  }, [columnList]);

  const tabsList = useMemo(
    () =>
      errorLength
        ? BULK_PAYMENT_REVIEW_TABS.map((item) => ({
            ...item,
            label: `${item.label} (${item.value === BULK_PAYMENT_REVIEW_TAB_TYPES.ERROR ? errorLength : dataLength})`,
          }))
        : [],
    [payouts, errorRows]
  );

  const onTabSelect = (item?: MenuItem) => item?.value && setCurrentTab(`${item.value}`);

  const onCancel = () => {
    errorLength > 0 ? setCurrentStage(BULK_PAYMENT_STAGES.UPLOAD) : setShowCancelPopup(true);
  };

  return (
    <>
      <div className='tw-w-full tw-overflow-x-hidden'>
        <Label
          title={`${errorLength ? 'Can’t process your payments.' : 'Review all payments'}`}
          titleClass='f-24-400 tw-text-TEXT_PRIMARY tw-mb-2'
          description={`${
            errorLength
              ? `${errorLength} payment${errorLength > 1 ? 's' : ''} in your uploaded file ${
                  errorLength > 1 ? 'have' : 'has'
                } errors. Please review and correct your file`
              : `${dataLength} payment${
                  dataLength > 1 ? 's are' : ' is'
                }  successfully imported. Please review your payment${dataLength > 1 ? 's' : ''} and confirm`
          }`}
          descriptionClass='f-14-300 tw-text-TEXT_SECONDARY'
          wrapperClass='tw-mt-[50px] tw-w-full tw-text-center'
        />
        {tabsList?.length > 1 && (
          <Tabs
            list={tabsList}
            onSelect={onTabSelect}
            wrapperStyle='tw-border-white !tw-w-auto tw-mx-[10%] tw-mt-12'
            tabItemWrapperStyle='!tw-w-auto tw-px-2'
            indicatorStyle='!tw-bottom-0 !tw-border-2'
            id='BULK_PAYMENT_REVIEW_TABS'
            eventCallback={trackMixpanel}
          />
        )}
        <Table
          columns={columns}
          data={currentTab === BULK_PAYMENT_REVIEW_TAB_TYPES.ERROR ? errorRows : payouts}
          id='BULK_PAYMENT_REVIEW_TABLE'
          eventCallback={trackMixpanel}
          className={`tw-overflow-y-scroll tw-overflow-x-scroll tw-h-[calc(100vh-330px)] tw-mx-[10%] ${
            tabsList?.length > 1 ? 'tw-mt-5' : 'tw-mt-12'
          }`}
          tableHeaderClassName='tw-sticky tw-top-0 tw-z-1'
        />
      </div>
      <div className='tw-bg-BASE_SECONDARY tw-w-full tw-py-6 tw-px-[10%] tw-flex tw-flex-row-reverse tw-fixed tw-bottom-0 tw-items-center'>
        {errorLength < 1 && (
          <Button
            buttonProps={{
              id: 'BULK_PAYMENT_REVIEW_NEXT_BUTTON',
              eventCallback: trackMixpanel,
              onClick: () => setCurrentStage(BULK_PAYMENT_STAGES.PAY),
              size: BUTTON_SIZE_TYPES.MEDIUM,
              wrapperClass: 'tw-min-w-[182px] tw-ml-4',
            }}
          >
            Yes, Continue to pay
          </Button>
        )}
        {errorLength > 0 && (
          <Button
            buttonProps={{
              id: 'BULK_PAYMENT_REVIEW_UPLOAD_BUTTON',
              eventCallback: trackMixpanel,
              onClick: () => setShowUploadPopup(true),
              size: BUTTON_SIZE_TYPES.MEDIUM,
              wrapperClass: 'tw-min-w-[182px] tw-ml-4',
            }}
          >
            Upload a new file
          </Button>
        )}
        <Button
          buttonProps={{
            id: 'BULK_PAYMENT_REVIEW_CANCEL_BUTTON',
            eventCallback: trackMixpanel,
            onClick: onCancel,
            size: BUTTON_SIZE_TYPES.MEDIUM,
            btnType: BUTTON_TYPES.SECONDARY,
            wrapperClass: 'tw-w-[162px]',
          }}
        >
          <div className='tw-whitespace-nowrap'>Cancel Payments</div>
        </Button>
      </div>
      <ConfirmPopup
        isOpen={showCancelPopup}
        title='Cancel Payments'
        cancelBtnText='No'
        submitBtnText='Yes'
        isSubmitButtonLoading={false}
        submitBtnStyle='tw-min-w-[104px]'
        onCancel={() => setShowCancelPopup(false)}
        mixpanelEventOnCancel={'BULK_PAYMENT_REVIEW_' + mixpanelEvents.CANCEL_BULK_PAYMENT_NO}
        onSubmit={() => setCurrentStage(BULK_PAYMENT_STAGES.UPLOAD)}
        mixpanelEventOnClose={'BULK_PAYMENT_REVIEW_' + mixpanelEvents.CANCEL_BULK_PAYMENT_YES}
        className='f-20-500 tw-text-TEXT_GRAY_5'
      >
        <div className='f-16-400 tw-text-TEXT_GRAY_5'>Are you sure you want to cancel payments?</div>
      </ConfirmPopup>
      {showUploadPopup && <BulkPaymentUploadPopup isOpen={showUploadPopup} onClose={setShowUploadPopup} />}
    </>
  );
};

export default BulkPaymentReview;
