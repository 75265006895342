import { type FC } from 'react';
import { Button } from 'destiny/dist/components/molecules/button';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip';
import Input from 'destiny/dist/components/organisms/input';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES, TooltipPositions } from 'destiny/dist/constants';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { trackMixpanel } from 'utils/mixpanel';

type SlippageProps = {
  slippageValue: string;
  setSlippageValue: (value: string) => void;
  receivingAmount?: string;
  destCurrencyCode?: string;
};

const Slippage: FC<SlippageProps> = ({ slippageValue, setSlippageValue, receivingAmount, destCurrencyCode }) => {
  const handleDecreaseSlippage = () => {
    const value = parseFloat(`${+parseFloat(slippageValue).toFixed(2) - 0.01}`);

    if (value < 0) return;

    setSlippageValue(value.toFixed(2));
  };

  const handleIncreaseSlippage = () => {
    const value = parseFloat(`${+parseFloat(slippageValue).toFixed(2) + 0.01}`);

    if (value > 0.1) return;

    setSlippageValue(value.toFixed(2));
  };

  const minReceivingAmount =
    Number(receivingAmount) - (Number(receivingAmount) * +parseFloat(slippageValue).toFixed(2)) / 100;

  return (
    <div>
      <div className='tw-flex tw-items-center f-12-300 tw-gap-1 tw-mb-2.5'>
        <div> Set a slippage threshold</div>
        <Tooltip
          color={COLORS.TEXT_PRIMARY}
          tooltipBodystyle='f-12-300 !tw-py-3 !tw-px-4 tw-min-w-[300px]'
          tooltipBody='If the total quote amount goes beyond the defined slippage threshold while execution, we will reach out to you to re-confirm the trade basis the revised quote.'
          position={TooltipPositions.RIGHT}
          id='RECIPIENTS_LIST_UNIQUE_CURRENCIES'
        >
          <SvgSpriteLoader id='info-circle' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={12} height={12} />
        </Tooltip>
      </div>
      <div className='tw-flex tw-gap-3 tw-items-center'>
        <Button
          buttonProps={{
            id: 'MOVE_MONEY_TRANSFER_DETAILS_DECREASE_SLIPPAGE',
            eventCallback: trackMixpanel,
            onClick: handleDecreaseSlippage,
            btnType: BUTTON_TYPES.SECONDARY,
            size: BUTTON_SIZE_TYPES.SMALL,
          }}
          showLeadingIcon
          customLeadingIcon={
            <SvgSpriteLoader id='chevron-left' iconCategory={ICON_SPRITE_TYPES.ARROWS} width={16} height={16} />
          }
        />
        <Input
          inputFieldProps={{
            showTrailingIcon: true,
            trailingIconProps: { id: 'percent-02', iconCategory: ICON_SPRITE_TYPES.GENERAL, width: 16, height: 16 },
            inputTagProps: {
              value: `${slippageValue}`,
              onChange: (e) => setSlippageValue(e.target.value),
              type: 'number',
            },
            size: 'SMALL',
          }}
          wrapperClass='tw-w-40'
        />
        <Button
          buttonProps={{
            id: 'MOVE_MONEY_TRANSFER_DETAILS_INCREASE_SLIPPAGE',
            eventCallback: trackMixpanel,
            onClick: handleIncreaseSlippage,
            btnType: BUTTON_TYPES.SECONDARY,
            size: BUTTON_SIZE_TYPES.SMALL,
          }}
          showLeadingIcon
          customLeadingIcon={
            <SvgSpriteLoader id='chevron-right' iconCategory={ICON_SPRITE_TYPES.ARROWS} width={16} height={16} />
          }
        />
      </div>
      {minReceivingAmount > 0 && (
        <div className='f-12-300'>
          *Receiving amount may go till {destCurrencyCode}&nbsp;
          {minReceivingAmount}
        </div>
      )}
    </div>
  );
};

export default Slippage;
