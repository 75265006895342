import { FC } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';
import { COLORS } from 'destiny/dist/constants/colors';
import ChevronUpDownToggle from 'modules/accounts-mea/components/ChevronUpDownToggle';

interface EntityTitleProps {
  title: string;
  isAccordianOpen?: boolean;
}

const EntityTitle: FC<EntityTitleProps> = ({ title = '', isAccordianOpen = false }) => {
  return (
    <div className='tw-w-full tw-flex tw-items-center tw-flex-row tw-gap-4 tw-px-6 tw-py-4 tw-cursor-pointer'>
      <ChevronUpDownToggle isOpen={isAccordianOpen} color={COLORS.TEXT_TERTIARY} />
      <Text textClass='tw-text-TEXT_TERTIARY tw-mb-1 f-13-500 '>{title}</Text>
    </div>
  );
};

export default EntityTitle;
