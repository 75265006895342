import { type FC, useMemo } from 'react';
import { useGetCreateCounterPartyFormQuery } from 'api/beneficiary-v2';
import { BENE_FORM_STEPS, defaultConfig, useBeneficiaryContextStore } from 'modules/beneficiary-v2/beneficiary.context';
import {
  createPrefillDataFormat,
  disabledPrefilledFields,
  updateFormConfig,
} from 'modules/beneficiary-v2/beneficiary.utils';
import BeneficiaryFormWrapper from 'modules/beneficiary-v2/BeneficiaryFormWrapper';
import { CounterPartyEligibility } from 'types/beneficiary-v2';
import InfoBox from 'components/InfoBox';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const BeneficiaryCreateCounterPartyForm: FC<{ prefilledData?: CounterPartyEligibility }> = ({ prefilledData }) => {
  const {
    state: { formStateData = defaultConfig },
  } = useBeneficiaryContextStore();

  const { data, isError, isFetching, refetch } = useGetCreateCounterPartyFormQuery({
    destination_currency: formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.destination_currency?.value,
    entity_id: formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.entity_id?.value,
  });

  const formattedData = useMemo(() => {
    return data?.form?.contextual_data
      ? disabledPrefilledFields(data, data?.form?.contextual_data)
      : updateFormConfig(data, prefilledData);
  }, [prefilledData, data]);

  return (
    <CommonWrapper isError={isError} isLoading={isFetching} refetchFunnction={refetch}>
      <InfoBox text={data?.form?.contextual_data?.form_description} id='BENE_CREATE_STEP_2_FORM_DESCRIPTION' />
      <BeneficiaryFormWrapper
        data={formattedData}
        noDefaultValueFields={prefilledData ? [] : ['country']}
        prefilledData={
          prefilledData
            ? createPrefillDataFormat(prefilledData)
            : data?.form?.contextual_data
            ? createPrefillDataFormat(data?.form?.contextual_data, true)
            : undefined
        }
      />
    </CommonWrapper>
  );
};

export default BeneficiaryCreateCounterPartyForm;
