import { type FC } from 'react';
import { useGetCreateCryptoBeneFormQuery } from 'api/beneficiary-v2';
import { BENE_FORM_STEPS, defaultConfig, useBeneficiaryContextStore } from 'modules/beneficiary-v2/beneficiary.context';
import BeneficiaryFormWrapper from 'modules/beneficiary-v2/BeneficiaryFormWrapper';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const BeneficiaryCreateCryptoForm: FC = () => {
  const {
    state: { formStateData = defaultConfig },
  } = useBeneficiaryContextStore();

  const { data, isError, isFetching, refetch } = useGetCreateCryptoBeneFormQuery({
    custody_provider: formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.beneficiary_bank_name?.value || 'OTHERS',
    recipient_type: formStateData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.beneficiary_account_type?.value || 'Corporate',
  });

  return (
    <CommonWrapper isError={isError} isLoading={isFetching} refetchFunnction={refetch}>
      <BeneficiaryFormWrapper data={data?.form?.stepper_form_sections} />
    </CommonWrapper>
  );
};

export default BeneficiaryCreateCryptoForm;
