import { type FC } from 'react';
import { useDeleteBankAccountWalletMutation } from 'api/beneficiary-v2';
import { defaultFnType } from 'destiny/dist/types';
import { Beneficiary } from 'types/beneficiary-v2';
import { PaymentMethod } from 'types/transactions';
import { toast } from 'utils/toast';
import ConfirmPopup from 'components/popup/ConfirmPopup';

type BeneficiaryBankAccountWalletDeletePopupProps = {
  isOpen: boolean;
  onClose: defaultFnType;
  data: Beneficiary;
};

const BeneficiaryBankAccountWalletDeletePopup: FC<BeneficiaryBankAccountWalletDeletePopupProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  const [deleteBeneficiary, { isLoading }] = useDeleteBankAccountWalletMutation();

  const handleDelete = () => {
    deleteBeneficiary(data?.id)
      .unwrap()
      .then(() => {
        toast.success(`${data?.payout_method === PaymentMethod.FIAT ? 'Account ' : 'Wallet '} deleted successfully`);
        onClose();
      })
      .catch(() => {
        toast.error(
          `Failed to delete ${data?.payout_method === PaymentMethod.FIAT ? 'account ' : 'wallet '}. Please try again.`
        );
      });
  };

  return (
    <ConfirmPopup
      isOpen={isOpen}
      onCancel={onClose}
      onSubmit={handleDelete}
      title='Confirm action'
      submitBtnText='Delete'
      isSubmitButtonLoading={isLoading}
    >
      {`Are you sure to remove ${data?.payout_method === PaymentMethod.FIAT ? 'Account ' : 'Wallet '}${
        data?.beneficiary_account_number
      } from recipient ${data?.counter_party?.name}?`}
    </ConfirmPopup>
  );
};

export default BeneficiaryBankAccountWalletDeletePopup;
