import { type FC } from 'react';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { defaultFnType } from 'destiny/dist/types';
import FullPageLayout from 'layout/FullPageLayout';
import BeneficiaryCreate from 'modules/beneficiary-v2/BeneficiaryCreate';
import { CounterParty } from 'types/beneficiary-v2';
import NoPermissionState from 'components/NoPermissionState';
import AccessWrapper from 'components/wrappers/AccessWrapper';

export type BeneficiaryCreateFullPagePopupProps = {
  onClose: defaultFnType;
  counterParty?: CounterParty;
  refetchCounterParties?: defaultFnType;
};

const BeneficiaryCreateFullPagePopup: FC<BeneficiaryCreateFullPagePopupProps> = ({
  onClose,
  counterParty,
  refetchCounterParties,
}) => {
  return (
    <AccessWrapper
      permissionId={FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId}
      scope={FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope}
      deniedComponent={
        <FullPageLayout id='BENE_CREATE_NO_PERMISSION' onClose={onClose}>
          <NoPermissionState className='tw-h-[calc(100vh-224px)]' />
        </FullPageLayout>
      }
    >
      <BeneficiaryCreate onClose={onClose} counterParty={counterParty} refetchCounterParties={refetchCounterParties} />
    </AccessWrapper>
  );
};

export default BeneficiaryCreateFullPagePopup;
