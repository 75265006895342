import { MenuItem } from 'destiny/dist/types';
import { OptionsType } from 'destiny/dist/types/dropdown';
import { InstructionsTemplateProps } from 'components/instructions-template/InstructionsTemplate';

export enum BULK_PAYMENT_STAGES {
  UPLOAD,
  REVIEW,
  PAY,
}

export const PROGRESS_LINE_LIST: OptionsType[] = [
  {
    value: BULK_PAYMENT_STAGES.UPLOAD,
    label: 'Upload',
  },
  {
    value: BULK_PAYMENT_STAGES.REVIEW,
    label: 'Review',
  },
  {
    value: BULK_PAYMENT_STAGES.PAY,
    label: 'Pay',
  },
];

export const BULK_PAYMENT_INSTRUCTIONS: InstructionsTemplateProps = {
  title: 'Seamlessly execute bulk digital asset payments in just a few clicks',
  points: [
    {
      label: 'Download a template',
      value: 'Pick the template that best meets your requirements, and download it.',
    },
    {
      label: 'Add your payments to the template',
      value: 'Enter the payment information for all the payments you want to make into the downloaded template.',
    },
    {
      label: 'Upload to Roma, review and pay',
      value: 'Upload the file, review all payments before you commit to paying, and send all of them in one go',
    },
  ],
};

export const BULK_PAYMENT_UPLOADER_CONFIG = {
  DEFAULT: {
    title: 'Upload your payments',
    description: 'You can drag and drop your file here. We accept .CSV files.',
    buttonTitle: 'Add a file',
  },
  ERROR: {
    description: 'Your file couldn’t be processed, you can try again with a new file or you can refer to our template.',
    buttonTitle: 'Upload a new file',
  },
  LOADING: {
    description: 'Kindly wait, we are processing your file',
  },
  SUCCESS: {
    description: 'Kindly wait, we are processing your file',
  },
};

export const BULK_PAYMENT_UPLOAD_POLLING_LIMITS = {
  MAX_ATTEMPTS: 200,
  INTERVAL: 3000,
  SUCCESS_TIMEOUT: 1000,
};

export const BULK_PAYMENT_REVIEW_TAB_TYPES = {
  ALL: 'all',
  ERROR: 'error',
};

export const BULK_PAYMENT_REVIEW_TABS: MenuItem[] = [
  {
    label: 'All',
    value: BULK_PAYMENT_REVIEW_TAB_TYPES.ALL,
  },
  {
    label: 'Error',
    value: BULK_PAYMENT_REVIEW_TAB_TYPES.ERROR,
  },
];

export const BULK_PAYMENT_INFO_STATEMENT =
  'The exchange rate has been updated as it has been over 2:00 minutes. Please review the updated amount before proceeding';

export const BULK_PAYMENT_DOWNLOAD_TEMPLATE = {
  title: 'Download a template',
  description: "We've created different file templates to help you get started with bulk payments.",
  file: 'https://storage.googleapis.com/zamp-prd-sg-payments-sdk/assets/Zamp_Bulk_Transfer_Sample.csv',
};
