import { type FC } from 'react';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';

const BeneficiaryBankAccountCurrencyColumn: FC<{ data: string }> = ({ data }) => {
  return (
    <div className='tw-flex tw-items-center tw-gap-4'>
      <SvgSpriteLoader id={data} iconCategory={ICON_SPRITE_TYPES.FIAT_CURRENCIES} width={24} height={24} />
      <div>{data} account</div>
    </div>
  );
};

export default BeneficiaryBankAccountCurrencyColumn;
