import { FC, useState } from 'react';
import { defaultFn } from 'destiny/dist/constants/index';
import AccountsList from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/accountsByEntitySelector/AccountsList';
import EntityTitle from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/accountsByEntitySelector/EntityTitle';
import { AccountByVaultIdType, EntityResponseType } from 'types/accountsApi.types';
import EntityAccordion from 'components/accordian/CardAccordion';

interface AccountsByEntitySelectorProps {
  entity: EntityResponseType;
  defaultOpen: boolean;
  onAccountSelect: (payload: AccountByVaultIdType) => void;
}

const AccountsByEntitySelector: FC<AccountsByEntitySelectorProps> = ({
  entity,
  defaultOpen = true,
  onAccountSelect = defaultFn,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const handleAccordionClick = () => {
    setIsOpen((prevValue) => !prevValue);
  };

  const handleAccountSelect = (selectedAccount: AccountByVaultIdType) => {
    onAccountSelect({ ...selectedAccount, parentEntity: entity });
  };

  return (
    <EntityAccordion
      title={<EntityTitle title={entity?.name ?? entity?.display_name ?? ''} isAccordianOpen={isOpen} />}
      onClick={handleAccordionClick}
      isOpenControlled={isOpen}
      key={entity?.id}
      wrapperClassName='!tw-overflow-visible tw-border-b tw-border-b-DIVIDER_GRAY'
      bodyWrapperOpenClassName='!tw-overflow-visible'
    >
      {entity?.vaults && <AccountsList vaults={entity?.vaults} onAccountSelect={handleAccountSelect} />}
      {entity?.accounts && <AccountsList accountsList={entity?.accounts} onAccountSelect={handleAccountSelect} />}
    </EntityAccordion>
  );
};

export default AccountsByEntitySelector;
