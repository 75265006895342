import { FC } from 'react';
import { OptionsType } from 'destiny/dist/types/dropdown';
import InstructionRow from 'components/instructions-template/InstructionRow';

export interface InstructionsTemplateProps {
  title: string;
  points: OptionsType[];
  className?: string;
}

const InstructionsTemplate: FC<InstructionsTemplateProps> = ({ title, points, className = '' }) => (
  <div className={className}>
    <div className='f-24-400 tw-mb-8'>{title}</div>
    <div className='tw-flex tw-flex-col tw-gap-8'>
      {points?.map((item, index) => (
        <InstructionRow {...item} id={`${index + 1}`} key={`point_${index}`} />
      ))}
    </div>
  </div>
);

export default InstructionsTemplate;
