import { ChangeEvent, type FC, useState } from 'react';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { FILE_SIZE } from 'constants/index';
import { TOTP_ERRORS } from 'constants/mfa';
import { Button } from 'destiny/dist/components/molecules/button';
import { Label } from 'destiny/dist/components/molecules/label';
import { BUTTON_SIZE_TYPES, BUTTON_STATE_TYPES, BUTTON_TYPES, defaultFn } from 'destiny/dist/constants';
import { COLORS } from 'destiny/dist/constants/colors';
import { defaultFnType, MapAny } from 'destiny/dist/types';
import { useAppSelector } from 'hooks/toolkit';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import CheckMFA from 'modules/settings/CheckMFA';
import { NOTES_MANDATORY_CURRENCIES } from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.constants';
import { RootState } from 'store';
import { UploadFileResponseType } from 'types';
import { ApiErrorResponse } from 'types/api';
import { INPUT_FILE_FORMATS } from 'types/mime';
import { CompletedInfoParams, DestCurrency, SourceCurrency } from 'types/transactions';
import { trackMixpanel } from 'utils/mixpanel';
import FileUploaderV3 from 'components/file-uploader/FileUploaderV3';
import useUploadFile from 'components/file-uploader/useUploadFile';
import Textarea from 'components/textarea/Textarea';

export type NotesAndAttachmentsProps = {
  quotePayload?: MapAny;
  data?: MapAny;
  handleBack: defaultFnType;
  onSuccess?: (params: CompletedInfoParams) => void;
  onError: (error?: ApiErrorResponse) => void;
  selectedSourceCurrency?: SourceCurrency;
  selectedSourceAccount?: MapAny | null;
  selectedDestCurrency?: DestCurrency;
  className?: string;
  fileIconColor?: string;
  filenameClassName?: string;
  buttonWrapperClassName?: string;
  selectedRecipientAccount?: MapAny;
  onRequestPayout?: (
    comment: string,
    selectedFiles: UploadFileResponseType[],
    totp: string,
    handleError: (e: ApiErrorResponse) => void
  ) => void;
  isRequestPayoutLoading?: boolean;
  isRequestPayoutDisabled?: boolean;
};

const NotesAndAttachments: FC<NotesAndAttachmentsProps> = ({
  onError = defaultFn,
  handleBack = defaultFn,
  selectedDestCurrency,
  className = 'tw-mt-[140px]',
  fileIconColor = COLORS.ZAMP_PRIMARY,
  filenameClassName = 'tw-text-ZAMP_PRIMARY',
  buttonWrapperClassName = 'tw-min-w-[120px]',
  onRequestPayout,
  isRequestPayoutLoading,
  isRequestPayoutDisabled,
}) => {
  const [comment, setComment] = useState<string>('');
  const [totp, setTotp] = useState('');
  const [totpError, setTotpError] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<UploadFileResponseType[]>([]);

  const { user: userData } = useAppSelector((state: RootState) => state?.user);

  const isMakerRole = !useUserAccessFromPermission(
    FLOW_PERMISSION_PROPERTIES.UPDATE_PAYOUT_REQUESTS.permissionId,
    FLOW_PERMISSION_PROPERTIES.UPDATE_PAYOUT_REQUESTS.scope
  );

  const { uploadFile, isLoading, uploadProgress } = useUploadFile({
    maxSize: FILE_SIZE.THREE_MB,
    acceptedFormats: [
      INPUT_FILE_FORMATS.PNG,
      INPUT_FILE_FORMATS.JPEG,
      INPUT_FILE_FORMATS.JPG,
      INPUT_FILE_FORMATS.PDF,
      INPUT_FILE_FORMATS.BMP,
    ],
  });

  const isNotesMandatory = NOTES_MANDATORY_CURRENCIES.includes(selectedDestCurrency?.code ?? '');

  const isExecutePaymentDisabled =
    !userData?.is_mfa_enabled || totp?.trim()?.length !== 6 || isLoading || (isNotesMandatory && !comment);

  const onChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e?.target) setComment(e?.target?.value);
  };

  const handleSetTotp = (value: string) => {
    setTotp(value);
  };

  const handleError = (e: ApiErrorResponse) => {
    if (e?.data?.error) {
      const { code, message } = e.data.error;

      if (code === TOTP_ERRORS.INVALID_TOTP) setTotpError(message);
      else onError(e);
    } else {
      onError();
    }
  };

  const onSubmit = () => {
    if (!isExecutePaymentDisabled) {
      onRequestPayout?.(comment, selectedFiles, totp, handleError);
    }
  };

  const handleFileSelected = (files: FileList | null, index: number) => {
    if (!files || !files?.length) {
      console.error('No files selected', index);
      if (index >= 0) setSelectedFiles((prev) => prev.filter((_, i) => i !== index));

      return;
    }

    const formattedFiles = Array.from(files ?? []).map((file) => ({
      fileName: file.name,
      rawFile: file,
      identifier: '',
      url: '',
      downloadableUrl: '',
    }));

    formattedFiles.forEach((file) => {
      uploadFile(file.rawFile)
        .then((response) => {
          setSelectedFiles((prevFiles) => {
            let flag = false;

            const newFiles = prevFiles?.map((prevFile) => {
              if (prevFile.fileName === file.fileName) {
                flag = true;

                return {
                  ...prevFile,
                  ...response,
                };
              }

              return prevFile;
            });

            if (!flag) newFiles.push(response);

            return newFiles;
          });
        })
        .catch(() => {
          console.error('Error uploading file');
        });
    });
  };

  return (
    <div className={`tw-w-[390px] ${className}`}>
      <div className='tw-space-y-8'>
        <div>
          <Label description='Attachments (optional)' descriptionClass='f-12-300 tw-text-TEXT_PRIMARY tw-mb-2.5' />
          <FileUploaderV3
            onChange={handleFileSelected}
            selectedFileNames={selectedFiles?.map((file) => file.fileName)}
            isLoading={isLoading}
            uploadProgress={uploadProgress}
            iconColor={fileIconColor}
            filenameClassName={filenameClassName}
            acceptedFormats={`${INPUT_FILE_FORMATS.PNG}, ${INPUT_FILE_FORMATS.JPEG}, ${INPUT_FILE_FORMATS.JPG}, ${INPUT_FILE_FORMATS.PDF}, ${INPUT_FILE_FORMATS.BMP}`}
          />
        </div>
        <div>
          <Label
            description={`Notes ${isNotesMandatory ? '' : '(optional)'}`}
            descriptionClass='f-12-300 tw-text-TEXT_PRIMARY tw-mb-2.5'
          />
          <Textarea
            id='single-transfer-comments-textarea'
            name='text'
            value={comment}
            textAreaStyle='tw-bg-white tw-h-[60px] tw-px-6 tw-py-5 f-14-300 tw-text-TEXT_PRIMARY tw-rounded-[5px] focus:tw-border-ZAMP_PRIMARY focus:tw-outline-0'
            onChange={onChangeComment}
            autoFocus={true}
            placeHolder=''
          />
        </div>
        <CheckMFA
          wrapperClassName=''
          setOtp={handleSetTotp}
          onOtpSubmit={onSubmit}
          otpError={totpError}
          autoFocus={false}
          showDivider={false}
          labelStyle='tw-text-TEXT_SECONDARY f-12-300 tw-mb-3'
          otpInputProps={{
            wrapperClassName: 'tw-gap-4',
            customStyles: 'tw-w-[52px] tw-h-[52px]',
          }}
          enableDescription='Before you proceed, add an extra layer of protection used to ensure the security'
          customEnableMFAStyles={{
            enableWrapperClass: 'tw-p-4',
            enableIconWrapperClass: 'tw-min-w-[80px] tw-min-h-[86px] tw-mr-8',
            enableIconWidth: 80,
            enableIconHeight: 86,
            enableLabelClass: 'f-14-400 tw-mb-2.5',
            enableDescriptionClass: 'f-12-300 tw-mb-2.5',
            enableButtonClass:
              'tw-bg-white tw-rounded-[100px] tw-border hover:tw-border-TEXT_PRIMARY !tw-h-[35px] tw-py-[9px] tw-px-4 f-12-400',
          }}
        />
      </div>
      <div className='tw-flex tw-gap-4 tw-mt-6'>
        <Button
          buttonProps={{
            btnType: BUTTON_TYPES.SECONDARY,
            size: BUTTON_SIZE_TYPES.MEDIUM,
            state: BUTTON_STATE_TYPES.DEFAULT,
            wrapperClass: buttonWrapperClassName,
            onClick: handleBack,
            id: 'MOVE_MONEY_SINGLE_TRANSFER_REVIEW_SECONDARY_BUTTON',
            eventCallback: trackMixpanel,
          }}
        >
          Back
        </Button>

        <Button
          buttonProps={{
            btnType: BUTTON_TYPES.PRIMARY,
            size: BUTTON_SIZE_TYPES.MEDIUM,
            state: BUTTON_STATE_TYPES.DEFAULT,
            wrapperClass: buttonWrapperClassName,
            isLoading: isRequestPayoutLoading,
            disabled: isExecutePaymentDisabled || isRequestPayoutDisabled,
            id: 'MOVE_MONEY_SINGLE_TRANSFER_PAY_BUTTON',
            eventCallback: trackMixpanel,
            onClick: onSubmit,
          }}
        >
          {isMakerRole ? 'Send for approval' : 'Pay'}
        </Button>
      </div>
    </div>
  );
};

export default NotesAndAttachments;
