import { ReactNode } from 'react';
import { BLUE_LOADER } from 'constants/lottie/loading_blue';
import { Button } from 'destiny/dist/components/molecules/button';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { MIME } from 'types/mime';
import { downloadFile } from 'utils/common';
import { trackMixpanel } from 'utils/mixpanel';
import Player from 'components/DynamicLottiePlayer';

type DownloadButtonProps = {
  url: string;
  mime: MIME;
  setIsLoading: (flag: boolean) => void;
  isLoading: boolean;
  title: ReactNode;
  className?: string;
  btnType?: BUTTON_TYPES;
  icon?: string;
  size?: BUTTON_SIZE_TYPES;
  iconCategory?: ICON_SPRITE_TYPES;
  buttonId: string;
  iconDimension?: {
    width: number;
    height: number;
  };
};

const DownloadButton: React.FC<DownloadButtonProps> = ({
  url,
  setIsLoading,
  isLoading,
  title,
  className,
  btnType = BUTTON_TYPES.SECONDARY,
  size = BUTTON_SIZE_TYPES.LARGE,
  iconDimension = { width: 18, height: 18 },
  icon = 'file-download-03',
  iconCategory = ICON_SPRITE_TYPES.FILES,
  buttonId = '',
}) => {
  const handleClick = () => {
    setIsLoading(true);
    downloadFile(url, setIsLoading);
  };

  return (
    <Button
      buttonProps={{
        eventCallback: trackMixpanel,
        id: buttonId,
        btnType: btnType,
        onClick: handleClick,
        size: size,
        wrapperClass: `tw-w-[264px] ${className}`,
      }}
      showLeadingIcon={!isLoading}
      customLeadingIcon={<SvgSpriteLoader id={icon} iconCategory={iconCategory} {...iconDimension} />}
    >
      {isLoading ? (
        <Player src={JSON.parse(BLUE_LOADER)} className='lottie-player' loop autoplay />
      ) : (
        <div className='tw-flex'>
          {typeof title === 'string' ? <div className='tw-whitespace-nowrap'>{title}</div> : title}
        </div>
      )}
    </Button>
  );
};

export default DownloadButton;
