import { mapPaymentMethodNames } from 'constants/transactions';
import { store } from 'store';
import { CorridorType } from 'types';
import {
  CORRIDOR_TYPE,
  PaymentMethod,
  TransactionDetailsHeadings,
  TransactionListColumns,
  TransactionListItemType,
  TransactionType,
  TxnType,
} from 'types/transactions';
import { getMonDayFormat, getTime, getTrimmedAmount } from 'utils/common';

const getToFrom = (transaction: TransactionType) => {
  return transaction.transfer_type === TxnType.DEBIT
    ? transaction.beneficiary_name
    : transaction.transfer_type === TxnType.CREDIT
    ? transaction.beneficiary_name
      ? transaction.beneficiary_name
      : transaction.source_account_address
    : 'N/A';
};

const getSender = (transaction: TransactionType): string => {
  if (transaction.transfer_type === TxnType.CREDIT)
    return transaction.transfer_method === PaymentMethod.FIAT
      ? transaction.beneficiary_name
      : transaction.source_account_address;

  return '';
};

const getReceiver = (transaction: TransactionType): string => {
  if (transaction.transfer_type === TxnType.DEBIT) return transaction.beneficiary_name;

  return '';
};

export const getTransactionListItemData = (transaction: TransactionType): TransactionListItemType => {
  const txnListItem: TransactionListItemType = {
    [TransactionListColumns.DATE]: getMonDayFormat(transaction.created_at),
    [TransactionListColumns.TO_FROM]: getToFrom(transaction),
    [TransactionListColumns.AMOUNT]: getTrimmedAmount(transaction.source_amount),
    [TransactionListColumns.CURRENCY]: transaction.source_currency_display_name,
    [TransactionListColumns.PAYMENT_METHOD]:
      mapPaymentMethodNames[transaction.transfer_method] || mapPaymentMethodNames[PaymentMethod.BANK_TRANSFER],
    [TransactionListColumns.STATUS]: transaction.status,
  };

  return txnListItem;
};

export const getTransactionDetails = (transaction: TransactionType) => {
  const txnDetails = {
    [TransactionDetailsHeadings.TRANSACTION_ID]: transaction.id,
    [TransactionDetailsHeadings.DATE]: `${getMonDayFormat(transaction.created_at)} at ${getTime(
      transaction.created_at
    )}`,
    [TransactionDetailsHeadings.SENDER]: getSender(transaction),
    [TransactionDetailsHeadings.BENEFICIARY]: getReceiver(transaction),
    [TransactionDetailsHeadings.SOURCE_AMOUNT]: `${getCurrencyName(transaction.source_currency_code)} ${
      transaction.source_amount
    }`,
    [TransactionDetailsHeadings.RECEIVING_AMOUNT]: `${getCurrencyName(transaction.dest_currency_code)} ${
      transaction.receiving_amount
    }`,
    [TransactionDetailsHeadings.REF_NOTE]: '',
    [TransactionDetailsHeadings.TRANSACTION_HASH]: transaction.transaction_hash,
    [TransactionDetailsHeadings.BANK_REF_NO]: transaction.bank_reference_id,
    [TransactionDetailsHeadings.STATUS]: transaction.status,
    [TransactionDetailsHeadings.TOTAL_FEES]: `${getCurrencyName(transaction.fixed_cost_currency)} ${
      transaction.total_cost
    }`,
  };

  return txnDetails;
};

export const getCurrencyName = (code: string): string => {
  const { config } = store.getState();
  const { currencyCodeAndNameMap } = config;

  return currencyCodeAndNameMap[code];
};

export const isCryptoCorridorAvailableUtil = (corridors?: CorridorType[]): boolean => {
  if (corridors)
    for (let i = 0; i < corridors?.length; i++) if (corridors[i].type === CORRIDOR_TYPE.CRYPTO_CRYPTO) return true;

  return false;
};
