import { type FC, useEffect, useRef, useState } from 'react';
import { useGetUpdateBeneficiaryFormQuery } from 'api/beneficiary';
import { useUpdateBeneficiaryAdditionalDetailsMutation } from 'api/beneficiary-v2';
import { FormFieldType } from 'constants/kyb';
import { Label } from 'destiny/dist/components/molecules/label';
import { BUTTON_SIZE_TYPES } from 'destiny/dist/constants';
import { BANK_TYPES_FOR_RFI } from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.constants';
import { getFormattedBeneData } from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.utils';
import { defaultFnType, MapAny } from 'types';
import { BeneficiaryPayoutEligibilty } from 'types/beneficiary';
import { PaymentMethod } from 'types/transactions';
import Avatar from 'components/avatar/Avatar';
import CustomDropdownWidget from 'components/CustomDropdownWidget';
import JsonSchemaForm, { defaultWidgetMap } from 'components/form/jsonSchemaForm/JsonSchemaForm';
import SkeletonLoader from 'components/form/jsonSchemaForm/SkeletonLoader';
import BottomBar from 'components/kyb/Bottombar';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const PLACEHOLDER_VALUE = 'placeholderValue';

const AdditionalBeneDetails: FC<{
  recipientId?: string;
  onNext: defaultFnType;
  onError: defaultFnType;
  onBack: defaultFnType;
  prefilledBeneData?: BeneficiaryPayoutEligibilty;
}> = ({ recipientId, onNext, onError, onBack, prefilledBeneData }) => {
  const { data, isFetching, isError } = useGetUpdateBeneficiaryFormQuery(recipientId ?? '');
  const [updateBene, { isLoading }] = useUpdateBeneficiaryAdditionalDetailsMutation();
  const formRef = useRef<any>();
  const [schema, setSchema] = useState<any>(null);
  const [defaultFormData, setDefaultFormData] = useState<any>(null);
  const [updatedFormData, setUpdatedFormData] = useState<any>(null);
  const [schemaChanged, setSchemaChanged] = useState<boolean>(false);
  const [defaultFormDataChanged, setDefaultFormDataChanged] = useState<boolean>(false);
  const [decisionKeys, setDecisionKeys] = useState({ currencyType: '', destinationCurrency: '' });

  const getSchema = (key1?: string, key2?: string) => {
    const default1: string = key1 ?? data?.default;
    const default2: string = key2 ?? data?.[default1?.toLowerCase()]?.default;

    setDecisionKeys({
      currencyType: default1,
      destinationCurrency: default2,
    });

    const defaultLevelData = data?.[default1?.toLowerCase()];

    return defaultLevelData?.currencies?.[default2] ?? defaultLevelData;
  };

  const getDefaultFormData = (properties: any, keys: MapAny, prefilledData?: MapAny) => {
    const defaultFormData = Object.keys(properties).reduce((acc: MapAny, key: string) => {
      const property = properties[key];
      const displayType = schema?.displayTypes?.[key];
      const placeholderValue = displayType === FormFieldType.INFO ? PLACEHOLDER_VALUE : null;

      const selectValueIndex = property?.enum?.findIndex((item: any) => item?.id === property?.default);
      const selectValue = property?.enum?.[selectValueIndex]?.id;

      acc[key] =
        (displayType === FormFieldType.SELECT ||
        displayType === FormFieldType.TABS ||
        displayType === FormFieldType.RADIO
          ? selectValue
          : property?.default) ?? placeholderValue;

      return acc;
    }, {});

    Object.keys(keys)?.forEach((keyName) => {
      const mappedKey = keys?.[keyName];
      const dataValue = prefilledData?.[mappedKey];

      if (mappedKey && dataValue) defaultFormData[keyName] = dataValue;
    });

    return defaultFormData;
  };

  useEffect(() => {
    if (!schema && data) handleSchemaChange(getSchema());
  }, [data]);

  useEffect(() => {
    if (schemaChanged && schema?.properties)
      handleFormDataChange(getDefaultFormData(schema?.properties, schema?.keys, prefilledBeneData));
  }, [schemaChanged]);

  const handleSchemaChange = (newSchema: any) => {
    setSchema(newSchema);
    setSchemaChanged(true);
  };

  const handleFormDataChange = (newFormData: any) => {
    setUpdatedFormData(newFormData);
    setDefaultFormData(newFormData);
    setDefaultFormDataChanged(true);
  };

  const handleChange = ({ formData = {} }: { formData: MapAny }, rootKey: string) => {
    if (rootKey && !schemaChanged) {
      setUpdatedFormData(formData);

      const changedKey = rootKey?.split('_')?.[1];

      if (formData?.[changedKey] !== defaultFormData?.[changedKey])
        if (changedKey === 'currencyType') handleSchemaChange(getSchema(formData[changedKey]));
        else if (changedKey === 'destinationCurrency')
          handleSchemaChange(getSchema(decisionKeys.currencyType?.toLowerCase(), formData[changedKey]));
    }
  };

  const onChangeOverride = (updatedField: any = {}) =>
    setUpdatedFormData((prevUpdatedFormData: any) => ({ ...prevUpdatedFormData, ...updatedField }));

  const handleSubmit = async (formData: MapAny = {}) => {
    const payload: any = {};

    Object.keys(formData)?.forEach((keyName) => {
      const mappedKey = schema?.keys?.[keyName];
      const dataValue = formData[keyName];

      if (!!mappedKey && !!dataValue && keyName === 'beneficiaryCustody') {
        if (dataValue === BANK_TYPES_FOR_RFI.OTHERS) {
          payload['beneficiary_bank_type'] = BANK_TYPES_FOR_RFI.OTHERS;
        } else {
          payload['beneficiary_bank_type'] = BANK_TYPES_FOR_RFI.VASP;
        }
      }

      if (mappedKey && dataValue && dataValue !== PLACEHOLDER_VALUE) payload[mappedKey] = formData[keyName];
    });

    updateBene({
      payload,
      id: recipientId,
    })
      .unwrap()
      .then(() => {
        onNext?.();
      })
      .catch(onError);
  };

  const onSubmit = ({ formData = {} }: { formData: MapAny }) => handleSubmit(formData);

  const handleError = (errors: any) => {
    if (errors?.length === 0) formRef?.current?.submit?.();
  };

  const handleSubmitButtonclick = () => {
    if (formRef?.current?.validateForm?.()) formRef?.current?.submit?.();
  };

  useEffect(() => {
    if (isError) onError?.();
  }, [isError]);

  return (
    <div>
      <CommonWrapper isLoading={isFetching} skeletonType={SkeletonTypes.CUSTOM} skeleton={<SkeletonLoader />}>
        {prefilledBeneData?.payout_method === PaymentMethod.CRYPTO && (
          <div className='tw-p-4 tw-border tw-border-DIVIDER_GRAY tw-rounded-[5px]'>
            <div className='f-12-300 tw-mb-2'>Paying to</div>
            <div className='tw-flex tw-items-center tw-py-1 tw-mb-3'>
              <Avatar name={schema?.beneficiaryDetails?.beneficiaryName ?? ''} className='tw-mr-3' />
              <Label
                title={schema?.beneficiaryDetails?.beneficiaryName}
                description={schema?.beneficiaryDetails?.beneficiaryAccountNumber}
                titleClass='f-14-400'
                descriptionClass='f-12-300 tw-text-TEXT_SECONDARY'
              />
            </div>
            <div className='f-12-300 tw-space-y-3'>
              {getFormattedBeneData(schema?.beneficiaryDetails).map(
                (item) =>
                  !!item.value && (
                    <div className='tw-flex tw-justify-between' key={item.value}>
                      <div className='tw-text-TEXT_SECONDARY tw-whitespace-nowrap'>{item.label}</div>
                      <div className='tw-max-w-[200px] tw-truncate' title={`${item.value}`}>
                        {item.value}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        )}
        <div className='f-14-300 tw-text-TEXT_SECONDARY tw-mt-6 tw-mb-4'>
          {prefilledBeneData?.payout_method === PaymentMethod.FIAT
            ? 'We require a few more details to ensure your transaction is smooth and secure.'
            : 'For transactions over $15,000, we require a few extra details to comply with regulations.'}
        </div>
        <JsonSchemaForm
          schema={schema}
          formData={schemaChanged ? defaultFormData : updatedFormData}
          resetUiSchema={schemaChanged && defaultFormDataChanged}
          onUiSchemaSet={() => setSchemaChanged(false)}
          onChange={handleChange}
          onSubmit={onSubmit}
          onError={handleError}
          customProps={{ onChangeOverride, schemaId: 'UPDATE_RECIPIENT_FORM' }}
          displayTypes={schema?.displayTypes}
          liveValidate={false}
          ref={formRef}
          skeleton={<SkeletonLoader />}
          widgetMap={{ ...defaultWidgetMap, [FormFieldType.SELECT]: CustomDropdownWidget }}
          buttons={
            <>
              <div className='tw-mb-[90px]'></div>
              <div className='tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-bg-white'>
                <BottomBar
                  onNext={handleSubmitButtonclick}
                  onBack={onBack}
                  nextBtnText='Proceed'
                  isLoading={isLoading}
                  backButtonProps={{ size: BUTTON_SIZE_TYPES.MEDIUM }}
                  nextButtonProps={{ size: BUTTON_SIZE_TYPES.MEDIUM }}
                  className='!tw-justify-center'
                />
              </div>
            </>
          }
          id='UPDATE_RECIPIENT_FORM'
          prefilledFormData={prefilledBeneData}
        />
      </CommonWrapper>
    </div>
  );
};

export default AdditionalBeneDetails;
