import { type FC } from 'react';
import { MapAny } from 'destiny/dist/types';
import Form from 'components/form/Form';

type BeneficiaryFormSectionProps = {
  formStateData: MapAny;
  formConfig: MapAny;
  onChange: (key: string, value: any) => void;
  errors: MapAny;
  hideSectionPart?: boolean;
  allValues: MapAny[];
  wrapperClass?: string;
};

const BeneficiaryFormSection: FC<BeneficiaryFormSectionProps> = ({
  formStateData,
  formConfig,
  onChange,
  errors,
  hideSectionPart = false,
  allValues,
  wrapperClass,
}) => (
  <div className='tw-flex tw-justify-between'>
    {!hideSectionPart && (
      <div className='f-14-400 tw-text-TEXT_SECONDARY tw-text-left tw-py-4 tw-w-56'>{formConfig?.section_title}</div>
    )}
    <Form
      id={formConfig?.key?.toUpperCase()}
      formFields={formConfig}
      formState={formStateData}
      errors={errors}
      updateFormState={onChange}
      isDestinyComponent
      useDescriptionLabel
      wrapperClass={`tw-flex tw-flex-col tw-pt-3 ${hideSectionPart ? 'tw-w-[720px]' : 'tw-w-[480px]'} ${wrapperClass}`}
      customClassNames={{
        customInputText: { size: 'SMALL', errClassName: 'f-12-300 tw-text-RED_PRIMARY tw-mb-3 !tw-mt-0' },
        customCheckbox: {
          labelOverrideClassName: 'f-14-400 tw-text-TEXT_SECONDARY',
        },
        customInput:
          'tw-mb-3 f-16-300 tw-w-full tw-box-border	tw-text-TEXT_PRIMARY tw-bg-BASE_SECONDARY tw-border tw-rounded-[5px] tw-border-DIVIDER_GRAY focus:tw-border-ZAMP_PRIMARY focus:tw-outline-0 tw-h-[44px] tw-py-3 tw-px-6 ',
      }}
      allValues={allValues}
      defaultStyle={false}
      scrollToErr
    />
  </div>
);

export default BeneficiaryFormSection;
