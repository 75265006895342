import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useInitiateBulkPaymentMutation, useLazyGetBulkPaymentByIdQuery } from 'api/bulk-payment';
import { FILE_SIZE, SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { defaultFn } from 'destiny/dist/constants';
import { useAppDispatch } from 'hooks/toolkit';
import usePolling from 'hooks/usePolling';
import { useSessionToken } from 'hooks/useSessionToken';
import BulkFileUploader from 'modules/bulk-payment/BulkFileUploader';
import { BULK_PAYMENT_UPLOAD_POLLING_LIMITS } from 'modules/bulk-payment/BulkPayment.constants';
import { updateInitiatedPayouts } from 'store/slices/bulk-payouts';
import { ApiErrorResponse, GetPayoutsByBulkIdResponse } from 'types/api';
import { INPUT_FILE_FORMATS } from 'types/mime';
import { TxnStatus } from 'types/transactions';
import FileUploaderWrapper from 'components/file-uploader/FileUploaderWrapper';
import Popup from 'components/popup/Popup';

interface BulkPaymentUploadPopup {
  isOpen: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const BulkPaymentUploadPopup: FC<BulkPaymentUploadPopup> = ({ isOpen, onClose }) => {
  const [error, setError] = useState<string>('');
  const [isFileProcessing, setIsFileProcessing] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [initiateBulkPayout] = useInitiateBulkPaymentMutation();
  const [getPayoutsByBulkId] = useLazyGetBulkPaymentByIdQuery();

  const { sessionToken, createSessionToken } = useSessionToken(SESSION_CAPABILITY_CONTEXT_KEYS.BULK_PAYOUT_CREATE);

  const { startPolling } = usePolling();

  const dispatch: any = useAppDispatch();

  const handleError = (error: ApiErrorResponse) => {
    createSessionToken();
    setError(error?.data?.error?.message ?? error);
    setIsFileProcessing(false);
  };

  const pollForData = ({ id = '' }) => {
    startPolling({
      fn: () => getPayoutsByBulkId({ id }),
      validate: ({ status = '' }) => {
        return status === TxnStatus.VALIDATED || status === TxnStatus.VALIDATION_FAILED;
      },
      maxAttempts: BULK_PAYMENT_UPLOAD_POLLING_LIMITS.MAX_ATTEMPTS,
      interval: BULK_PAYMENT_UPLOAD_POLLING_LIMITS.INTERVAL,
    })
      .then((data) => {
        if (data?.status === TxnStatus.VALIDATION_FAILED && (data?.error_rows?.length ?? 0) < 1)
          handleError(data?.error);
        else {
          dispatch(updateInitiatedPayouts(data as GetPayoutsByBulkIdResponse));
          setIsFileProcessing(false);
          setIsSuccess(true);
          setTimeout(() => onClose(false), BULK_PAYMENT_UPLOAD_POLLING_LIMITS.SUCCESS_TIMEOUT);
        }
      })
      .catch(handleError);
  };

  const handleFileChange = (file: string | null) => {
    if (file) {
      setError('');
      setIsFileProcessing(true);
      initiateBulkPayout({ data: { key: file }, idempotencyHeader: sessionToken ?? '' })
        .unwrap()
        .then(pollForData)
        .catch(handleError);
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      handleVisibility={onClose}
      className='tw-bg-white tw-w-[500px] tw-rounded-2.5'
      closeOnClickOutside={!isFileProcessing}
    >
      <FileUploaderWrapper
        filesSelected={''}
        errorMsg={error}
        onFilesSelect={handleFileChange}
        disableNext={defaultFn}
        acceptedFormats={INPUT_FILE_FORMATS.CSV}
        isFileUploading={isFileProcessing}
        maxSize={FILE_SIZE.THREE_MB}
        setParentError={setError}
        Component={BulkFileUploader}
        isSuccess={isSuccess}
      />
    </Popup>
  );
};

export default BulkPaymentUploadPopup;
