import { ChangeEvent, useState } from 'react';
import { useExecuteInternalTransferV2Mutation } from 'api/internal-transfer';
import { useGetQuoteFiatQuery } from 'api/moveMoney';
import { INFO_ROUND } from 'constants/icons';
import { TOTP_ERRORS } from 'constants/mfa';
import { Text } from 'destiny/dist/components/atoms/text';
import { Button } from 'destiny/dist/components/molecules/button';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { defaultFn } from 'destiny/dist/constants';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { BUTTON_SIZE_TYPES, BUTTON_STATE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { useAppSelector } from 'hooks/toolkit';
import CheckMFA from 'modules/settings/CheckMFA';
import AmountDetails from 'modules/single-transfer/fiat/AmountDetails';
import RfqSummarySkeleton from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/RfqSummarySkeleton';
import Image from 'next/image';
import { RootState } from 'store';
import { ApiErrorResponse } from 'types/api';
import { ThirdPartyReviewAndPayProps } from 'types/transactions';
import { trackMixpanel } from 'utils/mixpanel';
import { ErrorCardTypes } from 'components/banners/types';
import { SkeletonTypes } from 'components/skeletons/types';
import Textarea from 'components/textarea/Textarea';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const ReviewAndPay: React.FC<ThirdPartyReviewAndPayProps> = ({
  selectedSourceCurrency,
  selectedDestCurrency,
  quoteId = '',
  isQuoteAutoUpdated = false,
  onSuccess = defaultFn,
  onError = defaultFn,
  handleBack = defaultFn,
}) => {
  const [totp, setTotp] = useState('');
  const [totpError, setTotpError] = useState('');
  const [comment, setComment] = useState<string>('');
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false);
  const { currencyCodeAndNameMap } = useAppSelector((state: RootState) => state.config);
  const { user: userData } = useAppSelector((state: RootState) => state?.user);
  const { data, isLoading, isError, refetch } = useGetQuoteFiatQuery({ quoteId }, { skip: !quoteId });
  const [executePayment, { isLoading: isInitiateLoading }] = useExecuteInternalTransferV2Mutation();
  const isExecutePaymentDisabled = !userData?.is_mfa_enabled || totp?.trim()?.length !== 6 || isLoading;

  const handleError = (e: ApiErrorResponse) => {
    if (e?.data?.error) {
      const { code, message } = e.data.error;

      if (code === TOTP_ERRORS.INVALID_TOTP) setTotpError(message);
      else onError(e);
    } else {
      onError();
    }
  };

  const handleSubmit = () => {
    executePayment({
      data: {
        quote_id: quoteId,
        comments: comment,
        source_account_id: selectedSourceCurrency?.source_account_id ?? '',
        dest_account_id: selectedDestCurrency?.dest_account_id ?? '',
      },
      totp,
      accountId: selectedSourceCurrency?.source_account_id ?? '',
    })
      .unwrap()
      .then(() => {
        onSuccess({
          amount: data?.source_amount,
          currency: selectedSourceCurrency ? currencyCodeAndNameMap[selectedSourceCurrency?.code] : '',
        });
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const handleSetTotp = (value: string) => {
    setTotp(value);
  };

  const handleShowCommentBox = () => {
    setShowCommentBox((prevValue) => !prevValue);
  };

  const handleAddComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e?.target) setComment(e?.target?.value);
  };

  return (
    <div className='tw-mt-6'>
      <CommonWrapper
        isLoading={isLoading}
        skeletonType={SkeletonTypes.CUSTOM}
        skeleton={<RfqSummarySkeleton isQuoteAutoUpdated={isQuoteAutoUpdated} />}
        isError={isError}
        errorCardType={ErrorCardTypes.API_FAIL}
        refetchFunnction={refetch}
      >
        <>
          <div className='tw-mb-3'>
            <div className='tw-flex tw-items-center tw-justify-between tw-mb-3.5'>
              <div className='tw-text-TEXT_PRIMARY f-12-300'>You are sending</div>
              <div className='tw-text-TEXT_BLACK_1 f-16-400'>{`${data?.source_amount} ${
                selectedSourceCurrency ? currencyCodeAndNameMap[selectedSourceCurrency?.code] : ''
              }`}</div>
            </div>

            {selectedSourceCurrency && selectedDestCurrency && (
              <AmountDetails
                transferDetails={data}
                corridor={selectedSourceCurrency?.corridor_type}
                destinationCurrency={currencyCodeAndNameMap?.[selectedDestCurrency?.code]}
                sourceCurr={currencyCodeAndNameMap?.[selectedSourceCurrency?.code]}
                className='tw-mb-1'
                itemsParentWrapperClassName='tw-flex-row-reverse tw-justify-between tw-mb-2'
                itemsWrapperClassName='tw-justify-end'
                currencyTextClassName='tw-text-TEXT_SECONDARY f-12-300'
                labelClassName='tw-text-TEXT_TERTIARY f-12-300'
                showIcon={false}
                feeBreakupItemClassName='tw-flex-row-reverse tw-justify-between'
                feeBreakupValueClassName='f-12-400 tw-text-TEXT_SECONDARY'
                feeBreakupWrapperClassName='tw-mb-2'
              />
            )}

            <div className='tw-flex tw-items-center tw-justify-between'>
              <div className='tw-text-TEXT_PRIMARY f-12-300'>You are getting</div>
              <div className='tw-text-TEXT_BLACK_1 f-16-400'>{`${data?.receiving_amount} ${
                selectedDestCurrency ? currencyCodeAndNameMap?.[selectedDestCurrency?.code] : ''
              }`}</div>
            </div>
          </div>

          {isQuoteAutoUpdated && (
            <div className='tw-flex tw-justify-between tw-px-4 tw-py-3 tw-bg-BASE_PRIMARY tw-rounded-2.5 tw-mb-3'>
              <div className='tw-shrink-0 tw-relative tw-mr-4'>
                <Image src={INFO_ROUND} alt='info' width={13} height={13} />
              </div>
              <div className='f-12-300 tw-text-TEXT_SECONDARY'>
                The exchange rate has been updated as it has been over 2:00 minutes. Please review the updated amount
                before proceeding
              </div>
            </div>
          )}
        </>
      </CommonWrapper>

      <div className='tw-mt-3 tw-mb-6'>
        <div
          className='tw-flex tw-items-center tw-py-2 tw-cursor-pointer'
          role='presentation'
          onClick={handleShowCommentBox}
        >
          <SvgSpriteLoader
            id={showCommentBox ? 'minus-circle' : 'plus-circle'}
            iconCategory={ICON_SPRITE_TYPES.GENERAL}
            width={16}
            height={16}
            className='tw-mr-2.5'
          />
          <Text textClass='tw-text-TEXT_PRIMARY f-14-300 tw-select-none'>Anything you’d like to add?</Text>
        </div>
        {showCommentBox && (
          <Textarea
            id='comments'
            name='text'
            value={comment}
            placeHolder='Add any notes for future reference...'
            textAreaStyle='tw-bg-white tw-h-[60px] tw-px-6 tw-py-5 f-14-300 tw-text-TEXT_PRIMARY tw-rounded-[5px] placeholder:tw-text-TEXT_TERTIARY placeholder:tw-text-sm placeholder:tw-font-light placeholder:tw-tracking-[0.03em] focus:tw-border-ZAMP_PRIMARY focus:tw-outline-0'
            onChange={handleAddComment}
            autoFocus={true}
          />
        )}
      </div>

      <CheckMFA
        wrapperClassName=''
        setOtp={handleSetTotp}
        onOtpSubmit={handleSubmit}
        otpError={totpError}
        autoFocus={false}
        showDivider={false}
        labelStyle='tw-text-TEXT_SECONDARY f-12-300 tw-mb-3'
        otpInputProps={{
          wrapperClassName: 'tw-gap-4',
          customStyles: 'tw-w-[52px] tw-h-[52px]',
        }}
        enableDescription='Before you proceed, add an extra layer of protection used to ensure the security'
        customEnableMFAStyles={{
          enableWrapperClass: 'tw-p-4',
          enableIconWrapperClass: 'tw-min-w-[80px] tw-min-h-[86px] tw-mr-8',
          enableIconWidth: 80,
          enableIconHeight: 86,
          enableLabelClass: 'f-14-400 tw-mb-2.5',
          enableDescriptionClass: 'f-12-300 tw-mb-2.5',
          enableButtonClass:
            'tw-bg-white tw-rounded-[100px] tw-border hover:tw-border-TEXT_PRIMARY !tw-h-[35px] tw-py-2 tw-px-4 f-12-400',
        }}
      />

      <div className='tw-flex tw-gap-4 tw-mt-6'>
        <Button
          buttonProps={{
            btnType: BUTTON_TYPES.SECONDARY,
            size: BUTTON_SIZE_TYPES.MEDIUM,
            state: BUTTON_STATE_TYPES.DEFAULT,
            wrapperClass: 'tw-min-w-[120px]',
            onClick: handleBack,
            id: 'MOVE_MONEY_SINGLE_TRANSFER_REVIEW_SECONDARY_BUTTON',
            eventCallback: trackMixpanel,
          }}
        >
          Back
        </Button>

        <Button
          buttonProps={{
            btnType: BUTTON_TYPES.PRIMARY,
            size: BUTTON_SIZE_TYPES.MEDIUM,
            state: BUTTON_STATE_TYPES.DEFAULT,
            wrapperClass: 'tw-min-w-[120px]',
            isLoading: isInitiateLoading,
            disabled: isExecutePaymentDisabled,
            id: 'MOVE_MONEY_INTERNAL_TRANSFER_REVIEW_PRIMARY_BUTTON',
            eventCallback: trackMixpanel,
            onClick: handleSubmit,
          }}
        >
          Transfer
        </Button>
      </div>
    </div>
  );
};

export default ReviewAndPay;
