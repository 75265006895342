import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { CHEVRON_RIGHT_BLACK } from 'constants/icons';
import mixpanelEvents from 'constants/mixpanel';
import { useAppSelector } from 'hooks/toolkit';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import Image from 'next/image';
import { RootState } from 'store';
import { trackMixpanel } from 'utils/mixpanel';
import Button from 'components/button/Button';
import { ButtonTypes } from 'components/button/types';

interface TransferButtonProps {
  path?: string;
  isDisabled?: boolean;
  setShowMoveMoney: (flag: boolean) => void;
}

const TransferButton: React.FC<TransferButtonProps> = ({ setShowMoveMoney, path, isDisabled = false }) => {
  const { isBankingKybSuccess } = useAppSelector((state: RootState) => state.kyb);

  const canDoTransfer =
    useUserAccessFromPermission(
      FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId,
      FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope
    ) && isBankingKybSuccess;

  if (!canDoTransfer) return null;

  return (
    <Button
      id={'transfer'}
      btnType={ButtonTypes.ACCENT}
      onClick={() => {
        trackMixpanel(mixpanelEvents.TRANSFER_BUTTON_CLICK, { SourcePage: path });
        setShowMoveMoney(true);
      }}
      className={`!tw-h-[35px] tw-w-[140px] tw-text-xs ${isDisabled ? 'tw-pointer-events-none' : ''}`}
      style={{ paddingLeft: '16px', paddingRight: '16px' }}
      isDisabled={isDisabled}
    >
      <div className='tw-whitespace-nowrap'>Move Money</div>
      <Image
        src={CHEVRON_RIGHT_BLACK}
        alt='Move Money'
        draggable='false'
        width={20}
        height={20}
        className='tw-ml-[16px]'
      />
    </Button>
  );
};

export default TransferButton;
