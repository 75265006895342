import { FC, useEffect } from 'react';
import { useRequestCurrencyMutation } from 'api/accounts';
import { REQUEST_CURRENCY, REQUEST_SENT } from 'constants/account';
import { CHECK } from 'constants/icons';
import { SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { Text } from 'destiny/dist/components/atoms/text';
import { Button } from 'destiny/dist/components/molecules/button';
import { SupporterInfo } from 'destiny/dist/components/molecules/supporterInfo';
import { defaultFn } from 'destiny/dist/constants/index';
import { BUTTON_SIZE_TYPES, BUTTON_STATE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { SUPPORT_INFO_TYPES } from 'destiny/dist/constants/molecules/supporterInfo';
import { useSessionToken } from 'hooks/useSessionToken';
import Image from 'next/image';
import { trackMixpanel } from 'utils/mixpanel';

interface VaultNoCurrencyProps {
  allowedCurrency: string[];
  allCurrency: string[];
  searchText: string;
  setButtonText: (text: string) => void;
  buttonText: string;
  entityName: string;
}

const VaultNoCurrency: FC<VaultNoCurrencyProps> = ({
  allowedCurrency = [],
  allCurrency = [],
  searchText = '',
  setButtonText,
  buttonText = REQUEST_CURRENCY,
  entityName = '',
}) => {
  const { sessionToken: requestCurrencyToken, createSessionToken: createRequestCurrencySessionToken } = useSessionToken(
    SESSION_CAPABILITY_CONTEXT_KEYS.REQUEST_CURRENCY
  );

  const [
    requestCurrency,
    { isSuccess: isRequestCurrencySuccess, isLoading: isRequestLoading, isError: isRequestCurrencyErr },
  ] = useRequestCurrencyMutation();

  const handleRequestCurrency = () =>
    requestCurrency({
      data: { currency_code: searchText, entity_name: entityName },
      idempotencyHeader: requestCurrencyToken || '',
    });

  useEffect(() => {
    isRequestCurrencySuccess && setButtonText(REQUEST_SENT);
  }, [isRequestCurrencySuccess]);

  useEffect(() => {
    isRequestCurrencyErr && createRequestCurrencySessionToken();
  }, [isRequestCurrencyErr]);

  const currenciesUsed = allCurrency.filter((x) => !allowedCurrency.includes(x));

  return (
    <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-52 tw-mx-auto'>
      {entityName ? (
        currenciesUsed.includes(searchText.toUpperCase()) ? (
          <Text textClass='f-12-300 tw-text-TEXT_SECONDARY tw-mb-2.5'>{`“${searchText}” already exists`}</Text>
        ) : (
          <>
            <Text textClass='f-12-300 tw-text-TEXT_SECONDARY tw-mb-2.5'>{`Seems like we don’t have “${searchText}” in our system as of now.`}</Text>
            <Button
              buttonProps={{
                btnType: BUTTON_TYPES.SECONDARY,
                disabled: false,
                size: BUTTON_SIZE_TYPES.MEDIUM,
                state: BUTTON_STATE_TYPES.DEFAULT,
                onClick: buttonText === REQUEST_SENT ? defaultFn : handleRequestCurrency,
                isLoading: isRequestLoading,
                wrapperClass: 'tw-w-44',
                id: 'ACCOUNTS_VAULT_REQUEST_CURRENCY_BUTTON',
                eventCallback: trackMixpanel,
              }}
              showLeadingIcon={buttonText === REQUEST_SENT}
              customLeadingIcon={<Image src={CHECK} alt='check' width={20} height={20} className='tw-min-w-5' />}
            >
              <Text textClass='tw-whitespace-nowrap'>{buttonText}</Text>
            </Button>
            {isRequestCurrencyErr && (
              <SupporterInfo
                type={SUPPORT_INFO_TYPES.ERROR}
                text={'Cannot Request Currency.Please try again.'}
                wrapperClass='tw-flex tw-items-center tw-mt-2.5'
              />
            )}
          </>
        )
      ) : (
        <Text textClass='f-12-300 tw-text-TEXT_SECONDARY tw-mb-2.5'>Please select an entity first</Text>
      )}
    </div>
  );
};

export default VaultNoCurrency;
