import React, { useRef, useState } from 'react';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { useOnClickOutside } from 'hooks';
import { MenuItem } from 'types';
import OptionMenuItem from 'components/dropdown/OptionMenuItem';

interface DropdownProps {
  id: string;
  options: Array<MenuItem>;
  onMenuItemClick: (menu: MenuItem) => void;
  className?: string;
  isDisabled?: boolean;
  optionClassName?: string;
  eventParentId?: string;
}

const OptionDropdown: React.FC<DropdownProps> = ({
  className = '',
  id,
  options,
  onMenuItemClick,
  isDisabled = false,
  optionClassName = '',
  eventParentId = '',
}) => {
  const [isOpen, toggleOpen] = useState(false);

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    toggleOpen(false);
  });

  return (
    <div className={`f-14-300 tw-relative ${className} tw-text-TEXT_GRAY_2`} ref={ref}>
      <div
        data-testid={id}
        role='presentation'
        onClick={(e) => {
          e.stopPropagation();
          toggleOpen(!isOpen);
        }}
      >
        <SvgSpriteLoader
          id='dots-vertical'
          iconCategory={ICON_SPRITE_TYPES.GENERAL}
          width={24}
          height={24}
          className='tw-cursor-pointer'
        />
      </div>
      {isOpen && !!options.length && (
        <div
          className={`dropdown-select tw-z-10 tw-absolute tw-bg-white tw-top-6 tw-text-GRAY_1 tw-right-0
          tw-border tw-border-DIVIDER_GRAY tw-rounded tw-shadow-md tw-cursor-pointer tw-max-h-[250px] tw-overflow-y-auto`}
        >
          {options.map((item, index) => (
            <OptionMenuItem
              key={item?.id}
              menu={item}
              index={index}
              onMenuItemClick={onMenuItemClick}
              isDisabled={isDisabled}
              optionClassName={optionClassName}
              eventParentId={eventParentId}
              toggleOpen={toggleOpen}
              id={id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OptionDropdown;
