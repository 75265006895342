import { FC } from 'react';
import { OptionsType } from 'destiny/dist/types/dropdown';

const ListTooltipBody: FC<{ list: OptionsType[] }> = ({ list }) => (
  <div className='tw-flex tw-flex-col tw-gap-4 tw-max-h-60 tw-overflow-y-scroll tw-no-scrollbar'>
    {list?.map((item, index) => (
      <div key={`${item?.value}-${index}`}>{item?.value}</div>
    ))}
  </div>
);

export default ListTooltipBody;
