import { DragEventHandler, useState } from 'react';
import { Button } from 'destiny/dist/components/molecules/button';
import { Label } from 'destiny/dist/components/molecules/label';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { BUTTON_SIZE_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { BULK_PAYMENT_UPLOADER_CONFIG } from 'modules/bulk-payment/BulkPayment.constants';
import { defaultFnType } from 'types';
import { trackMixpanel } from 'utils/mixpanel';
import { Loader } from 'components/loader/Loader';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

type BulkFileUploaderProps = {
  isLoading: boolean;
  children: React.ReactNode;
  handleClick: defaultFnType;
  errorMsg: string;
  isSuccess: boolean;
  onFileDrop: (file: File | null) => void;
};

const loadingClassName = 'tw-flex tw-flex-col tw-justify-center tw-items-center';
const loadingDescriptionClassName = 'f-14-400 tw-text-TEXT_TERTIARY tw-mt-4';

const BulkFileUploader: React.FC<BulkFileUploaderProps> = ({
  isLoading,
  children,
  handleClick,
  errorMsg,
  isSuccess,
  onFileDrop,
}) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const handleFileDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    const files = event?.dataTransfer?.files?.[0];

    setIsDragOver(false);
    onFileDrop(files);
  };

  return (
    <div
      onDrop={handleFileDrop}
      onDragOver={(event) => event.preventDefault()}
      onDragEnter={() => setIsDragOver(true)}
      onDragExit={() => setIsDragOver(false)}
      onDragLeave={() => setIsDragOver(false)}
    >
      <CommonWrapper
        className={`tw-rounded-2.5 tw-w-[460px] tw-h-64 tw-flex tw-flex-col tw-items-center tw-justify-center ${
          errorMsg ? 'tw-border-RED_PRIMARY tw-border' : ''
        }
        ${isDragOver ? 'tw-bg-GRAY_1' : 'tw-bg-BASE_PRIMARY'}`}
        isLoading={isLoading}
        isSuccess={isSuccess}
        skeletonType={SkeletonTypes.CUSTOM}
        skeleton={
          <div className={loadingClassName}>
            <Loader width='48px' height='48px' />
            <div className={loadingDescriptionClassName}>{BULK_PAYMENT_UPLOADER_CONFIG.LOADING.description}</div>
          </div>
        }
        successCard={
          <div className={`${loadingClassName} tw-rounded-2.5 tw-w-[460px] tw-h-64 tw-bg-BASE_PRIMARY`}>
            <SvgSpriteLoader
              id='check-circle'
              iconCategory={ICON_SPRITE_TYPES.GENERAL}
              width={48}
              height={48}
              color={COLORS.GREEN_PRIMARY}
            />
            <div className={loadingDescriptionClassName}>{BULK_PAYMENT_UPLOADER_CONFIG.SUCCESS.description}</div>
          </div>
        }
      >
        {!!errorMsg && (
          <SvgSpriteLoader
            id='alert-circle'
            iconCategory={ICON_SPRITE_TYPES.ALERTS_AND_FEEDBACK}
            width={40}
            height={40}
            color={COLORS.RED_PRIMARY}
            className='tw-mb-4'
          />
        )}
        <Label
          title={
            <div onDragOver={() => setIsDragOver(true)}>
              {errorMsg ? '' : BULK_PAYMENT_UPLOADER_CONFIG.DEFAULT.title}
            </div>
          }
          description={
            <div onDragOver={() => setIsDragOver(true)}>
              {errorMsg
                ? BULK_PAYMENT_UPLOADER_CONFIG.ERROR.description
                : BULK_PAYMENT_UPLOADER_CONFIG.DEFAULT.description}
            </div>
          }
          wrapperClass={`tw-mb-6 tw-w-[${errorMsg ? 380 : 222}px] tw-text-center`}
          titleClass='f-16-400 tw-text-TEXT_PRIMARY tw-mb-2'
          descriptionClass={`${errorMsg ? 'f-14-300' : 'f-12-300'} tw-text-TEXT_SECONDARY`}
        />
        <Button
          buttonProps={{
            id: 'BULK_FILE_UPLOADER_BUTTON',
            eventCallback: trackMixpanel,
            onClick: handleClick,
            size: BUTTON_SIZE_TYPES.MEDIUM,
          }}
          showLeadingIcon
          customLeadingIcon={<SvgSpriteLoader id='upload-02' iconCategory={ICON_SPRITE_TYPES.GENERAL} />}
        >
          <div onDragOver={() => setIsDragOver(true)}>
            {errorMsg
              ? BULK_PAYMENT_UPLOADER_CONFIG.ERROR.buttonTitle
              : BULK_PAYMENT_UPLOADER_CONFIG.DEFAULT.buttonTitle}
          </div>
        </Button>
        {children}
      </CommonWrapper>
    </div>
  );
};

export default BulkFileUploader;
