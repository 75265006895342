import { useState } from 'react';
import { useGetSignedUrlMutation } from 'api/kyb';
import { FileExtensionToInputFormatMapping, FileExtensionToTypeMap, FileMimeType } from 'constants/fileMimeTypes';
import { FILE_SIZE, REQUEST_TYPES } from 'constants/index';
import { useAppSelector } from 'hooks/toolkit';
import { RootState } from 'store';
import { UploadFileResponseType } from 'types';
import { INPUT_FILE_FORMATS } from 'types/mime';

const UPLOAD_FAILED_ERROR = 'Uploading failed!';

interface FileUploaderWrapperProps {
  maxSize?: number;
  acceptedFormats?: INPUT_FILE_FORMATS[];
}

const useUploadFile: (params: FileUploaderWrapperProps) => {
  uploadFile: (
    fileToUpload: File | null,
    bucket?: string,
    path?: string,
    progressCallback?: (fileName: string, progress: number) => void
  ) => Promise<UploadFileResponseType>;
  error: string | null;
  isLoading: boolean;
  uploadProgress: number;
} = ({ maxSize = FILE_SIZE.THREE_MB, acceptedFormats = [INPUT_FILE_FORMATS.CSV] }) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const {
    user: { id },
  } = useAppSelector((state: RootState) => state?.user);

  const [getSignedUrl] = useGetSignedUrlMutation({});

  const uploadFile = (
    fileToUpload: File | null,
    bucket?: string,
    path?: string,
    progressCallback?: (fileName: string, progress: number) => void
  ) => {
    const promise = new Promise<UploadFileResponseType>((resolve, reject) => {
      if (isLoading) return;

      if (fileToUpload)
        if (fileToUpload?.size > maxSize) {
          const err = `File size cannot exceed more than ${maxSize / FILE_SIZE.ONE_MB}MB`;

          reject(err);
          setError(err);
        } else {
          const fileExtension: string = fileToUpload?.name?.split('.')?.pop()?.toLowerCase() ?? '';

          //please add file type in FileMimeType on add new file format
          const fileName = id + '_' + Date.now() + '.' + (FileMimeType[fileToUpload?.type] ?? fileExtension);

          const payload = {
            key: fileName,
            bucket,
            path,
          };

          const isAllowedFormat = acceptedFormats.includes(FileExtensionToInputFormatMapping[fileExtension]);

          if (isAllowedFormat) {
            setError(null);
            setIsLoading(true);
            setUploadProgress(0);
            progressCallback?.(fileToUpload?.name, 0);

            getSignedUrl(payload)
              .unwrap()
              .then((response: UploadFileResponseType) => {
                if (response) {
                  const xhr = new XMLHttpRequest();

                  xhr.open(REQUEST_TYPES.PUT, response.url, true);
                  xhr.setRequestHeader('Content-Type', fileToUpload?.type || FileExtensionToTypeMap[fileExtension]);

                  xhr.upload.onprogress = function (event) {
                    if (event.lengthComputable) {
                      const percentComplete = (event.loaded / event.total) * 100;

                      setUploadProgress(Math.floor(percentComplete));
                      progressCallback?.(fileToUpload?.name, Math.floor(percentComplete));
                    }
                  };

                  xhr.onload = function () {
                    setIsLoading(false);
                    if (xhr.status === 200) {
                      setError(null);
                      resolve({ ...response, fileName: fileToUpload?.name });
                    } else {
                      setError(UPLOAD_FAILED_ERROR);
                      reject(UPLOAD_FAILED_ERROR);
                    }
                  };

                  xhr.onerror = function () {
                    setError(UPLOAD_FAILED_ERROR);
                    reject(UPLOAD_FAILED_ERROR);
                  };

                  xhr.send(fileToUpload);
                }
              })
              .catch(() => {
                setError(UPLOAD_FAILED_ERROR);
                reject(UPLOAD_FAILED_ERROR);
              });
          } else {
            const err = `Invalid file type`;

            reject(err);
            setError(err);
          }
        }
    });

    return promise;
  };

  return {
    error,
    isLoading,
    uploadProgress,
    uploadFile,
  };
};

export default useUploadFile;
