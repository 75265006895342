import { createContext, Dispatch, FC, ReactElement, useContext, useReducer } from 'react';
import { captureException } from '@sentry/browser';
import { MapAny } from 'destiny/dist/types';
import { CounterParty } from 'types/beneficiary-v2';

enum thirdPartyPayoutActions {
  SET_SEARCH = 'SET_SEARCH',
  SET_COUNTER_PARTIES = 'SET_COUNTER_PARTIES',
  SET_IS_FETCHING_COUNTER_PARTIES_WITH_SEARCH = 'SET_IS_FETCHING_COUNTER_PARTIES_WITH_SEARCH',
}

interface InitialStateType {
  search: string;
  counterParties: CounterParty[];
  isFetchingCounterPartiesWithSearch: boolean;
}

interface ActionType {
  type: keyof typeof thirdPartyPayoutActions;
  payload?: MapAny;
}

const initialState: InitialStateType = {
  search: '',
  counterParties: [],
  isFetchingCounterPartiesWithSearch: false,
};

const context = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null,
});

const { Provider } = context;

/* eslint-disable react/display-name */
export const ThirdPartyPayoutStateProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer((state = {}, action: ActionType) => {
    switch (action.type) {
      case thirdPartyPayoutActions.SET_SEARCH:
        return { ...state, search: action?.payload?.search };
      case thirdPartyPayoutActions.SET_COUNTER_PARTIES: {
        const page = action?.payload?.page ?? 1;
        const oldCounterPartiesList = state?.counterParties ?? [];
        const newCounterPartiesList = action?.payload?.newCounterParties ?? [];
        const counterParties =
          page === 1 ? [...newCounterPartiesList] : [...oldCounterPartiesList, ...newCounterPartiesList];

        return { ...state, counterParties };
      }
      case thirdPartyPayoutActions.SET_IS_FETCHING_COUNTER_PARTIES_WITH_SEARCH:
        return { ...state, isFetchingCounterPartiesWithSearch: action?.payload?.isFetchingCounterPartiesWithSearch };
      default:
        captureException(new Error('Beneficiary unhandled action called'));

        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const withThirdPartyPayoutContext = (WrappedComponent: FC) => {
  return (props: MapAny) => (
    <ThirdPartyPayoutStateProvider>
      <WrappedComponent {...props} />
    </ThirdPartyPayoutStateProvider>
  );
};

const useThirdPartyPayoutContextStore = () => useContext(context);

export { thirdPartyPayoutActions, useThirdPartyPayoutContextStore, withThirdPartyPayoutContext };
