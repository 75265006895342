export const FIAT_QUOTE_EXPIRY_TIME = 90000;

export const PURPOSE_LIST = [
  {
    label: 'Transfer to own account',
    value: 'IR001',
  },
  {
    label: 'Family Maintenance',
    value: 'IR002',
  },
  {
    label: 'Education-related student expenses',
    value: 'IR003',
  },
  {
    label: 'Medical Treatment',
    value: 'IR004',
  },
  {
    label: 'Hotel Accommodation',
    value: 'IR005',
  },
  {
    label: 'Travel',
    value: 'IR006',
  },
  {
    label: 'Utility Bills',
    value: 'IR006',
  },
  {
    label: 'Repayment of Loans',
    value: 'IR008',
  },
  {
    label: 'Tax Payment',
    value: 'IR009',
  },
  {
    label: 'Purchase of Residential Property',
    value: 'IR010',
  },
  {
    label: 'Payment of Property Rental',
    value: 'IR011',
  },
  {
    label: 'Insurance Premium',
    value: 'IR012',
  },
  {
    label: 'Product indemnity insurance',
    value: 'IR013',
  },
  {
    label: 'Insurance Claims Payment',
    value: 'IR014',
  },
  {
    label: 'Mutual Fund Investment',
    value: 'IR015',
  },
  {
    label: 'Investment in Shares',
    value: 'IR016',
  },
  {
    label: 'Donations',
    value: 'IR017',
  },
  {
    label: 'Information Service Charges',
    value: 'IR01801',
  },
  {
    label: 'Advertising & Public relations-related expenses',
    value: 'IR01802',
  },
  {
    label: 'Royalty fees, trademark fees, patent fees, and copyright fees',
    value: 'IR01803',
  },
  {
    label: 'Fees for brokers, front end fee, commitment fee, guarantee fee and custodian fee',
    value: 'IR01804',
  },
  {
    label: 'Fees for advisors, technical assistance, and academic knowledge, including remuneration for specialists',
    value: 'IR01805',
  },
  {
    label: 'Representative office expenses',
    value: 'IR01806',
  },
  {
    label: 'Construction costs/expenses',
    value: 'IR01807',
  },
  {
    label: 'Transportation fees for goods',
    value: 'IR01808',
  },
  {
    label: 'For payment of exported goods',
    value: 'IR01809',
  },
  {
    label: 'Delivery fees for goods',
    value: 'IR01810',
  },
  {
    label: 'General Goods Trades - Offline trade',
    value: 'IR01811',
  },
];

export const SOURCE_LIST = [
  {
    label: 'Salary',
    value: 'Salary',
  },
  {
    label: 'Personal Savings',
    value: 'Personal Savings',
  },
  {
    label: 'Personal Wealth',
    value: 'Personal Wealth',
  },
  {
    label: 'Retirement Funds',
    value: 'Retirement Funds',
  },
  {
    label: 'Business Owner/Shareholder',
    value: 'Business Owner/Shareholder',
  },
  {
    label: 'Loan Facility',
    value: 'Loan Facility',
  },
  {
    label: 'Personal Account',
    value: 'Personal Account',
  },
];

// TODO: RMV TEMP CONSTANTS
export const FIAT_TXN = 'Fiat';
export const NETWORK = 'NETWORK';

export enum TransferStages {
  CRYPTO_TRANSFER_DETAILS = 'crypto-transfer-details',
  FIAT_TRANSFER_DETAILS = 'fiat-transfer-details',
  FIAT_REVIEW_AND_PAY = 'fiat-review-and-pay',
  BULK_REVIEW = 'bulk-review',
  BULK_PAY = 'bulk-pay',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const DONT_SYNC = 'DONT_SYNC';
