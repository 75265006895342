import SkeletonElement from 'components/skeletons/SkeletonElement';
import { SKELETON_ELEMENT_SHAPES } from 'components/skeletons/types';

const SKELETON_ARRAY = Array(4).fill('');

const SelectSourceAccountSkeleton = ({ showDropdown = true, className = '' }) => {
  return (
    <>
      <div className='tw-mb-3'>
        <SkeletonElement className={`!tw-animate-none tw-mb-3 tw-w-[70px] tw-h-[15px] ${className}`} />
        <SkeletonElement className={`!tw-animate-none tw-w-full tw-h-[67px] tw-rounded-2.5 ${className}`} />
      </div>

      {showDropdown && (
        <div className='tw-border tw-border-DIVIDER_GRAY tw-rounded-2.5 tw-shadow-menu tw-overflow-y-auto tw-w-full tw-max-h-[259px] tw-bg-white'>
          {SKELETON_ARRAY.map((_, index) => {
            return (
              <div className='tw-px-6 tw-py-4  tw-flex tw-items-center' key={index}>
                <SkeletonElement shape={SKELETON_ELEMENT_SHAPES.CIRCLE} className='tw-mr-4 tw-w-8 tw-h-8' />
                <div className='tw-flex tw-flex-col tw-justify-center'>
                  <SkeletonElement className='!tw-animate-none tw-mb-2.5 tw-w-[100px] tw-h-3.5' />
                  <SkeletonElement className='!tw-animate-none tw-w-[90px] tw-h-3' />
                </div>
                <div className='tw-flex tw-flex-col tw-justify-center tw-ml-auto tw-min-w-[106px] tw-max-w-[106px]'>
                  <SkeletonElement className='!tw-animate-none tw-mb-2.5 tw-w-[106px] tw-h-3' />
                  <SkeletonElement className='!tw-animate-none tw-w-[90px] tw-h-3' />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SelectSourceAccountSkeleton;
