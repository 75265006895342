import React, { memo, useCallback, useEffect, useState } from 'react';
import { useGetConsentByTypeQuery } from 'api/consent';
import { ACCESS_FLAGS } from 'constants/config';
import { NAVBAR_SHADOW } from 'constants/icons';
import { CONSENT_TYPES, SCREEN_BREAKPOINTS } from 'constants/index';
import { StatusType } from 'constants/kyb';
import { NAVIGATION_KEY_SHORTCUTS_MAP } from 'constants/shortcuts';
import { useAppDispatch, useAppSelector } from 'hooks/toolkit';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { addShortcutListeners, deleteShortcutListeners } from 'store/slices/shortcuts';
import { NavigationItemSchema } from 'types/config';
import SidebarProfileSection from 'components/layout/SidebarProfileSection';
import SidebarTab from 'components/layout/SidebarTab';

type SidebarProps = {
  items: NavigationItemSchema[];
  className?: string;
};

const Sidebar: React.FC<SidebarProps> = ({ items, className = '' }) => {
  const { userAccessFlags, user } = useAppSelector((state: RootState) => state.user);
  const [expanded, setExpanded] = useState(false);
  const router = useRouter();
  const { data } = useGetConsentByTypeQuery(CONSENT_TYPES.PAYMENTS);
  const windowDimensions = useWindowDimensions();
  const dispatch = useAppDispatch();
  const [dropdownState, setDropdownState] = useState(false);

  useEffect(() => {
    setExpanded(windowDimensions.width >= SCREEN_BREAKPOINTS.XL_WIDTH);
  }, [windowDimensions]);

  useEffect(() => {
    const keys = items.map((eachItem) => eachItem.key);

    const applicableShortcuts: string[] = [];

    keys.forEach(
      (eachKey) =>
        NAVIGATION_KEY_SHORTCUTS_MAP?.[eachKey] && applicableShortcuts.push(NAVIGATION_KEY_SHORTCUTS_MAP?.[eachKey])
    );

    dispatch(addShortcutListeners(applicableShortcuts));

    return () => {
      dispatch(deleteShortcutListeners(applicableShortcuts));
    };
  }, [items]);

  const accessFlag = userAccessFlags[ACCESS_FLAGS.IS_TREASURY_ENABLED]
    ? ACCESS_FLAGS.IS_TREASURY_ENABLED
    : ACCESS_FLAGS.IS_PAYOUTS_ENABLED;

  const toggleSidebar = () => {
    setExpanded((current) => !current);
  };

  const toggleDropdown = useCallback(() => {
    setDropdownState((prev) => !prev);
  }, []);

  const handleMouseLeave = () => {
    setExpanded(false);
    setDropdownState(false);
  };

  return (
    <>
      <div className='tw-w-sidebarmini tw-min-w-sidebarmini tw-h-full xl:tw-hidden'>
        <Image
          alt={'sidebar-shadow'}
          className={`tw-pointer-events-none tw-transition-all tw-duration-300 tw-h-full tw-overflow-x-visible tw-object-cover tw-absolute ${
            expanded ? 'tw-left-[200px]' : 'tw-left-3'
          }`}
          src={NAVBAR_SHADOW}
          width={130}
          height={windowDimensions.height}
        />
      </div>

      <div
        role='navigation'
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={handleMouseLeave}
        className={`tw-group tw-transition-all tw-duration-300 tw-bg-white tw-z-40 tw-absolute xl:tw-static tw-t-0 tw-h-full tw-l-[180px] hover:tw-bg-BASE_SECONDARY xl:hover:tw-bg-white ${
          expanded
            ? 'tw-w-sidebar tw-min-w-sidebar'
            : 'tw-w-sidebarmini tw-min-w-sidebarmini xl:tw-w-sidebar xl:tw-min-w-sidebar'
        } ${className}`}
        data-testid='sidebar'
      >
        <div className={`tw-max-h-[calc(100vh-180px)] tw-overflow-hidden tw-px-4 tw-pt-2`}>
          {items?.map((route: NavigationItemSchema) => (
            <Link href={route.route} key={route.key} onClick={expanded ? toggleSidebar : undefined}>
              <SidebarTab
                name={route.display_name}
                iconId={route.iconId}
                iconCategory={route.iconCategory}
                isSelected={router.pathname === route.route}
                accessFlag={accessFlag}
                isNew={route.is_new && data?.status !== StatusType.SUCCESS}
                expanded={expanded}
              />
            </Link>
          ))}
        </div>

        <SidebarProfileSection
          userName={user?.fullName}
          expanded={expanded}
          dropdownState={dropdownState}
          toggleDropdown={toggleDropdown}
        />
      </div>
    </>
  );
};

export default memo(Sidebar);
