import { type FC } from 'react';
import { useGetSelectFiatCryptoFormQuery } from 'api/beneficiary-v2';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { useBeneficiaryContextStore } from 'modules/beneficiary-v2/beneficiary.context';
import BeneficiaryFormWrapper from 'modules/beneficiary-v2/BeneficiaryFormWrapper';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const BeneficiarySelectFiatCryptoForm: FC<{ isAddToExistingCounterParty?: boolean }> = ({
  isAddToExistingCounterParty = false,
}) => {
  const {
    state: { eligibilityError = '' },
  } = useBeneficiaryContextStore();
  const { data, isError, isFetching, refetch } = useGetSelectFiatCryptoFormQuery({
    is_add_to_existing_counter_party: isAddToExistingCounterParty,
  });

  return (
    <CommonWrapper isError={isError} isLoading={isFetching} refetchFunnction={refetch}>
      <BeneficiaryFormWrapper
        data={data?.form?.stepper_form_sections}
        hideSectionPart
        noDefaultValueFields={['beneficiary_bank_country_code']}
      />
      {!!eligibilityError && (
        <div className='f-16-300 tw-text-ERROR_RED_2 tw-mt-8 tw-flex tw-gap-2 tw-items-center'>
          <SvgSpriteLoader id='alert-triangle' iconCategory={ICON_SPRITE_TYPES.ALERTS_AND_FEEDBACK} />
          <div>{eligibilityError}</div>
        </div>
      )}
    </CommonWrapper>
  );
};

export default BeneficiarySelectFiatCryptoForm;
