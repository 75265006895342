export enum MOVE_MONEY_ERROR_CODES {
  INSUFFICIENT_GAS_BALANCE = 'INSUFFICIENT_GAS_BALANCE',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  RISK_ANALYSIS_FAILED = 'RISK_ANALYSIS_FAILED',
  RISK_ANALYSIS_UNSUCCESSFUL = 'RISK_ANALYSIS_UNSUCCESSFUL',
}

export const MOVE_MONEY_ERROR_MESSAGE_CODES: MOVE_MONEY_ERROR_CODES[] = [
  MOVE_MONEY_ERROR_CODES.INSUFFICIENT_GAS_BALANCE,
  MOVE_MONEY_ERROR_CODES.RISK_ANALYSIS_FAILED,
  MOVE_MONEY_ERROR_CODES.RISK_ANALYSIS_UNSUCCESSFUL,
];

export const API_FAIL_ERROR_MESSAGE = 'Something went wrong, Please try again';

export const BANK_TYPES_FOR_RFI = {
  VASP: 'VASP',
  OTHERS: 'OTHERS',
};

export const EXPECTED_TRANSACTION_TIME: Record<string, string> = {
  NETWORK: '0-3 business hours',
  IFSC: '1 business days',
  'ACH CODE': '1 business days',
  WIRE: '6 business hours',
  SWIFT: '6 business days',
  SEPA: '2-3 business days',
};

export const ROUTING_CODE_DISPLAY_VALUE: Record<string, string> = {
  NETWORK: 'Crypto',
  IFSC: 'IMPS',
  'ACH CODE': 'ACH',
  WIRE: 'Fedwire',
  SWIFT: 'SWIFT',
  SEPA: 'SEPA',
  AED_LOCAL: 'Local',
  'AED LOCAL': 'Local',
};

export const NOTES_MANDATORY_CURRENCIES = ['INR'];
export const SLIPPAGE_SOURCE_CURRENCIES = ['AED'];
export const SLIPPAGE_SOURCE_AMOUNT_THRESHOLD = 3000000;
