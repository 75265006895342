import { type FC } from 'react';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip';
import { TooltipPositions } from 'destiny/dist/constants';
import { COLORS } from 'destiny/dist/constants/colors';
import { OptionsType } from 'destiny/dist/types/dropdown';
import { SIZE_TYPES } from 'types';
import ListTooltipBody from 'components/ListTooltipBody';

type StackedAvatarSizeStyleType = {
  suffixClassName: string;
  avatarStyleClassName: string;
  listWrapperClassName: string;
  stackedClassNames: Record<number, string>;
  className: string;
};

type StackedAvatarWithLabelProps = {
  list: OptionsType[];
  label: string;
  className?: string;
  iconBackgroundColor?: string;
  itemClassName?: string;
  size?: SIZE_TYPES;
  suffixClassName?: string;
  tooltipPosition?: TooltipPositions;
};

const STACKED_AVATARS_SIZE_STYLES: Record<SIZE_TYPES, StackedAvatarSizeStyleType> = {
  [SIZE_TYPES.SMALL]: {
    suffixClassName: 'f-13-300',
    avatarStyleClassName: 'f-10-400 tw-h-6 tw-w-6',
    listWrapperClassName: 'tw-w-10 tw-h-6',
    stackedClassNames: {
      0: '',
      1: 'tw-z-1 tw-left-2',
      2: 'tw-z-[2] tw-left-4',
    },
    className: 'tw-gap-1.5',
  },
  [SIZE_TYPES.XSMALL]: {
    suffixClassName: 'f-13-300',
    avatarStyleClassName: 'f-10-400 tw-h-3 tw-min-w-[12px]',
    listWrapperClassName: 'tw-w-10 tw-h-6',
    stackedClassNames: {
      0: '',
      1: 'tw-z-1 tw-left-2',
      2: 'tw-z-[2] tw-left-4',
    },
    className: 'tw-gap-1.5',
  },
  [SIZE_TYPES.MEDIUM]: {
    suffixClassName: 'f-16-300',
    avatarStyleClassName: 'f-12-300 tw-h-8 tw-min-w-[32px]',
    listWrapperClassName: 'tw-w-16 tw-h-8',
    stackedClassNames: {
      0: '',
      1: 'tw-z-1 tw-left-3',
      2: 'tw-z-[2] tw-left-7',
    },
    className: 'tw-gap-3',
  },
  // medium has been copied to large, xlarge since we don't have the actual styles for them
  [SIZE_TYPES.LARGE]: {
    suffixClassName: 'f-16-300',
    avatarStyleClassName: 'f-12-300 tw-h-8 tw-min-w-[32px]',
    listWrapperClassName: 'tw-w-16 tw-h-8',
    stackedClassNames: {
      0: '',
      1: 'tw-z-1 tw-left-3',
      2: 'tw-z-[2] tw-left-7',
    },
    className: 'tw-gap-3',
  },
  [SIZE_TYPES.XLARGE]: {
    suffixClassName: 'f-16-300',
    avatarStyleClassName: 'f-12-300 tw-h-8 tw-min-w-[32px]',
    listWrapperClassName: 'tw-w-16 tw-h-8',
    stackedClassNames: {
      0: '',
      1: 'tw-z-1 tw-left-3',
      2: 'tw-z-[2] tw-left-7',
    },
    className: 'tw-gap-3',
  },
};

const StackedAvatarWithLabel: FC<StackedAvatarWithLabelProps> = ({
  list,
  label,
  className = '',
  itemClassName = '',
  size = SIZE_TYPES.MEDIUM,
  suffixClassName = '',
  tooltipPosition = TooltipPositions.RIGHT,
}) => {
  return (
    <div className={className}>
      {!!list?.length && (
        <Tooltip
          color={COLORS.TEXT_PRIMARY}
          tooltipBodystyle='f-12-300 !tw-py-3 !tw-px-4 tw-whitespace-pre'
          tooltipBody={<ListTooltipBody list={list} />}
          position={tooltipPosition}
          id='RECIPIENTS_LIST_UNIQUE_CURRENCIES'
          className='tw-w-fit'
        >
          <div
            className={`tw-flex tw-items-center tw-w-fit ${STACKED_AVATARS_SIZE_STYLES[size].className} ${className}`}
          >
            <div className={`tw-flex tw-relative ${STACKED_AVATARS_SIZE_STYLES[size].listWrapperClassName}`}>
              {list.slice(0, 3).map((item, index: number) => (
                <div
                  key={`stackedAvatarWithLabel-${index}`}
                  className={`tw-bg-white tw-absolute tw-rounded-full ${STACKED_AVATARS_SIZE_STYLES[size].stackedClassNames[index]} ${itemClassName}`}
                >
                  {item?.icon}
                </div>
              ))}
            </div>

            <div
              className={`tw-text-TEXT_SECONDARY f-14-300 tw-whitespace-nowrap ${
                suffixClassName || STACKED_AVATARS_SIZE_STYLES[size].suffixClassName
              }`}
            >
              {label}
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default StackedAvatarWithLabel;
