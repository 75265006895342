import { type FC } from 'react';
import { getRoutingCodeDisplayName } from 'modules/beneficiary-v2/beneficiary.utils';
import { Beneficiary } from 'types/beneficiary-v2';

const BeneficiaryBankAccountPaymentTypeColumn: FC<{ data: Beneficiary }> = ({ data }) => (
  <span className='tw-bg-BASE_PRIMARY tw-px-2.5 tw-py-[1px]'>
    {getRoutingCodeDisplayName(data?.routing_code_type_1, data?.destination_currency)}
  </span>
);

export default BeneficiaryBankAccountPaymentTypeColumn;
