import { type FC } from 'react';
import SkeletonElement from 'components/skeletons/SkeletonElement';
import { SKELETON_ELEMENT_SHAPES } from 'components/skeletons/types';

const SelectRecipientAccountSkeleton: FC<{ count?: number }> = ({ count = 6 }) => {
  const SKELETON_ARRAY = Array(count).fill('');

  return (
    <>
      {SKELETON_ARRAY.map((_, index) => {
        return (
          <div
            className='tw-px-6 tw-py-3 tw-bg-white tw-rounded-2.5 tw-flex tw-items-center tw-justify-between'
            key={index}
          >
            <div className='tw-flex tw-items-center tw-w-3/5'>
              <SkeletonElement shape={SKELETON_ELEMENT_SHAPES.CIRCLE} className='tw-mr-3 tw-w-6 tw-h-6' />

              <SkeletonElement className='tw-mr-3 tw-w-[91px] tw-h-4' />
            </div>
            <div className='tw-flex tw-items-center tw-w-2/5'>
              <SkeletonElement shape={SKELETON_ELEMENT_SHAPES.CIRCLE} className='tw-w-6 tw-h-6' />
              <div className='tw-flex tw-flex-col tw-ml-3 tw-w-[calc(100%-36px)]'>
                <SkeletonElement className='tw-mb-2 tw-w-16 tw-h-3.5' />
                <SkeletonElement className='tw-w-14 tw-h-[13px]' />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SelectRecipientAccountSkeleton;
