import { ANNOUNCEMENT, DROPDOWN_ICON, ERROR_FILE, NETWORK_ICON } from 'constants/icons';
import Image from 'next/image';
import { CardIconType, CardType, InfoErrorCardProps } from 'components/info-error-card/types';

const cardStyles = {
  [CardType.ERROR]: 'tw-bg-RED_TERTIARY tw-border-RED_SECONDARY',
  [CardType.INFO]: 'tw-border-TEXT_PRIMARY',
  [CardType.AVATAR_WITH_TITLE_SUBTITLE]: 'tw-bg-GRAY_2 tw-border-0',
  [CardType.INLINE_TITLE_SUBTITLE]: 'tw-bg-ACCENT_BLUE_HOVER tw-border-0 tw-py-8',
  [CardType.CUSTOM]: '',
};

const commonCardStyles =
  'tw-border tw-border-solid tw-rounded-[10px] tw-p-6 tw-flex tw-items-center tw-text-TEXT_PRIMARY';

const icon = {
  [CardType.ERROR]: ERROR_FILE,
  [CardType.INFO]: ANNOUNCEMENT,
  [CardType.AVATAR_WITH_TITLE_SUBTITLE]: NETWORK_ICON,
  [CardType.INLINE_TITLE_SUBTITLE]: '',
  [CardType.CUSTOM]: '',
};

const iconDimensions = {
  [CardIconType.LARGE]: { width: 39, height: 39 },
  [CardIconType.MEDIUM]: { width: 32, height: 32 },
  [CardIconType.SMALL]: { width: 24, height: 24 },
};

const getSubtitleStyle = (type: CardType): string => {
  switch (type) {
    case CardType.AVATAR_WITH_TITLE_SUBTITLE:
      return 'tw-text-TEXT_SECONDARY tw-truncate tw-text-sm tw-font-light';
    case CardType.INLINE_TITLE_SUBTITLE:
      return 'tw-text-2xl tw-font-medium tw-flex-1 tw-leading-[30px] tw-pt-0 tw-text-right';
    case CardType.CUSTOM:
      return '';
    default:
      return 'tw-text-sm tw-font-light';
  }
};

const getTextWrapperStyle = (type: CardType): string => {
  switch (type) {
    case CardType.AVATAR_WITH_TITLE_SUBTITLE:
      return 'tw-truncate tw-flex-col';
    case CardType.INLINE_TITLE_SUBTITLE:
      return 'tw-flex-row tw-justify-center tw-flex-1 tw-items-center';
    default:
      return 'tw-flex-col';
  }
};

const InfoErrorCard: React.FC<InfoErrorCardProps> = ({
  type = CardType.INFO,
  className,
  subtitle,
  iconPath = '',
  iconSize = CardIconType.SMALL,
  title,
  subtitleClassName = '',
  customSubtitleClassName = '',
  textWrapperClassName = '',
  titleClassName = 'f-16-400',
  showDownIcon = false,
  onClick,
  showIcon = true,
  textWrapperStyle = {},
  trailingSection = null,
  customIcon = null,
  customIconDimensions = null,
  iconClassName = 'tw-mr-4',
}) => {
  const iconSizing = customIconDimensions ?? {
    style: { minWidth: `${iconDimensions[iconSize]?.width}px` },
    ...iconDimensions[iconSize],
  };

  const subtitleStyleClassName =
    customSubtitleClassName ?? `f-14-300 ${title ? 'tw-pt-1' : ''} ${getSubtitleStyle(type)}`;

  return (
    <div
      className={`${commonCardStyles} ${cardStyles[type]} ${className}`}
      role='presentation'
      onClick={() => onClick && onClick()}
    >
      {showIcon &&
        (customIcon ?? <Image src={iconPath || icon[type]} alt='info' className={iconClassName} {...iconSizing} />)}
      <div className={`tw-flex ${getTextWrapperStyle(type)} ${textWrapperClassName}`} style={textWrapperStyle}>
        <div className={titleClassName}>{title}</div>
        <div className={`${subtitleStyleClassName} ${subtitleClassName}`}>{subtitle}</div>
      </div>
      {showDownIcon && (
        <Image src={DROPDOWN_ICON} alt='down-arrow' className='tw-min-w-[13px] tw-mr-[11px]' width={13} height={8} />
      )}
      {trailingSection}
    </div>
  );
};

export default InfoErrorCard;
