import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useMakePayoutRequestMutation } from 'api/moveMoney';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { TOTP_ERRORS } from 'constants/mfa';
import mixpanelEvents from 'constants/mixpanel';
import { Button } from 'destiny/dist/components/molecules/button';
import { Label } from 'destiny/dist/components/molecules/label';
import { SupporterInfo } from 'destiny/dist/components/molecules/supporterInfo';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { SUPPORT_INFO_TYPES } from 'destiny/dist/constants/molecules/supporterInfo';
import { useAppSelector } from 'hooks/toolkit';
import { useSessionToken } from 'hooks/useSessionToken';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import { BULK_PAYMENT_STAGES } from 'modules/bulk-payment/BulkPayment.constants';
import { BulkPaymentStagesCommonProps } from 'modules/bulk-payment/BulkPayment.types';
import BulkPaymentSourceAccountSelector from 'modules/bulk-payment/BulkPaymentSourceAccountSelector';
import BulkPaymentSuccess from 'modules/bulk-payment/BulkPaymentSuccess';
import CheckMFA from 'modules/settings/CheckMFA';
import { RootState } from 'store';
import { defaultFnType } from 'types';
import { AccountByVaultIdType } from 'types/accountsApi.types';
import { ApiErrorResponse } from 'types/api';
import { PendingPayoutTypes } from 'types/approval';
import { trackMixpanel } from 'utils/mixpanel';
import { ErrorCardTypes } from 'components/banners/types';
import ConfirmPopup from 'components/popup/ConfirmPopup';
import Textarea from 'components/textarea/Textarea';
import CommonWrapper from 'components/wrappers/CommonWrapper';

interface BulkPaymentPayProps extends BulkPaymentStagesCommonProps {
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  setIsError: Dispatch<SetStateAction<boolean>>;
  handleClose: defaultFnType;
}

const detailClassName = 'tw-flex tw-justify-between tw-items-center';

const BulkPaymentPay: FC<BulkPaymentPayProps> = ({ setCurrentStage, setIsSuccess, setIsError, handleClose }) => {
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [totp, setTotp] = useState('');
  const [totpError, setTotpError] = useState('');
  const [isExecuteErr, setIsExecuteErr] = useState(false);
  const [isExecuteSuccess, setIsExecuteSuccess] = useState(false);
  const [selectedSourceAccount, setSelectedSourceAccount] = useState<AccountByVaultIdType>();
  const [isBalanceError, setIsBalanceError] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);

  const [
    makePayoutRequest,
    {
      isLoading: isRequestPayoutLoading,
      isError: isRequestPayoutError,
      error: requestPayoutError,
      isSuccess: isRequestPayoutSuccess,
    },
  ] = useMakePayoutRequestMutation();

  const { sessionToken: sessionTokenRequest, createSessionToken: createSessionTokenRequest } = useSessionToken(
    SESSION_CAPABILITY_CONTEXT_KEYS.REQUEST_PAYOUTS_TRANSACTION
  );

  const { payouts, totalAmount, totalFees, sourceCurrencyCode, payoutId, sumAmount } = useAppSelector(
    (state: RootState) => state.bulkPayout
  );

  const { currencyCodeAndNameMap } = useAppSelector((state: RootState) => state.config);

  const isMakerRole = !useUserAccessFromPermission(
    FLOW_PERMISSION_PROPERTIES.UPDATE_PAYOUT_REQUESTS.permissionId,
    FLOW_PERMISSION_PROPERTIES.UPDATE_PAYOUT_REQUESTS.scope
  );

  const handleAddComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e?.target) setComment(e?.target?.value);
  };

  const handleShowCommentBox = () => setShowCommentBox((prevValue) => !prevValue);

  const handleSetTotp = (value: string) => setTotp(value);

  const handleRetryOnErr = () => {
    setIsExecuteErr(false);
    setIsError(false);
  };

  const onError = () => {
    setIsExecuteErr(true);
    setIsError(true);
  };

  const handleError = (e: ApiErrorResponse) => {
    if (e?.data?.error) {
      const { code, message } = e.data.error;

      if (code === TOTP_ERRORS.INVALID_TOTP) {
        setTotpError(message);
      } else onError();
    } else {
      onError();
    }
  };

  const handleRequestPayout = () => {
    makePayoutRequest({
      data: {
        type: PendingPayoutTypes.BULK_PAYOUT_V2,
        request_details: JSON.stringify({
          id: payoutId,
          account_id: selectedSourceAccount?.id ?? '',
          comments: comment,
        }),
      },
      accountId: selectedSourceAccount?.id ?? '',
      totp: totp,
      sessionToken: sessionTokenRequest ?? '',
    });
    createSessionTokenRequest();
  };

  const onSubmit = () => {
    handleRequestPayout();
  };

  const checkInsufficientBalance = (balance: string) => {
    if (+balance < (sumAmount ?? 0)) setIsBalanceError(true);
    else setIsBalanceError(false);
  };

  const handleSourceAccountChange = (data: AccountByVaultIdType) => {
    setSelectedSourceAccount(data);
    checkInsufficientBalance(data?.balance ?? 0);
  };

  const isExecutePaymentDisabled = totp?.trim()?.length !== 6 || isBalanceError;

  useEffect(() => {
    isRequestPayoutError && handleError(requestPayoutError);
  }, [isRequestPayoutError]);

  useEffect(() => {
    if (isRequestPayoutSuccess) {
      setIsSuccess(true);
      setIsExecuteSuccess(true);
    }
  }, [isRequestPayoutSuccess]);

  return (
    <CommonWrapper
      successCard={
        <BulkPaymentSuccess
          onClose={handleClose}
          amount={totalAmount}
          currency={currencyCodeAndNameMap[sourceCurrencyCode]}
          totalPayments={payouts?.length}
          currencyCode={sourceCurrencyCode}
          sourceAccountDetails={{
            name: selectedSourceAccount?.name ?? '',
            maskedNumber: selectedSourceAccount?.masked_number ?? '',
          }}
        />
      }
      errorCardType={ErrorCardTypes.PAYMENT_FAIL}
      isSuccess={isExecuteSuccess}
      isError={isExecuteErr}
      refetchFunnction={handleRetryOnErr}
    >
      <div className='tw-flex tw-gap-40 tw-mt-28'>
        <div className='tw-w-[380px]'>
          <div className='f-24-400 tw-mb-6'>Paying from</div>
          <BulkPaymentSourceAccountSelector
            sourceCurrencyCode={sourceCurrencyCode}
            selectedSourceAccount={selectedSourceAccount}
            onSourceAccountSelect={handleSourceAccountChange}
          />
          {isBalanceError && (
            <SupporterInfo
              type={SUPPORT_INFO_TYPES.ERROR}
              text='You have insufficient balance'
              iconAttributes={{
                iconCategory: ICON_SPRITE_TYPES.ALERTS_AND_FEEDBACK,
                id: 'alert-triangle',
                width: 12,
                height: 12,
              }}
            />
          )}
        </div>
        <div className='tw-w-[400px]'>
          <div className='tw-flex tw-flex-col tw-gap-3'>
            <Label
              wrapperClass={detailClassName}
              title='No. of payments'
              description={payouts?.length}
              titleClass='f-12-300'
              descriptionClass='f-16-400'
            />
            <Label
              wrapperClass={detailClassName}
              title='Total payment amount'
              description={`${totalAmount} ${currencyCodeAndNameMap[sourceCurrencyCode]}`}
              titleClass='f-12-300'
              descriptionClass='f-16-400'
            />
            <Label
              wrapperClass={detailClassName}
              title='Total Fee'
              description={`${totalFees} ${currencyCodeAndNameMap[sourceCurrencyCode]}`}
              titleClass='f-12-300 tw-text-TEXT_TERTIARY'
              descriptionClass='f-12-300 tw-text-TEXT_SECONDARY'
            />
            <div className='tw-border tw-border-DIVIDER_GRAY tw-border-dashed' />
            <Label
              wrapperClass={detailClassName}
              title='You are sending'
              description={`${sumAmount} ${currencyCodeAndNameMap[sourceCurrencyCode]}`}
              titleClass='f-12-300'
              descriptionClass='f-16-400'
            />
          </div>
          <div className='tw-my-6'>
            <div
              className='tw-flex tw-items-center tw-py-2 tw-cursor-pointer'
              role='presentation'
              onClick={handleShowCommentBox}
            >
              <SvgSpriteLoader
                id={showCommentBox ? 'minus-circle' : 'plus-circle'}
                iconCategory={ICON_SPRITE_TYPES.GENERAL}
                width={16}
                height={16}
                className='tw-mr-2.5'
              />
              <div className='tw-text-TEXT_PRIMARY f-14-300 tw-select-none'>Anything you’d like to add?</div>
            </div>
            {showCommentBox && (
              <Textarea
                id='comments'
                name='text'
                value={comment}
                placeHolder='Add any notes for future reference...'
                textAreaStyle='tw-bg-white tw-h-[60px] tw-px-6 tw-py-[21px] f-14-300 tw-text-TEXT_PRIMARY tw-rounded-[5px] placeholder:tw-text-TEXT_TERTIARY placeholder:tw-text-sm placeholder:tw-font-light placeholder:tw-tracking-[0.03em] focus:tw-border-ZAMP_PRIMARY focus:tw-outline-0'
                onChange={handleAddComment}
                autoFocus={true}
              />
            )}
          </div>
          <CheckMFA
            wrapperClassName=''
            setOtp={handleSetTotp}
            onOtpSubmit={onSubmit}
            otpError={totpError}
            autoFocus={false}
            showDivider={false}
            labelStyle='tw-text-TEXT_SECONDARY f-12-300 tw-mb-3'
            otpInputProps={{
              wrapperClassName: 'tw-gap-4',
              customStyles: 'tw-w-[52px] tw-h-[52px]',
            }}
            enableDescription='Before you proceed, add an extra layer of protection used to ensure the security'
            customEnableMFAStyles={{
              enableWrapperClass: 'tw-p-4',
              enableIconWrapperClass: 'tw-min-w-[80px] tw-min-h-[86px] tw-mr-8',
              enableIconWidth: 80,
              enableIconHeight: 86,
              enableLabelClass: 'f-14-400 tw-mb-2.5',
              enableDescriptionClass: 'f-12-300 tw-mb-2.5',
              enableButtonClass:
                'tw-bg-white tw-rounded-[100px] tw-border hover:tw-border-TEXT_PRIMARY !tw-h-[35px] tw-py-[9px] tw-px-4 f-12-400',
            }}
          />

          {/* TODO: will be shown when we have the updated statement
          <div className='tw-flex tw-bg-BASE_PRIMARY tw-px-4 tw-py-3 tw-rounded-2.5 tw-mb-8 tw-mt-5'>
            <SvgSpriteLoader
              id='info-circle'
              iconCategory={ICON_SPRITE_TYPES.GENERAL}
              color={COLORS.TEXT_SECONDARY}
              width={16}
              height={16}
              className='tw-mr-4'
            />
            <div className='tw-text-TEXT_SECONDARY f-12-300'>{BULK_PAYMENT_INFO_STATEMENT}</div>
          </div> */}
          <div className='tw-flex tw-flex-row-reverse tw-gap-4 tw-mt-5'>
            <Button
              buttonProps={{
                size: BUTTON_SIZE_TYPES.MEDIUM,
                id: 'BULK_PAYMENT_PAY_EXECUTE_BUTTON',
                eventCallback: trackMixpanel,
                disabled: isExecutePaymentDisabled,
                onClick: onSubmit,
                isLoading: isRequestPayoutLoading,
                wrapperClass: isMakerRole ? 'tw-min-w-[204px]' : 'tw-min-w-[110px]',
              }}
              showLeadingIcon
              customLeadingIcon={<SvgSpriteLoader id='check' iconCategory={ICON_SPRITE_TYPES.GENERAL} />}
            >
              <div className='tw-whitespace-nowrap'>{isMakerRole ? 'Send for Approval' : 'Pay'}</div>
            </Button>
            <Button
              buttonProps={{
                size: BUTTON_SIZE_TYPES.MEDIUM,
                btnType: BUTTON_TYPES.SECONDARY,
                id: 'BULK_PAYMENT_PAY_CANCEL_BUTTON',
                eventCallback: trackMixpanel,
                onClick: () => setShowCancelPopup(true),
              }}
            >
              <div className='tw-whitespace-nowrap'>Cancel Payments</div>
            </Button>
          </div>
        </div>
      </div>
      <ConfirmPopup
        isOpen={showCancelPopup}
        title='Cancel Payments'
        cancelBtnText='No'
        submitBtnText='Yes'
        isSubmitButtonLoading={false}
        submitBtnStyle='tw-min-w-[104px]'
        onCancel={() => setShowCancelPopup(false)}
        mixpanelEventOnCancel={'BULK_PAYMENT_PAY_' + mixpanelEvents.CANCEL_BULK_PAYMENT_NO}
        onSubmit={() => setCurrentStage(BULK_PAYMENT_STAGES.UPLOAD)}
        mixpanelEventOnClose={'BULK_PAYMENT_PAY_' + mixpanelEvents.CANCEL_BULK_PAYMENT_YES}
        className='f-20-500 tw-text-TEXT_GRAY_5'
      >
        <div className='f-16-400 tw-text-TEXT_GRAY_5'>Are you sure you want to cancel payments?</div>
      </ConfirmPopup>
    </CommonWrapper>
  );
};

export default BulkPaymentPay;
