import { ICON_PROPS } from 'components/icons/constants';

const BulkTransferArrow: React.FC<ICON_PROPS> = ({ height = 24, width = 24, color = '#181B28' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 7.5L8 2.5M8 2.5H4.66667M8 2.5V5.83333'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5 17L16.5 12M16.5 12H13.1667M16.5 12V15.3333'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 16.5L17 2.5M17 2.5V7.66667M17 2.5H12.3333'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default BulkTransferArrow;
