import SkeletonElement from 'components/skeletons/SkeletonElement';
import { SKELETON_ELEMENT_SHAPES } from 'components/skeletons/types';

const SKELETON_ARRAY = Array(3).fill('');

export const RfqSkeleton = () => {
  return (
    <>
      {SKELETON_ARRAY.map((_, index) => {
        return (
          <div className={`tw-w-full tw-flex tw-items-center tw-py-2`} key={index}>
            <div className={`tw-flex tw-items-center tw-w-[158px] tw-mr-8`}>
              <SkeletonElement shape={SKELETON_ELEMENT_SHAPES.CIRCLE} className='tw-mr-4 tw-w-5 tw-h-5' />
              <SkeletonElement
                className={`${index === 0 ? 'tw-w-[70px]' : index === 1 ? 'tw-w-20' : 'tw-w-[100px]'} tw-h-[13px]`}
              />
            </div>
            <SkeletonElement
              className={`${index === 0 ? 'tw-w-[52px]' : index === 1 ? 'tw-w-[136px]' : 'tw-w-[85px]'} tw-h-[13px]`}
            />
          </div>
        );
      })}
    </>
  );
};

export const AccountBalanceSkeleton = ({ className = '' }) => {
  return <SkeletonElement className={`tw-w-[150px] tw-h-[13px] tw-mb-4 ${className}`} />;
};

export const LineInputSkeleton = () => {
  return (
    <>
      <SkeletonElement className='tw-w-[89px] tw-h-[13px] tw-mb-[29px]' />
      <div className='tw-flex tw-justify-between tw-items-center tw-pb-2 tw-border-b tw-border-DIVIDER_GRAY'>
        <SkeletonElement className='tw-w-[65px] tw-h-5' />
        <SkeletonElement className='tw-w-[118px] tw-h-5' />
      </div>
    </>
  );
};

const TransferDetailsSkeleton = () => {
  return (
    <>
      <div className='tw-mb-2'>
        <LineInputSkeleton />
      </div>

      <AccountBalanceSkeleton />

      <RfqSkeleton />

      <div className='tw-mt-4 tw-mb-6'>
        <LineInputSkeleton />
      </div>
    </>
  );
};

export default TransferDetailsSkeleton;
