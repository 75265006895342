import { type FC } from 'react';
import { useGetCounterPartyByIdQuery } from 'api/beneficiary-v2';
import { TooltipPositions } from 'destiny/dist/constants';
import { getBeneListAsOptions } from 'modules/beneficiary-v2/beneficiary.utils';
import { formSuffix } from 'modules/policies/policies.utils';
import SelectRecipientAccountSkeleton from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/SelectRecipientAccountSkeleton';
import { showCounterParty } from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.utils';
import { SIZE_TYPES } from 'types';
import { CounterParty, CounterPartyById } from 'types/beneficiary-v2';
import Avatar from 'components/avatar/Avatar';
import { SkeletonTypes } from 'components/skeletons/types';
import StackedAvatarWithLabel from 'components/StackedAvatarWithLabel';
import CommonWrapper from 'components/wrappers/CommonWrapper';

type SelectCounterPartyListItemProps = {
  counterParty: CounterParty;
  onClick: (counterParty: CounterParty, counterPartyById: CounterPartyById) => void;
  destCurrencyCode?: string;
  networkCode?: string;
};

const SelectCounterPartyListItem: FC<SelectCounterPartyListItemProps> = ({
  counterParty,
  onClick,
  destCurrencyCode,
  networkCode,
}) => {
  const { data, isFetching, isError, refetch } = useGetCounterPartyByIdQuery(counterParty?.id);

  const handleClick = () => {
    data && onClick(counterParty, data);
  };

  const beneList = getBeneListAsOptions(data?.fiat_beneficiaries ?? [], data?.crypto_beneficiaries ?? []);
  const label =
    (counterParty?.fiat_accounts_count >= 1
      ? `${counterParty?.fiat_accounts_count} ${formSuffix('account', counterParty?.fiat_accounts_count)}`
      : '') +
    (counterParty?.fiat_accounts_count >= 1 && counterParty?.crypto_wallets_count >= 1 ? ', ' : '') +
    (counterParty?.crypto_wallets_count >= 1
      ? `${counterParty?.crypto_wallets_count} ${formSuffix('wallet', counterParty?.crypto_wallets_count)}`
      : '');

  const isCounterPartyVisible = showCounterParty(beneList, destCurrencyCode, networkCode);

  return (
    <CommonWrapper
      isLoading={isFetching}
      isError={isError}
      refetchFunnction={refetch}
      skeleton={<SelectRecipientAccountSkeleton count={1} />}
      skeletonType={SkeletonTypes.CUSTOM}
      isNoData={!isCounterPartyVisible}
    >
      <div
        className='tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-3'
        onClick={handleClick}
        data-testid={`select-counterparty-${counterParty?.id}`}
      >
        <div className='tw-flex tw-items-center tw-w-[150px]'>
          <Avatar name={counterParty?.name} className='tw-mr-3 !tw-min-w-[24px] !tw-h-6' />
          <div className='f-14-400'>{counterParty?.name}</div>
        </div>
        <StackedAvatarWithLabel
          list={beneList}
          label={label}
          size={SIZE_TYPES.SMALL}
          tooltipPosition={TooltipPositions.LEFT}
        />
      </div>
    </CommonWrapper>
  );
};

export default SelectCounterPartyListItem;
