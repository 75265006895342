import { FC } from 'react';
import { ACCESS_FLAGS } from 'constants/config';
import { defaultFn } from 'destiny/dist/constants/index';
import { useAppSelector } from 'hooks/toolkit';
import BeneficiaryListItem from 'modules/single-transfer/thirdPartyPayouts/steps/BeneficiaryListItem';
import SelectedAccount from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedAccount';
import SelectedRecipient from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedRecipient';
import { getSelectedSourceAccountCardProps } from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.utils';
import { RootState } from 'store';
import { defaultFnType } from 'types';
import { CounterParty } from 'types/beneficiary-v2';
import { getBeneIcon } from 'utils/beneficiary';
import Avatar from 'components/avatar/Avatar';

interface SelectedAccountsProps {
  wrapperClassName?: string;
  wrapperStyle?: string;
  recipientAccount?: any;
  sourceAccount?: any;
  recipientProps?: any;
  sourceProps?: any;
  isSummary?: boolean;
  onRecipientClick?: defaultFnType;
  onSourceClick?: defaultFnType;
  isBulk?: boolean;
  isRecipientAccount?: boolean;
  selectedCounterParty?: CounterParty;
  onClickCounterParty?: defaultFnType;
  recipientTitle?: string;
  counterPartyCardWrapperClassName?: string;
  recipientClassName?: string;
  recipientAccountCardWrapperClassName?: string;
}

const SelectedAccounts: FC<SelectedAccountsProps> = ({
  wrapperClassName = 'tw-w-full',
  wrapperStyle = '',
  recipientAccount = null,
  sourceAccount = null,
  recipientProps = {},
  sourceProps = {},
  isSummary = false,
  onRecipientClick = defaultFn,
  onSourceClick = defaultFn,
  isBulk = false,
  isRecipientAccount = false,
  selectedCounterParty,
  onClickCounterParty,
  recipientTitle = 'Paying to',
  counterPartyCardWrapperClassName,
  recipientClassName = 'tw-bg-BASE_PRIMARY',
  recipientAccountCardWrapperClassName,
}) => {
  const { userAccessFlags } = useAppSelector((state: RootState) => state.user);
  const isBizbankingEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_BUSINESS_BANKING_ENABLED];
  const isMeaView = isBizbankingEnabled || isBulk;

  return (
    <div className={`${wrapperClassName} ${wrapperStyle}`}>
      {!!selectedCounterParty && !isSummary && (
        <SelectedAccount
          wrapperStyle='tw-mb-3'
          labelProps={{
            title: recipientTitle,
          }}
          cardProps={{
            customIcon: <Avatar name={selectedCounterParty?.name} className='tw-mr-3 !tw-min-w-[24px] !tw-h-6' />,
            title: selectedCounterParty?.name,
            onClick: onClickCounterParty,
          }}
          cardWrapperClassName={counterPartyCardWrapperClassName}
        />
      )}
      {!!selectedCounterParty && !!recipientAccount && !isSummary && (
        <>
          <div className='f-12-400 tw-text-TEXT_TERTIARY tw-mb-2'>Select an account/wallet</div>
          <BeneficiaryListItem
            beneficiary={recipientAccount}
            onClick={onRecipientClick}
            className={`!tw-px-5  tw-rounded-2.5 tw-mb-4 ${recipientClassName}`}
            showDropdownIcon
          />
        </>
      )}
      {!selectedCounterParty && !!recipientAccount && (
        <SelectedAccount
          wrapperStyle={isSummary ? 'tw-mb-4' : 'tw-mb-3'}
          labelProps={{
            title: 'Paying to',
          }}
          cardProps={{
            ...(isRecipientAccount
              ? getSelectedSourceAccountCardProps({ sourceAccount: recipientAccount, isMeaView })
              : {
                  title: recipientAccount?.beneficiary_name,
                  subtitle: recipientAccount?.beneficiary_account_number,
                  iconPath: getBeneIcon(recipientAccount),
                }),
            onClick: isSummary ? defaultFn : onRecipientClick,
          }}
          isSummary={isSummary}
          editClick={isSummary ? onRecipientClick : defaultFn}
          cardWrapperClassName={recipientAccountCardWrapperClassName}
          {...recipientProps}
        />
      )}
      {isSummary && !!selectedCounterParty && !!recipientAccount && (
        <div className='tw-mb-5'>
          <div className='f-12-300 tw-mb-2'>Paying to</div>
          <SelectedRecipient
            recipientAccount={recipientAccount}
            selectedCounterParty={selectedCounterParty}
            onRecipientClick={onRecipientClick}
            showEdit
          />
        </div>
      )}
      {!!sourceAccount && (
        <SelectedAccount
          wrapperStyle={isSummary ? 'tw-mb-4' : 'tw-mb-2'}
          labelProps={{
            title: 'Paying from',
          }}
          cardProps={{
            ...getSelectedSourceAccountCardProps({ sourceAccount, isMeaView }),
            onClick: isSummary ? defaultFn : onSourceClick,
          }}
          isSummary={isSummary}
          editClick={isSummary ? onSourceClick : defaultFn}
          cardWrapperClassName={counterPartyCardWrapperClassName}
          {...sourceProps}
        />
      )}
    </div>
  );
};

export default SelectedAccounts;
