import { OTC } from 'constants/index';
import { useRouter } from 'next/router';

const useIsOtcPage = () => {
  const router = useRouter();
  const isOtcPage = router.pathname?.includes(OTC);

  return isOtcPage;
};

export default useIsOtcPage;
