import { memo } from 'react';
import { sidebarEventsMap } from 'constants/index';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { trackMixpanel } from 'utils/mixpanel';

export interface SidebarTabOtcProps {
  isSelected: boolean;
  iconId: string;
  iconCategory: ICON_SPRITE_TYPES;
  name: string;
  accessFlag: string;
}

const SidebarTabOtc: React.FC<SidebarTabOtcProps> = ({ isSelected, iconId, iconCategory, name, accessFlag }) => {
  return (
    <div
      className={`tw-w-full tw-h-11.5 tw-px-5 tw-transition-[0.2s] tw-flex tw-gap-2 tw-items-center hover:tw-bg-GREEN_BG tw-mb-1  ${
        isSelected
          ? 'tw-bg-GREEN_BG tw-text-GREEN_SECONDARY f-14-500 tw-border-l-2 tw-border-GREEN_SECONDARY'
          : 'tw-text-TEXT_SECONDARY f-14-300'
      }`}
      onClick={() => {
        trackMixpanel(sidebarEventsMap[accessFlag][name], {});
      }}
      role='presentation'
    >
      {iconId && (
        <SvgSpriteLoader
          id={iconId}
          iconCategory={iconCategory}
          width={16}
          height={16}
          fillColor={COLORS.WHITE}
          className='tw-min-w-4'
        />
      )}
      <div className='tw-text-sm tw-whitespace-nowrap tw-block'>{name}</div>
    </div>
  );
};

export default memo(SidebarTabOtc);
