import { type FC } from 'react';
import { BeneficiaryStateProvider } from 'modules/beneficiary-v2/beneficiary.context';
import BeneficiaryCreateFullPagePopup, {
  BeneficiaryCreateFullPagePopupProps,
} from 'modules/beneficiary-v2/BeneficiaryCreateFullPagePopup';

const BeneficiaryCreateWithContext: FC<BeneficiaryCreateFullPagePopupProps> = ({
  onClose,
  counterParty,
  refetchCounterParties,
}) => (
  <BeneficiaryStateProvider>
    <BeneficiaryCreateFullPagePopup
      onClose={onClose}
      counterParty={counterParty}
      refetchCounterParties={refetchCounterParties}
    />
  </BeneficiaryStateProvider>
);

export default BeneficiaryCreateWithContext;
