import React, { useCallback, useEffect, useState } from 'react';
import { useLazyGetBeneficiaryPayoutEligibilityQuery } from 'api/beneficiary';
import { useGetPayoutConfigsForThirdPartyQuery, useGetSlippageQuery, useUpdateSlippageMutation } from 'api/moveMoney';
import { ACCESS_FLAGS } from 'constants/config';
import { ALERT } from 'constants/icons';
import { SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { Text } from 'destiny/dist/components/atoms/text';
import { Button } from 'destiny/dist/components/molecules/button';
import { SupporterInfo } from 'destiny/dist/components/molecules/supporterInfo';
import { defaultFn } from 'destiny/dist/constants/index';
import { BUTTON_SIZE_TYPES, BUTTON_STATE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { SUPPORT_INFO_TYPES } from 'destiny/dist/constants/molecules/supporterInfo';
import { debounce } from 'hooks';
import { useAppSelector } from 'hooks/toolkit';
import { useSessionToken } from 'hooks/useSessionToken';
import AccountBalance from 'modules/single-transfer/components/accountBalance';
import AmountDetails from 'modules/single-transfer/fiat/AmountDetails';
import CurrencyWalletSelect from 'modules/single-transfer/thirdPartyPayouts/steps/common/CurrencyWalletSelect';
import TransferDetailsSkeleton, {
  RfqSkeleton,
} from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/TransferDetailsSkeleton';
import Slippage from 'modules/single-transfer/thirdPartyPayouts/steps/Slippage';
import { showSlippage } from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.utils';
import Image from 'next/image';
import { RootState } from 'store';
import { MenuItem } from 'types';
import { GetAccountBalanceForAccountIdResponse } from 'types/api';
import { BeneficiaryPayoutEligibilty } from 'types/beneficiary';
import {
  AmountInfoType,
  AmountType,
  DestCurrency,
  RfqPayload,
  SourceCurrency,
  ThirdPartyTransferDetailsProps,
} from 'types/transactions';
import { trackMixpanel } from 'utils/mixpanel';
import { ErrorCardTypes } from 'components/banners/types';
import SkeletonElement from 'components/skeletons/SkeletonElement';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

const TransferDetails: React.FC<ThirdPartyTransferDetailsProps> = ({
  selectedRecipientAccount = null,
  selectedSourceAccount = null,
  selectedSourceCurrency = null,
  selectedDestCurrency = null,
  selectedAmountInfo = null,
  rfqDetails = null,
  rfqLoading = false,
  rfqError = null,
  clearRfqTimer = defaultFn,
  setRfqPayload = defaultFn,
  createRfq = defaultFn,
  handleSubmit = defaultFn,
  onError,
}) => {
  const { currencyCodeAndNameMap } = useAppSelector((state: RootState) => state.config);
  const {
    data: slippageData,
    isLoading: isSlippageLoading,
    isError: isSlippageError,
    refetch: refetchSlippage,
  } = useGetSlippageQuery();

  const [updateSlippage] = useUpdateSlippageMutation();
  const { sessionToken } = useSessionToken(SESSION_CAPABILITY_CONTEXT_KEYS.UPDATE_SLIPPAGE);

  const [initialDataSet, setInitialData] = useState(false);
  const [destCurrency, setDestCurrency] = useState<DestCurrency>();
  const [destCurrencies, setDestCurrencies] = useState<DestCurrency[]>([]);
  const [sourceCurrency, setSourceCurrency] = useState<SourceCurrency>();
  const [sourceCurrencies, setSourceCurrencies] = useState<SourceCurrency[]>([]);
  const [accountBalance, setAccountBalance] = useState<GetAccountBalanceForAccountIdResponse>();
  const [rfqPayloadState, setRfqPayloadState] = useState<RfqPayload>({
    corridor_id: '',
    beneficiary_id: '',
    account_id: '',
  });
  const [amountInfo, setAmountInfo] = useState<AmountInfoType>({
    [AmountType.SOURCE_AMOUNT]: '',
    [AmountType.DEST_AMOUNT]: '',
    isSourceAmount: true,
  });
  const [slippageValue, setSlippageValue] = useState<string>('0.05');
  const { userAccessFlags } = useAppSelector((state: RootState) => state.user);
  const isBizbankingEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_BUSINESS_BANKING_ENABLED];

  const {
    data: currencyConfigs,
    isLoading,
    isError,
    refetch,
  } = useGetPayoutConfigsForThirdPartyQuery({
    beneficiary_id: selectedRecipientAccount?.id || '',
    [isBizbankingEnabled ? 'account_id' : 'vault_id']: selectedSourceAccount?.id || '',
    version: isBizbankingEnabled ? 'v3' : 'v2',
  });

  const [getBeneficiaryPayoutEligibility, { isFetching: isBeneficiaryPayoutEligibiltyFetching }] =
    useLazyGetBeneficiaryPayoutEligibilityQuery();

  const showAmountDetails =
    !rfqError && rfqDetails && destCurrency && sourceCurrency && amountInfo?.destAmount && amountInfo?.sourceAmount;

  useEffect(() => {
    if (currencyConfigs) {
      const destinationCurrencies: DestCurrency[] = [];

      currencyConfigs.forEach((config) => {
        destinationCurrencies.push({
          code: config.dest_currency_code,
          sourceCurrencies: config.source_currencies,
        });
      });
      const initDestCurrency = selectedDestCurrency ?? destinationCurrencies[0];

      setDestCurrencies(destinationCurrencies);
      setDestCurrency(initDestCurrency);
      setSourceCurrencies(initDestCurrency.sourceCurrencies);
      setSourceCurrency(selectedSourceCurrency ?? initDestCurrency.sourceCurrencies[0]);
    }
  }, [currencyConfigs, selectedDestCurrency, selectedSourceCurrency]);

  useEffect(() => {
    if (
      accountBalance?.balance &&
      selectedAmountInfo?.sourceAmount &&
      !(amountInfo?.isSourceAmount && amountInfo?.sourceAmount)
    ) {
      const defaultSourceAmount = selectedAmountInfo?.sourceAmount;

      onAmountChange(AmountType.SOURCE_AMOUNT, defaultSourceAmount, sourceCurrency);
    }
  }, [accountBalance]);

  useEffect(() => {
    if (rfqDetails)
      if (selectedSourceCurrency && selectedDestCurrency && !initialDataSet) {
        setAmountInfo((oldState) => ({
          ...oldState,
          [AmountType.DEST_AMOUNT]: rfqDetails?.receiving_amount?.toString(),
          [AmountType.SOURCE_AMOUNT]: rfqDetails?.source_amount?.toString(),
        }));
        setInitialData(true);
      } else {
        setAmountInfo((oldState) => ({
          ...oldState,
          ...(oldState.isSourceAmount
            ? { [AmountType.DEST_AMOUNT]: rfqDetails?.receiving_amount?.toString() }
            : { [AmountType.SOURCE_AMOUNT]: rfqDetails?.source_amount?.toString() }),
        }));
      }
  }, [rfqDetails]);

  useEffect(() => {
    rfqError &&
      setAmountInfo((oldState) => {
        return {
          ...oldState,
          ...(oldState.isSourceAmount ? { [AmountType.DEST_AMOUNT]: '' } : { [AmountType.SOURCE_AMOUNT]: '' }),
        };
      });
  }, [rfqError]);

  const handleCreateRfq = (newPayload?: RfqPayload) => {
    const payload = newPayload ?? rfqPayloadState;

    setRfqPayload(payload);
    setRfqPayloadState(payload);

    try {
      createRfq(payload);
    } catch (e) {
      console.log('Some error occurred', e);
    }
  };

  const updateCurrencies = (
    amount: string,
    isSourceAmount: boolean,
    sourceCurrencyConfig: SourceCurrency,
    slippageValue: string
  ) => {
    clearRfqTimer();
    if (amount) {
      const amountNumber = Number(amount);

      if (selectedRecipientAccount && sourceCurrencyConfig) {
        const payload: RfqPayload = {
          corridor_id: sourceCurrencyConfig?.corridor_id,
          beneficiary_id: selectedRecipientAccount?.id,
          account_id: sourceCurrencyConfig?.account_id,
          slippage: +parseFloat(slippageValue).toFixed(2),
        };

        payload[isSourceAmount ? 'source_amount' : 'receiving_amount'] = amountNumber;

        handleCreateRfq(payload);
      }
    }
  };

  const getConversionCallback = useCallback(debounce(updateCurrencies, 500), []);

  const onAmountChange = (type: string, value: string, sourceCurrencyConfig: SourceCurrency | undefined) => {
    if (Number.isFinite(Number(value))) {
      const fraction = value?.split('.')[1];
      const isSourceAmount = type === AmountType.SOURCE_AMOUNT;

      if (fraction?.length > 8) return;

      getConversionCallback(value, isSourceAmount, sourceCurrencyConfig, slippageValue);

      if (!value)
        setAmountInfo((oldState) => ({
          ...oldState,
          sourceAmount: '',
          destAmount: '',
          isSourceAmount,
        }));
      else
        setAmountInfo((oldState) => ({
          ...oldState,
          [isSourceAmount ? AmountType.SOURCE_AMOUNT : AmountType.DEST_AMOUNT]: value,
          isSourceAmount,
        }));
    }
  };

  const hasInvalidAmountError = (type: AmountType, amount: string): string => {
    if (sourceCurrency) {
      const isSourceAmount = type === AmountType.SOURCE_AMOUNT;
      const { source_currency_min_value, source_currency_max_value, dest_currency_min_value, dest_currency_max_value } =
        sourceCurrency;
      const minValue = isSourceAmount ? source_currency_min_value : dest_currency_min_value;
      const maxValue = isSourceAmount ? source_currency_max_value : dest_currency_max_value;
      const isAch = selectedRecipientAccount?.routing_code_type_1 === 'ACH CODE';

      if (amount && +amount !== 0 && (+amount < minValue || +amount > maxValue))
        return `Amount should be within ${minValue} and ${maxValue}`;
      else if (isAch && !isSourceAmount && +amount > 50000)
        return 'ACH transactions are limited to $50,000. Please select FedWire for higher amounts.';
    }

    return '';
  };

  const handleNext = (beneficiaryPayoutEligibilty?: BeneficiaryPayoutEligibilty) => {
    if (sourceCurrency && destCurrency)
      handleSubmit({
        selectedSourceCurrency: sourceCurrency,
        selectedDestCurrency: destCurrency,
        selectedAmountInfo: amountInfo,
        recipientId: beneficiaryPayoutEligibilty?.id,
        needAdditionalDetails: !beneficiaryPayoutEligibilty?.is_beneficiary_payout_eligible,
        beneData: beneficiaryPayoutEligibilty,
      });
  };

  const handleSourceAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target) onAmountChange(AmountType.SOURCE_AMOUNT, e?.target?.value, sourceCurrency);
  };

  const handleSourceCurrencyChange = (item: SourceCurrency | DestCurrency | MenuItem) => {
    const sourceItem = item as SourceCurrency;

    setSourceCurrency(sourceItem);
    const isSourceAmount = amountInfo?.sourceAmount ? true : false;
    const value = amountInfo?.sourceAmount || amountInfo.destAmount;

    getConversionCallback(value, isSourceAmount, sourceItem, slippageValue);
  };

  const handleDestinationAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target) onAmountChange(AmountType.DEST_AMOUNT, e?.target?.value, sourceCurrency);
  };

  const handleDestinationCurrencyChange = (item: SourceCurrency | DestCurrency | MenuItem) => {
    const destItem = item as DestCurrency;
    const sourceCurr = destItem?.sourceCurrencies?.[0];

    setDestCurrency(destItem);
    setSourceCurrencies(destItem?.sourceCurrencies);
    setSourceCurrency(sourceCurr);
    const isSourceAmount = amountInfo?.isSourceAmount;
    const value = amountInfo?.sourceAmount || amountInfo?.destAmount;

    getConversionCallback(value, isSourceAmount, sourceCurr, slippageValue);
  };

  const handleNextWrapper = () => {
    updateSlippage({
      data: { slippage: +parseFloat(slippageValue).toFixed(2) },
      idempotencyHeader: sessionToken ?? '',
    });
    getBeneficiaryPayoutEligibility(rfqDetails?.id ?? '')
      .unwrap()
      .then(handleNext)
      .catch(onError);
  };

  const handleSlippageChange = (value: string) => {
    getConversionCallback(amountInfo?.sourceAmount, amountInfo?.isSourceAmount, sourceCurrency, value);
    setSlippageValue(value);
  };

  const nextButtonDisabled =
    rfqLoading ||
    !+amountInfo?.sourceAmount ||
    !+amountInfo?.destAmount ||
    !sourceCurrency?.code ||
    !!hasInvalidAmountError(AmountType.SOURCE_AMOUNT, amountInfo[AmountType.SOURCE_AMOUNT]) ||
    !!hasInvalidAmountError(AmountType.DEST_AMOUNT, amountInfo[AmountType.DEST_AMOUNT]);

  useEffect(() => {
    setSlippageValue(`${slippageData}`);
  }, [slippageData]);

  return (
    <div className='tw-w-full tw-mt-4'>
      <CommonWrapper
        isLoading={isLoading}
        skeletonType={SkeletonTypes.CUSTOM}
        skeleton={<TransferDetailsSkeleton />}
        isError={isError}
        errorCardType={ErrorCardTypes.API_FAIL}
        refetchFunnction={refetch}
      >
        <div className='tw-mb-2'>
          <CurrencyWalletSelect
            label='You are sending'
            inputProps={{
              id: 'single-transfer-source-amount-input',
              name: 'sourceAmount',
              onChange: handleSourceAmountChange,
              hasError: hasInvalidAmountError(AmountType.SOURCE_AMOUNT, amountInfo[AmountType.SOURCE_AMOUNT]),
              inputError: hasInvalidAmountError(AmountType.SOURCE_AMOUNT, amountInfo[AmountType.SOURCE_AMOUNT])
                ? true
                : false,

              value: amountInfo?.sourceAmount,
            }}
            showWallet={sourceCurrencies?.length > 0}
            walletSelectProps={{
              id: 'source_currency',
              onMenuItemClick: handleSourceCurrencyChange,
              options: sourceCurrencies,
              selectedItem: sourceCurrency,
            }}
          />
        </div>

        {sourceCurrency && <AccountBalance sourceCurrency={sourceCurrency} setAccountBalance={setAccountBalance} />}

        <CommonWrapper
          isLoading={rfqLoading}
          skeletonType={SkeletonTypes.CUSTOM}
          skeleton={<RfqSkeleton />}
          isError={rfqError && !rfqError?.data?.error?.code ? true : false}
          errorCardType={ErrorCardTypes.API_FAIL}
          refetchFunnction={handleCreateRfq}
        >
          {showAmountDetails && (
            <AmountDetails
              transferDetails={rfqDetails}
              destinationCurrency={currencyCodeAndNameMap?.[destCurrency?.code]}
              sourceCurr={currencyCodeAndNameMap?.[sourceCurrency?.code]}
              corridor={sourceCurrency.corridor_type}
              itemsWrapperClassName='tw-w-[158px] tw-mr-8'
              iconClassName='tw-mr-4'
              iconStyle={{ width: 20, height: 20 }}
              currencyTextClassName='f-12-400'
              labelClassName='tw-w-auto f-12-300'
              feeBreakupWrapperClassName='tw-pl-9'
            />
          )}
        </CommonWrapper>

        {rfqError && rfqError?.data?.error?.message && (
          <SupporterInfo
            text={
              <div className='tw-flex tw-items-center'>
                <Image src={ALERT} width={12} height={12} alt='alert' />
                <Text textClass='tw-ml-[8px] f-12-300 tw-whitespace-pre-wrap tw-text-RED_PRIMARY'>
                  {rfqError?.data?.error?.message}
                </Text>
              </div>
            }
            type={SUPPORT_INFO_TYPES.ERROR}
          />
        )}

        <div className='tw-mt-4 tw-mb-6'>
          <CurrencyWalletSelect
            label='Recipient gets'
            inputProps={{
              id: 'single-transfer-destination-amount-input',
              name: 'destinationAmount',
              onChange: handleDestinationAmountChange,
              hasError: hasInvalidAmountError(AmountType.DEST_AMOUNT, amountInfo[AmountType.DEST_AMOUNT]),
              inputError: hasInvalidAmountError(AmountType.DEST_AMOUNT, amountInfo[AmountType.DEST_AMOUNT])
                ? true
                : false,
              value: amountInfo.destAmount,
            }}
            showWallet={destCurrencies?.length > 0}
            walletSelectProps={{
              id: 'dest_currency',
              onMenuItemClick: handleDestinationCurrencyChange,
              options: destCurrencies,
              selectedItem: destCurrency,
            }}
          />
        </div>
        {showSlippage(sourceCurrency, destCurrency, amountInfo) && (
          <CommonWrapper
            isLoading={isSlippageLoading}
            isError={isSlippageError}
            refetchFunnction={refetchSlippage}
            skeletonType={SkeletonTypes.CUSTOM}
            skeleton={<SkeletonElement className='tw-h-28' />}
            className='tw-mb-4 tw-mt-10'
          >
            <Slippage
              slippageValue={slippageValue}
              setSlippageValue={handleSlippageChange}
              receivingAmount={amountInfo.destAmount}
              destCurrencyCode={destCurrency?.code}
            />
          </CommonWrapper>
        )}
      </CommonWrapper>

      <Button
        buttonProps={{
          btnType: BUTTON_TYPES.PRIMARY,
          size: BUTTON_SIZE_TYPES.MEDIUM,
          state: BUTTON_STATE_TYPES.DEFAULT,
          wrapperClass: 'tw-min-w-[120px]',
          disabled: nextButtonDisabled,
          onClick: handleNextWrapper,
          id: 'MOVE_MONEY_SINGLE_TRANSFER_DETAILS_PRIMARY_BUTTON',
          eventCallback: trackMixpanel,
          isLoading: isBeneficiaryPayoutEligibiltyFetching,
        }}
      >
        Next
      </Button>
    </div>
  );
};

export default TransferDetails;
