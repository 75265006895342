import { type FC, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroller';
import { Player } from '@lottiefiles/react-lottie-player';
import { useLazyGetCounterPartiesQuery } from 'api/beneficiary-v2';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { PLUS_CIRCLE, SEARCH_MD } from 'constants/icons';
import { StatusType } from 'constants/kyb';
import { WHITE_LOADER } from 'constants/lottie/loading_whilte';
import { useAppSelector } from 'hooks/toolkit';
import useConfiguration, { CONFIG_DEFINITIONS } from 'hooks/useConfiguration';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import { COUNTER_PARTIES_PAGE_LIMIT } from 'modules/beneficiary-v2/beneficiary.constants';
import BeneficiaryCreateWithContext from 'modules/beneficiary-v2/BeneficiaryCreateWithContext';
import BeneficiaryListingZeroData from 'modules/beneficiary-v2/BeneficiaryListingZeroData';
import SelectCounterPartyListItem from 'modules/single-transfer/thirdPartyPayouts/steps/SelectCounterPartyListItem';
import SelectRecipientAccountSkeleton from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/SelectRecipientAccountSkeleton';
import {
  thirdPartyPayoutActions,
  useThirdPartyPayoutContextStore,
} from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.context';
import Image from 'next/image';
import { RootState } from 'store';
import { CounterParty, CounterPartyById } from 'types/beneficiary-v2';
import Input from 'components/input/Input';
import SkeletonList from 'components/skeletons/SkeletonList';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

type SelectCounterPartyProps = {
  setSelectedCounterParty?: (counterParty: CounterParty) => void;
  setSelectedCounterPartyById?: (counterPartyById: CounterPartyById) => void;
  searchClassName?: string;
  destCurrencyCode?: string;
  networkCode?: string;
};
const SelectCounterParty: FC<SelectCounterPartyProps> = ({
  setSelectedCounterPartyById,
  setSelectedCounterParty,
  searchClassName = 'tw-bg-GRAY_2',
  destCurrencyCode,
  networkCode,
}) => {
  const [showList, setShowList] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [showAddRecipient, setShowAddRecipient] = useState<boolean>(false);

  const {
    state: { counterParties },
    dispatch,
  } = useThirdPartyPayoutContextStore();

  const [getCounterParties, { data, isFetching, isError }] = useLazyGetCounterPartiesQuery();

  const { checkConfigEnabled } = useConfiguration();
  const { bankingKybStatus } = useAppSelector((state: RootState) => state.kyb);
  const isBankingPreKyb = bankingKybStatus !== StatusType.SUCCESS;
  const canManageRecipients = useUserAccessFromPermission(
    FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId,
    FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope
  );
  const canAddRecipient =
    checkConfigEnabled(CONFIG_DEFINITIONS.ADD_RECIPIENTS) && !isBankingPreKyb && canManageRecipients;

  const noRecipients = !isFetching && (!counterParties || counterParties?.length === 0);

  const handleFetchCounterParties = (page: number, search: string) => {
    !isFetching &&
      getCounterParties({ page, limit: COUNTER_PARTIES_PAGE_LIMIT, search_key: search })
        .unwrap()
        .then((data) => {
          dispatch({ type: thirdPartyPayoutActions.SET_COUNTER_PARTIES, payload: { page, newCounterParties: data } });
        })
        .catch(console.log);
  };

  const toggleAddBene = () => setShowAddRecipient((prev) => !prev);

  const handleCounterPartySelect = (counterParty: CounterParty, counterPartyById: CounterPartyById) => {
    setShowList(false);
    setSelectedCounterParty?.(counterParty);
    setSelectedCounterPartyById?.(counterPartyById);
  };

  const refetchCounterParties = () => {
    setTimeout(() => handleFetchCounterParties(1, search), 500);
  };

  useEffect(() => {
    handleFetchCounterParties(1, search);
  }, [search]);

  return (
    <>
      {showList && (
        <>
          <div className='tw-w-full tw-flex tw-flex-col'>
            <div className='tw-w-full tw-grow tw-relative tw-mb-2'>
              <div className='tw-h-full tw-left-6 tw-absolute tw-z-50 tw-flex tw-items-center'>
                <Image src={SEARCH_MD} alt='search' width={20} height={20} priority />
              </div>
              <Input
                id='search'
                value={search}
                onChange={(evt: any) => setSearch(evt?.target?.value)}
                className={`f-16-300 tw-border-none  tw-text-TEXT_PRIMARY !tw-h-[52px] !tw-rounded-[5px] tw-pl-[60px] !tw-pr-6 !tw-py-4 ${searchClassName}`}
                placeHolder='Search by name'
              />
              {isFetching && search && (
                <div className='tw-h-full tw-right-6 tw-top-0 tw-absolute tw-z-50 tw-flex tw-items-center'>
                  <Player src={JSON.parse(WHITE_LOADER)} className='lottie-player' loop autoplay />
                </div>
              )}
            </div>
          </div>

          <div className='tw-bg-white tw-rounded-2.5 tw-border tw-DIVIDER_GRAY tw-shadow-menu'>
            {canAddRecipient && !noRecipients && (
              <div
                role='presentation'
                onClick={toggleAddBene}
                className='tw-flex tw-items-center tw-px-6 tw-py-4 tw-border-b tw-DIVIDER_GRAY tw-cursor-pointer'
                data-testid='add-recipient-button'
              >
                <Image src={PLUS_CIRCLE} width={24} height={24} className='tw-mr-3' alt='add' priority />
                <div className='f-14-400 tw-text-TEXT_PRIMARY' id='add-a-new-recipient'>
                  Add a new recipient
                </div>
              </div>
            )}
            <CommonWrapper
              isLoading={isFetching && !counterParties?.length}
              skeletonType={SkeletonTypes.CUSTOM}
              skeleton={<SelectRecipientAccountSkeleton />}
              isError={isError && !counterParties?.length}
              isNoData={!isFetching && !counterParties?.length && !data?.length}
              noDataBanner={<BeneficiaryListingZeroData />}
              refetchFunnction={refetchCounterParties}
            >
              <div className='tw-max-h-[354px] tw-overflow-y-auto'>
                <InfiniteScroll
                  loadMore={handleFetchCounterParties}
                  hasMore={!isFetching && data?.length === COUNTER_PARTIES_PAGE_LIMIT}
                  loader={
                    <div key='bene-listing-loader'>
                      <SkeletonList />
                    </div>
                  }
                  useWindow={false}
                >
                  {counterParties?.map((item) => (
                    <SelectCounterPartyListItem
                      key={item?.id}
                      counterParty={item}
                      onClick={handleCounterPartySelect}
                      destCurrencyCode={destCurrencyCode}
                      networkCode={networkCode}
                    />
                  ))}
                </InfiniteScroll>
              </div>
            </CommonWrapper>
          </div>
        </>
      )}

      {showAddRecipient && (
        <BeneficiaryCreateWithContext onClose={toggleAddBene} refetchCounterParties={refetchCounterParties} />
      )}
    </>
  );
};

export default SelectCounterParty;
