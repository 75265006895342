import { type FC } from 'react';
import SelectedAccount from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedAccount';
import SelectBankAccountWallet from 'modules/single-transfer/thirdPartyPayouts/steps/SelectBankAccountWallet';
import SelectCounterParty from 'modules/single-transfer/thirdPartyPayouts/steps/SelectCounterParty';
import { ThirdPartyPayoutStateProvider } from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.context';
import { CounterParty, CounterPartyById } from 'types/beneficiary-v2';
import Avatar from 'components/avatar/Avatar';

type SelectRecipientWrapperProps = {
  selectedCounterParty?: CounterParty;
  selectedCounterPartyById?: CounterPartyById;
  setSelectedCounterPartyById?: (counterParty?: CounterPartyById) => void;
  setSelectedCounterParty?: (counterParty?: CounterParty) => void;
  onRecipientAccountSelect: (args: any) => void;
  searchClassName?: string;
  title?: string;
  cardWrapperClassName?: string;
  beneIconColor?: string;
  destCurrencyCode?: string;
  networkCode?: string;
};
const SelectRecipientWrapper: FC<SelectRecipientWrapperProps> = ({
  onRecipientAccountSelect,
  selectedCounterParty,
  setSelectedCounterParty,
  searchClassName = 'tw-bg-GRAY_2',
  title = 'Paying to',
  cardWrapperClassName,
  beneIconColor,
  setSelectedCounterPartyById,
  destCurrencyCode,
  networkCode,
}) => (
  <ThirdPartyPayoutStateProvider>
    <div className='tw-cursor-pointer'>
      {!selectedCounterParty && <div className='f-12-400 tw-text-TEXT_TERTIARY tw-mb-2'>{title}</div>}
      {!selectedCounterParty && (
        <SelectCounterParty
          setSelectedCounterParty={setSelectedCounterParty}
          searchClassName={searchClassName}
          setSelectedCounterPartyById={setSelectedCounterPartyById}
          destCurrencyCode={destCurrencyCode}
          networkCode={networkCode}
        />
      )}
      {!!selectedCounterParty && (
        <div>
          <SelectedAccount
            wrapperStyle='tw-mb-3'
            labelProps={{
              title: title,
            }}
            cardProps={{
              customIcon: <Avatar name={selectedCounterParty?.name} className='tw-mr-3 !tw-min-w-[24px] !tw-h-6' />,
              title: selectedCounterParty?.name,
              onClick: () => setSelectedCounterParty?.(undefined),
            }}
            cardWrapperClassName={cardWrapperClassName}
          />
          <div className='tw-mb-2 tw-text-TEXT_TERTIARY f-12-400'>Select an account/wallet</div>
          <SelectBankAccountWallet
            selectedCounterParty={selectedCounterParty}
            onRecipientAccountSelect={onRecipientAccountSelect}
            searchClassName={searchClassName}
            beneIconColor={beneIconColor}
            destCurrencyCode={destCurrencyCode}
            networkCode={networkCode}
          />
        </div>
      )}
    </div>
  </ThirdPartyPayoutStateProvider>
);

export default SelectRecipientWrapper;
