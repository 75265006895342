import { FC } from 'react';
import { Label } from 'destiny/dist/components/molecules/label';
import { OptionsType } from 'destiny/dist/types/dropdown';

const InstructionRow: FC<OptionsType> = ({ label, value, id }) => (
  <div className='tw-flex'>
    <div className='tw-h-12 tw-min-w-[48px] tw-bg-BASE_PRIMARY tw-rounded-full tw-py-[14px] f-16-400 tw-mr-8 tw-text-center'>
      {id}
    </div>
    <Label title={label as string} titleClass='f-14-400 tw-text-TEXT_PRIMARY tw-mb-1' description={value} />
  </div>
);

export default InstructionRow;
