import { useAppSelector } from 'hooks/toolkit';
import { RootState } from 'store';
import { checkNullOrUndefined } from 'utils/common';

export enum CONFIG_DEFINITIONS {
  ADD_ACCOUNT,
  ADD_RECIPIENTS,
  IS_INDIVIDUAL,
  LINK_ACCOUNT_ENABLED,
  INVITE_TEAM,
  POLICIES_ENABLED,
  THIRD_PARTY_CRYPTO_PAYINS_ENABLED,
  THIRD_PARTY_FIAT_PAYINS_ENABLED,
}

const checkTruthyValue = (value: boolean) => {
  return checkNullOrUndefined(value) || (typeof value === 'boolean' && value);
};

const useConfiguration = () => {
  const { configuration, user } = useAppSelector((state: RootState) => state.user);

  const checkConfigEnabled = (accessType: CONFIG_DEFINITIONS) => {
    switch (accessType) {
      case CONFIG_DEFINITIONS.ADD_ACCOUNT:
        return checkTruthyValue(configuration?.isAddAccountEnabled);
      case CONFIG_DEFINITIONS.IS_INDIVIDUAL:
        return configuration?.isIndividual ?? false;
      case CONFIG_DEFINITIONS.ADD_RECIPIENTS:
        return checkTruthyValue(configuration?.isAddRecipientEnabled);
      case CONFIG_DEFINITIONS.INVITE_TEAM:
        return checkTruthyValue(configuration?.canInviteTeam);
      case CONFIG_DEFINITIONS.LINK_ACCOUNT_ENABLED:
        return (
          !configuration?.accountLinkingWhitelistedUserList ||
          configuration?.accountLinkingWhitelistedUserList?.includes(user?.id)
        );
      case CONFIG_DEFINITIONS.POLICIES_ENABLED:
        return !configuration?.isPoliciesDisabled;
      case CONFIG_DEFINITIONS.THIRD_PARTY_CRYPTO_PAYINS_ENABLED:
        return !configuration?.isThirdPartyCryptoPayinsDisabled;
        case CONFIG_DEFINITIONS.THIRD_PARTY_FIAT_PAYINS_ENABLED:
          return configuration?.isThirdPartyFiatPayinsDisabled;
      default:
        true;
    }
  };

  return { checkConfigEnabled };
};

export default useConfiguration;
