import { FC } from 'react';
import ThirdPartyPayouts from 'modules/single-transfer/thirdPartyPayouts/ThirdPartyPayouts';
import { BeneficiaryType } from 'types/beneficiary';
import { CounterParty } from 'types/beneficiary-v2';

type SingleTransferProps = {
  onClose: (param: null) => void;
  beneficiary?: BeneficiaryType | null;
  selectedSourceAccountData?: {
    entityId: string;
    vaultId: string;
    accountId: string;
  };
  className?: string;
  counterParty?: CounterParty;
};

const SingleTransfer: FC<SingleTransferProps> = ({ beneficiary, onClose, selectedSourceAccountData, counterParty }) => {
  const handleClose = () => onClose(null);

  return (
    <ThirdPartyPayouts
      onClose={handleClose}
      recipient={beneficiary}
      selectedSourceAccountData={selectedSourceAccountData}
      counterParty={counterParty}
    />
  );
};

export default SingleTransfer;
