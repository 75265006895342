import { type FC } from 'react';
import { useMakePayoutRequestMutation } from 'api/moveMoney';
import { SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { useAppSelector } from 'hooks/toolkit';
import { useSessionToken } from 'hooks/useSessionToken';
import NotesAndAttachments, {
  NotesAndAttachmentsProps,
} from 'modules/single-transfer/thirdPartyPayouts/steps/NotesAndAttachments';
import { RootState } from 'store';
import { UploadFileResponseType } from 'types';
import { ApiErrorResponse } from 'types/api';
import { PendingPayoutTypes } from 'types/approval';

const ExecuteTransfer: FC<NotesAndAttachmentsProps> = (props) => {
  const [makePayoutRequest, { isLoading: isRequestPayoutLoading }] = useMakePayoutRequestMutation();
  const { sessionToken, createSessionToken } = useSessionToken(
    SESSION_CAPABILITY_CONTEXT_KEYS.REQUEST_PAYOUTS_TRANSACTION
  );

  const { currencyCodeAndNameMap } = useAppSelector((state: RootState) => state.config);

  const handleRequestPayout = (
    comment: string,
    selectedFiles: UploadFileResponseType[],
    totp: string,
    handleError: (e: ApiErrorResponse) => void
  ) => {
    makePayoutRequest({
      data: {
        type: PendingPayoutTypes.SINGLE_PAYOUT,
        request_details: JSON.stringify({
          ...props?.quotePayload,
          comments: comment,
          should_sync: false,
          chart_of_accounts_id: undefined,
          computed_source_amount: props?.data?.source_amount,
          documents: selectedFiles?.map((file) => ({
            document_name: file.identifier,
            file_extension: file.identifier.split('.').pop(),
          })),
        }),
      },
      accountId: props?.selectedSourceAccount?.id,
      totp: totp,
      sessionToken: sessionToken ?? '',
    })
      .unwrap()
      .then(() => {
        props?.onSuccess?.({
          amount: props?.data?.source_amount,
          currency: props?.selectedSourceCurrency ? currencyCodeAndNameMap[props?.selectedSourceCurrency?.code] : '',
        });
      })
      .catch((e) => {
        handleError(e);
      })
      .finally(() => {
        createSessionToken();
      });
  };

  return (
    <NotesAndAttachments
      {...props}
      onRequestPayout={handleRequestPayout}
      isRequestPayoutLoading={isRequestPayoutLoading}
    />
  );
};

export default ExecuteTransfer;
