import { FC, useState } from 'react';
import { X_CLOSE } from 'constants/icons';
import { ProgressLine } from 'destiny/dist/components/molecules/progressLine';
import { BULK_PAYMENT_STAGES, PROGRESS_LINE_LIST } from 'modules/bulk-payment/BulkPayment.constants';
import BulkPaymentPay from 'modules/bulk-payment/BulkPaymentPay';
import BulkPaymentReview from 'modules/bulk-payment/BulkPaymentReview';
import BulkPaymentUpload from 'modules/bulk-payment/BulkPaymentUpload';
import { trackMixpanel } from 'utils/mixpanel';
import FullScreenPopup from 'components/popup/FullScreenPopup';

const BulkPayment: FC<{ onClose: (param: boolean) => void }> = ({ onClose }) => {
  const [currentStage, setCurrentStage] = useState<BULK_PAYMENT_STAGES>(BULK_PAYMENT_STAGES.UPLOAD);
  const [disableClose, setDisableClose] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClose = () => {
    !disableClose && onClose(false);
  };

  const getComponent = () => {
    switch (currentStage) {
      case BULK_PAYMENT_STAGES.UPLOAD:
        return <BulkPaymentUpload setCurrentStage={setCurrentStage} setDisableClose={setDisableClose} />;
      case BULK_PAYMENT_STAGES.REVIEW:
        return <BulkPaymentReview setCurrentStage={setCurrentStage} />;
      case BULK_PAYMENT_STAGES.PAY:
        return (
          <BulkPaymentPay
            setCurrentStage={setCurrentStage}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
            handleClose={handleClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    <FullScreenPopup
      onClose={handleClose}
      logoWrapperClassName='tw-w-20 tw-h-5 tw-absolute tw-top-8 tw-left-10'
      logoDimensions={{ width: 80, height: 20 }}
      closeWrapperClassName='tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-GRAY_2 tw-w-8 tw-h-8 tw-fixed tw-top-6 tw-right-6'
      closeIconSrc={X_CLOSE}
      closeIconDimensions={{ width: 16, height: 16 }}
      bodyClassName='tw-mt-10'
    >
      {!isError && !isSuccess && (
        <ProgressLine
          list={PROGRESS_LINE_LIST}
          selectedIndex={currentStage}
          id='BULK_PAYMENTS_PROGRESS_LINE'
          eventCallback={trackMixpanel}
          className='tw-my-1 tw-w-[540px]'
        />
      )}
      {getComponent()}
    </FullScreenPopup>
  );
};

export default BulkPayment;
