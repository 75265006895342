import { OptionsType } from 'destiny/dist/types/dropdown';
import ArrowUpRight from 'components/icons/ArrowUpRight';
import BulkTransferArrow from 'components/icons/BulkTransferArrow';
import InternalTransferArrow from 'components/icons/InternalTransferArrow';

export const MOVE_MONEY_VALUES = {
  SINGLE: 'SINGLE',
  SELF: 'SELF',
  BULK: 'BULK',
};

export const MOVE_MONEY_OPTIONS: OptionsType[] = [
  {
    label: 'Single Transfer',
    value: MOVE_MONEY_VALUES.SINGLE,
    icon: <ArrowUpRight />,
  },
  {
    label: 'Self Transfer',
    value: MOVE_MONEY_VALUES.SELF,
    icon: <InternalTransferArrow />,
  },
  {
    label: 'Bulk Transfer',
    value: MOVE_MONEY_VALUES.BULK,
    icon: <BulkTransferArrow />,
  },
];
