import { FC } from 'react';
import { defaultFn } from 'destiny/dist/constants/index';
import AccountSelector from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/AccountSelector';
import { AccountByVaultIdType, VaultByEntityIdType } from 'types/accountsApi.types';
import { InternalTransferAccountType } from 'types/internalTransferApi.types';

interface AccountsListProps {
  vaults?: VaultByEntityIdType[];
  accountsList?: InternalTransferAccountType[];
  onAccountSelect: (payload: AccountByVaultIdType) => void;
}

const AccountsList: FC<AccountsListProps> = ({ vaults = [], accountsList, onAccountSelect = defaultFn }) => {
  const accounts =
    accountsList ??
    vaults?.reduce((acc: AccountByVaultIdType[], vault: VaultByEntityIdType) => {
      if (vault?.accounts?.length > 0) acc = acc.concat(vault?.accounts);

      return acc;
    }, []) ??
    [];

  return (
    <>
      {accounts?.map(
        (account, index) =>
          account && (
            <AccountSelector account={account} key={index} onAccountSelect={onAccountSelect} id={index?.toString()} />
          )
      )}
    </>
  );
};

export default AccountsList;
