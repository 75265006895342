import { useState } from 'react';
import { useInitiateTotpMutation } from 'api/mfa';
import { ROUTES_PATH } from 'constants/routeConfig';
import { useAppDispatch } from 'hooks/toolkit';
import { useRouter } from 'next/router';
import { setMfaInitiateTotpData } from 'store/slices/mfa';

export const useEnableMFA = (redirect?: boolean) => {
  const router = useRouter();

  const dispatch = useAppDispatch();

  const [initiateTotp] = useInitiateTotpMutation({});

  const [loading, setLoading] = useState(false);

  const handleEnableMFA = async () => {
    try {
      setLoading(true);

      const result = await initiateTotp()?.unwrap();

      setLoading(false);

      dispatch(setMfaInitiateTotpData(result));
      const redirectPath = `${ROUTES_PATH.MFA + (redirect ? `?redirect=${redirect}` : '')}`;

      router.push(redirectPath);
    } catch (e) {
      setLoading(false);
      console.warn(e);
    }
  };

  return { loading, handleEnableMFA };
};
