import { FC } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';

const InfoBox: FC<{ id: string; text?: string; className?: string }> = ({ id, text, className }) => (
  <>
    {!!text && (
      <div className={`tw-flex tw-px-4 tw-py-3 tw-bg-BASE_PRIMARY tw-rounded-2.5 tw-my-3 ${className}`}>
        <SvgSpriteLoader
          id='info-circle'
          iconCategory={ICON_SPRITE_TYPES.GENERAL}
          width={13}
          height={13}
          className='tw-mr-2'
        />
        <Text textClass='f-12-300 tw-text-TEXT_SECONDARY' id={`info-box-${id}`}>
          {text}
        </Text>
      </div>
    )}
  </>
);

export default InfoBox;
