import { FC } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';
import { defaultFn } from 'destiny/dist/constants/index';
import AccountBalance, { AccountBalanceProps } from 'modules/accounts-mea/components/AccountBalance';
import MaskedAccountNumber, { MaskedAccountNumberProps } from 'modules/accounts-mea/components/MaskedAccountNumber';
import { AccountByVaultIdType } from 'types/accountsApi.types';
import Currency from 'components/currency/Currency';
import { ICON_TYPE } from 'components/currency/types';

interface AccountSelectorProps {
  account: AccountByVaultIdType;
  onAccountSelect?: (payload: AccountByVaultIdType) => void;
  showIcon?: boolean;
  wrapperClassName?: string;
  accountTextWrapperClassName?: string;
  accountNameClassName?: string;
  maskedAccountNumberProps?: MaskedAccountNumberProps;
  accountBalanceProps?: AccountBalanceProps;
  accountBalanceWrapper?: string;
  id?: string;
}

const AccountSelector: FC<AccountSelectorProps> = ({
  account,
  onAccountSelect = defaultFn,
  showIcon = true,
  wrapperClassName = 'tw-flex tw-items-center tw-px-6 tw-py-4 tw-cursor-pointer hover:tw-bg-BASE_SECONDARY',
  accountTextWrapperClassName = '',
  accountNameClassName = 'tw-text-TEXT_PRIMARY tw-mb-1 f-14-400 tw-truncate',
  maskedAccountNumberProps = {},
  accountBalanceProps = {},
  accountBalanceWrapper = 'tw-flex tw-flex-col tw-justify-center tw-ml-auto tw-min-w-[106px] tw-max-w-[106px]',
  id = '',
}) => {
  const { name, display_name, masked_number, masked_account_number, balance = '', currency_code } = account || {};

  const handleAccountSelect = () => onAccountSelect(account);

  return (
    <div
      className={wrapperClassName}
      role='presentation'
      onClick={handleAccountSelect}
      data-testid={`account-selector-${id}`}
    >
      {showIcon && <Currency code={currency_code} showName={false} iconSize={ICON_TYPE.LARGE} hideTooltip />}
      <div
        className={`tw-flex tw-flex-col tw-justify-center ${
          balance ? 'tw-w-[calc(100%-170px)]' : 'tw-w-[calc(100%-48px)]'
        } ${accountTextWrapperClassName}`}
      >
        <Text textClass={accountNameClassName}>{name ?? display_name ?? ''}</Text>
        {(!!masked_number || !!masked_account_number) && (
          <MaskedAccountNumber
            maskClassName='tw-min-w-[2.5px] tw-w-[2.5px] tw-h-[2.5px] tw-bg-TEXT_PRIMARY'
            textClassName='f-12-300 tw-text-TEXT_PRIMARY tw-truncate'
            {...maskedAccountNumberProps}
            maskedAccountNumber={masked_number ?? masked_account_number ?? ''}
          />
        )}
      </div>
      {!!balance && (
        <div className={accountBalanceWrapper}>
          <AccountBalance
            headerText='Estimated balance'
            wrapperClassName='tw-flex tw-flex-col tw-gap-1 tw-justify-center tw-ml-auto tw-min-w-[106px] tw-max-w-[106px]'
            headerClassName='tw-text-TEXT_SECONDARY f-12-300 tw-truncate'
            balanceClassName='tw-text-TEXT_SECONDARY f-12-300 tw-truncate'
            {...accountBalanceProps}
            data={{ balance, currency_code }}
          />
        </div>
      )}
    </div>
  );
};

export default AccountSelector;
