import { ROMA_LOGO, ROMA_LOGO_GREEN, ZAMP_LOGO } from 'constants/icons';
import { OTC } from 'constants/index';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import mixpanelEvents from 'constants/mixpanel';
import { ROUTES_PATH } from 'constants/routeConfig';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { NavigationAdditionalPropertiesSchema } from 'types/config';
import { isRomaDashboard } from 'utils/common';
import { getVariation } from 'utils/launchDarkly';
import { trackMixpanel } from 'utils/mixpanel';
import MoveMoney from 'components/move-money';

interface DashboardTopbarProps {
  config?: NavigationAdditionalPropertiesSchema;
}

const DashboardTopbar: React.FC<DashboardTopbarProps> = ({
  config = {
    is_move_money: false,
    is_contact_us: true,
    is_help: false,
    is_notifications: false,
    is_faq: false,
  },
}) => {
  const router = useRouter();

  const isRoma = isRomaDashboard();
  const isOtcEnabled = getVariation(LAUNCH_DARKLY_FLAGS.OTC);

  return (
    <>
      <div
        className={`tw-sticky tw-top-0 tw-w-full tw-flex tw-items-center  tw-z-50 ${
          isOtcEnabled
            ? 'tw-py-2 tw-pl-5 tw-pr-10 tw-bg-white'
            : 'tw-h-topbar tw-pl-[50px] tw-pr-[70px] tw-bg-BASE_SECONDARY'
        }`}
        data-testid='topbar'
      >
        <Link href={ROUTES_PATH.HOME}>
          {isOtcEnabled ? (
            <Image
              width={66}
              height={16}
              alt={'roma logo'}
              className='tw-w-[66px] tw-align-middle'
              src={ROMA_LOGO_GREEN}
              priority={true}
            />
          ) : (
            <Image
              width={92}
              height={22}
              alt={'zamp logo'}
              className='tw-w-[92px] tw-align-middle'
              src={isRoma ? ROMA_LOGO : ZAMP_LOGO}
              priority={true}
            />
          )}
        </Link>
        <div className='tw-flex-grow'></div>
        {config.is_move_money && !router.pathname?.includes(OTC) && <MoveMoney />}
        {config.is_help && (
          <Link href={ROUTES_PATH.HELP} onClick={() => trackMixpanel(mixpanelEvents.HELP_CLICK, {})}>
            <span
              className={`tw-text-sm tw-ml-6 ${
                router.asPath == ROUTES_PATH.HELP ? 'tw-text-ZAMP_PRIMARY tw-font-medium' : 'tw-text-TEXT_GRAY_1'
              }`}
            >
              Help
            </span>
          </Link>
        )}
        {config.is_contact_us && (
          <Link href={ROUTES_PATH.CONTACT_US}>
            <span
              className={`tw-text-sm tw-ml-6 ${
                router.asPath == ROUTES_PATH.CONTACT_US ? 'tw-text-ZAMP_PRIMARY tw-font-medium' : 'tw-text-TEXT_GRAY_1'
              }`}
            >
              Contact us
            </span>
          </Link>
        )}
        {config.is_faq && (
          <Link href={ROUTES_PATH.FAQ}>
            <span
              className={`tw-text-sm tw-ml-6 ${
                router.asPath == ROUTES_PATH.FAQ ? 'tw-text-ZAMP_PRIMARY tw-font-medium' : 'tw-text-TEXT_GRAY_1'
              }`}
            >
              FAQs
            </span>
          </Link>
        )}
      </div>
    </>
  );
};

export default DashboardTopbar;
