import { type FC } from 'react';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import { getVariation } from 'utils/launchDarkly';
import SidebarProfileSection, { SidebarProfileSectionProps } from 'components/layout/SidebarProfileSection';
import SidebarProfileSectionOtc, { SidebarProfileSectionOtcProps } from 'components/layout/SidebarProfileSectionOtc';

interface SidebarProfileWrapperProps extends SidebarProfileSectionOtcProps, SidebarProfileSectionProps {}

const SidebarProfileWrapper: FC<SidebarProfileWrapperProps> = (props) => {
  const isOtcEnabled = getVariation(LAUNCH_DARKLY_FLAGS.OTC);

  return isOtcEnabled ? <SidebarProfileSectionOtc {...props} /> : <SidebarProfileSection {...props} />;
};

export default SidebarProfileWrapper;
