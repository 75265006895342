import { FC, useState } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { defaultFn } from 'destiny/dist/constants/index';
import SelectorListItem from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/accountsByVaultsByEntitySelector/SelectorListItem';
import AccountSelector from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/AccountSelector';
import { defaultFnType } from 'types';
import { AccountByVaultIdType, EntityResponseType } from 'types/accountsApi.types';
import Popup from 'components/popup/Popup';

interface AccountsByVaultsByEntitySelectorProps {
  entities: EntityResponseType[];
  onAccountSelect: (payload: AccountByVaultIdType) => void;
  onClose: defaultFnType;
}

const selectorListItemWrapperClassName =
  'tw-basis-1/3 tw-flex tw-flex-col tw-max-h-[448px] tw-overflow-y-auto tw-no-scrollbar';

const AccountsByVaultsByEntitySelector: FC<AccountsByVaultsByEntitySelectorProps> = ({
  entities = [],
  onAccountSelect = defaultFn,
  onClose = defaultFn,
}) => {
  const [selectedEntity, setSelectedEntity] = useState(entities?.[0]);
  const [selectedVault, setSelectedVault] = useState(entities?.[0]?.vaults?.[0]);

  const handleAccountSelect = (selectedAccount: AccountByVaultIdType) => {
    onAccountSelect({ ...selectedAccount, parentEntity: selectedEntity });
  };

  return (
    <Popup isOpen handleVisibility={onClose}>
      <div className='tw-w-[756px] tw-min-w-[756px] tw-max-h-[510px] tw-overflow-hidden tw-bg-white tw-rounded-2.5 tw-border tw-border-DIVIDER_GRAY tw-shadow-menu'>
        <div className='tw-w-full tw-flex tw-justify-between tw-items-center tw-px-6 tw-py-8'>
          <Text textClass='f-18-500 tw-text-TEXT_PRIMARY'>Paying From</Text>
          <SvgSpriteLoader
            id='x-close'
            iconCategory={ICON_SPRITE_TYPES.GENERAL}
            width={24}
            height={24}
            onClick={onClose}
            className='tw-cursor-pointer'
          />
        </div>
        <div className='tw-w-full tw-flex tw-flex-row tw-gap-0.5 tw-p-3'>
          <div className={selectorListItemWrapperClassName}>
            {entities?.map((entity, index) => (
              <SelectorListItem
                selected={entity?.id === selectedEntity?.id}
                key={index}
                onClick={() => setSelectedEntity(entity)}
              >
                {entity?.name}
              </SelectorListItem>
            ))}
          </div>
          <div className={selectorListItemWrapperClassName}>
            {selectedEntity?.vaults?.map((vault, index) => (
              <SelectorListItem
                selected={vault?.id === selectedVault?.id}
                key={index}
                onClick={() => setSelectedVault(vault)}
              >
                {vault?.name}
              </SelectorListItem>
            ))}
          </div>
          <div className={selectorListItemWrapperClassName}>
            {selectedVault?.accounts?.map((account, index) => (
              <SelectorListItem
                key={index}
                onClick={() => handleAccountSelect(account)}
                className='tw-w-full'
                wrapperClassName='!tw-py-3'
              >
                <AccountSelector
                  account={account}
                  showIcon={false}
                  accountTextWrapperClassName='!tw-w-full !tw-flex-row !tw-justify-start tw-gap-2 tw-mb-0.5'
                  accountNameClassName='f-12-400 tw-text-TEXT_PRIMARY tw-truncate'
                  accountBalanceProps={{
                    headerText: 'Estimated balance:',
                    wrapperClassName: 'tw-flex tw-flex-row tw-items-center tw-gap-0.5',
                    headerClassName: 'tw-text-TEXT_TERTIARY f-12-300 tw-truncate',
                    balanceClassName: 'tw-text-TEXT_TERTIARY f-12-300 tw-truncate',
                  }}
                  accountBalanceWrapper=''
                  wrapperClassName='tw-w-full'
                />
              </SelectorListItem>
            ))}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default AccountsByVaultsByEntitySelector;
