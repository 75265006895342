import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useInternalTransferQuoteMutation } from 'api/internal-transfer';
import { X_CLOSE } from 'constants/icons';
import { FIAT_QUOTE_EXPIRY_TIME } from 'constants/transfer';
import { Text } from 'destiny/dist/components/atoms/text';
import {
  INTERNAL_TRANSFER_STEP_TITLE as STEP_TITLE,
  INTERNAL_TRANSFER_STEPS_TYPES as STEPS_TYPES,
} from 'modules/single-transfer/internalTransferV2/internalTransfer.constants';
import SelectAccounts from 'modules/single-transfer/internalTransferV2/steps/SelectAccounts';
import StepwiseView from 'modules/single-transfer/internalTransferV2/steps/StepwiseView';
import SelectedAccounts from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedAccounts';
import PaymentInitiated from 'modules/single-transfer/thirdPartyPayouts/steps/PaymentInitiated';
import {
  API_FAIL_ERROR_MESSAGE,
  MOVE_MONEY_ERROR_CODES,
} from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.constants';
import { MapAny } from 'types';
import { ApiErrorResponse } from 'types/api';
import { InternalTransferRFQQuoteRequest, InternalTransferToEntityType } from 'types/internalTransferApi.types';
import { CompletedInfoParams } from 'types/transactions';
import { ErrorCardTypes } from 'components/banners/types';
import FullScreenPopup from 'components/popup/FullScreenPopup';
import CommonWrapper from 'components/wrappers/CommonWrapper';

interface InternalTransferProps {
  recipientEntityAccounts?: InternalTransferToEntityType[];
  onClose: Dispatch<SetStateAction<boolean>>;
}

const InternalTransfer: FC<InternalTransferProps> = ({ recipientEntityAccounts, onClose }) => {
  const [currentStep, setCurrentStep] = useState<number>(STEPS_TYPES.TRANSFER_DETAILS);
  const [stepData, setStepData] = useState<MapAny>({});
  const [selectedRecipientAccount, setSelectedRecipientAccount] = useState<MapAny>();
  const [selectedSourceAccount, setSelectedSourceAccount] = useState<MapAny>();
  const [transferDetails, setTransferDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [successData, setSuccessData] = useState<CompletedInfoParams>();

  const [rfqPayload, setRfqPayload] = useState<InternalTransferRFQQuoteRequest>({
    corridor_id: '',
    source_account_id: '',
    dest_account_id: '',
    source_amount: 0,
    receiving_amount: 0,
  });
  const [isRfqAutoUpdated, setRfqAutoUpdated] = useState<boolean>(false);
  const [
    createRfq,
    { data: rfqDetails, isLoading: rfqLoading, isUninitialized: rfqUninitialized, error: rfqError, reset: resetRfq },
  ] = useInternalTransferQuoteMutation();

  const rfqTimer = useRef<ReturnType<typeof setTimeout>>();

  const clearRfqTimer = () => {
    if (rfqTimer) clearTimeout(rfqTimer.current);
  };

  const handleRfqTimer = (payload: InternalTransferRFQQuoteRequest) => {
    clearRfqTimer();

    const timeOut = setTimeout(async () => {
      createRfq(payload)
        .unwrap()
        .then(() => setRfqAutoUpdated(true))
        .catch((e) => console.log(e));
    }, FIAT_QUOTE_EXPIRY_TIME);

    rfqTimer.current = timeOut;
  };

  useEffect(() => {
    setRfqAutoUpdated(false);
  }, [rfqPayload]);

  useEffect(() => {
    if (rfqDetails?.id && rfqPayload) handleRfqTimer(rfqPayload);
  }, [rfqDetails, rfqPayload]);

  const handleRecipientAccountSelect = (payload: MapAny) => {
    setSelectedRecipientAccount(payload);
    resetRfq();
  };

  const handleSourceAccountSelect = (payload?: MapAny) => {
    setSelectedSourceAccount(payload);
    resetRfq();
  };

  const handleAccountEdit = (isRecipientEdit: boolean) => {
    setStepData({ isRecipientEdit, isSourceEdit: !isRecipientEdit });
    setCurrentStep(STEPS_TYPES.TRANSFER_DETAILS);
  };

  const handlePreviousStep = () => {
    const newStep = currentStep - 1;

    setCurrentStep(newStep);
    setStepData({});
  };

  const handleNextStep = (stepData?: any) => {
    const newStep = currentStep + 1;

    if (currentStep === STEPS_TYPES.TRANSFER_DETAILS) setTransferDetails(stepData);

    setCurrentStep(newStep);
    setStepData({});
  };

  const handleGoToStep = (goToStep: number) => {
    setCurrentStep(goToStep);
    setStepData({});
  };

  const handleSuccess = (data: CompletedInfoParams) => {
    setSuccessData(data);
    setIsSuccess(true);
  };

  const handleError = (e?: ApiErrorResponse) => {
    let errorMessageText = API_FAIL_ERROR_MESSAGE;

    if (e?.data?.error) {
      const { code, message } = e.data.error;

      if (code === MOVE_MONEY_ERROR_CODES.INSUFFICIENT_GAS_BALANCE) errorMessageText = message;
    }
    setErrorMessage(errorMessageText);
    setIsError(true);
  };

  const handleRetryOnError = () => {
    setIsError(false);
    handleGoToStep(STEPS_TYPES.TRANSFER_DETAILS);
  };

  const handleClose = () => onClose(false);

  return (
    <FullScreenPopup
      onClose={handleClose}
      logoWrapperClassName='tw-w-[78.03px] tw-h-[19px] tw-absolute tw-top-8 tw-left-[42px]'
      logoDimensions={{ width: 78.03, height: 19 }}
      closeWrapperClassName='tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-GRAY_2 tw-w-[33px] tw-h-[33px] tw-fixed tw-top-[25px] tw-right-[25px]'
      closeIconSrc={X_CLOSE}
      closeIconDimensions={{ width: 14.67, height: 14.67 }}
      bodyClassName={`tw-h-full ${isSuccess || isError ? 'tw-justify-center' : ''}`}
    >
      <div className='tw-w-[390px]'>
        <CommonWrapper
          isSuccess={isSuccess}
          successCard={
            <PaymentInitiated
              onClose={handleClose}
              recipientAccount={selectedRecipientAccount}
              sourceAccount={selectedSourceAccount}
              isRecipientAccount
              {...successData}
            />
          }
          isError={isError}
          errorCardType={ErrorCardTypes.PAYMENT_FAIL}
          errorCardProps={{
            subtitle: errorMessage,
            customWrapperClassName: '',
            customImageDimensions: { width: 144, height: 140 },
            customTitleClassName: 'f-20-400 tw-text-TEXT_PRIMARY tw-mt-2.5 tw-mb-8',
            customSubtitleClassName: 'f-16-300 tw-text-TEXT_TERTIARY',
          }}
          refetchFunnction={handleRetryOnError}
        >
          <div className='tw-mt-[75px] tw-mb-4'>
            <Text textClass='f-18-500 tw-text-TEXT_PRIMARY tw-mb-4'>{STEP_TITLE[currentStep]}</Text>

            {currentStep === STEPS_TYPES.TRANSFER_DETAILS && (
              <SelectAccounts
                recipientEntityAccounts={recipientEntityAccounts}
                recipientAccount={selectedRecipientAccount}
                sourceAccount={selectedSourceAccount}
                onRecipientSelect={handleRecipientAccountSelect}
                onSourceSelect={handleSourceAccountSelect}
                {...stepData}
              />
            )}

            {currentStep === STEPS_TYPES.REVIEW_AND_PAY && (
              <SelectedAccounts
                isSummary
                isRecipientAccount
                recipientAccount={selectedRecipientAccount}
                sourceAccount={selectedSourceAccount}
                recipientProps={{ isDropdown: false, labelProps: { title: 'Transfer to' } }}
                sourceProps={{ isDropdown: false, labelProps: { title: 'Transfer from' } }}
                onRecipientClick={() => handleAccountEdit(true)}
                onSourceClick={() => handleAccountEdit(false)}
              />
            )}

            <StepwiseView
              selectedRecipientAccount={selectedRecipientAccount}
              selectedSourceAccount={selectedSourceAccount}
              transferDetails={transferDetails}
              rfqData={{
                rfqDetails,
                rfqLoading,
                rfqError,
                rfqPayload,
                rfqUninitialized,
                setRfqPayload,
                clearRfqTimer,
                createRfq,
              }}
              summaryData={{
                quotePayload: rfqPayload,
                quoteId: rfqDetails?.id ?? '',
                isQuoteAutoUpdated: isRfqAutoUpdated,
              }}
              step={currentStep}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              handleSuccess={handleSuccess}
              handleError={handleError}
            />
          </div>
        </CommonWrapper>
      </div>
    </FullScreenPopup>
  );
};

export default InternalTransfer;
