import { CSSProperties, ReactElement, ReactNode } from 'react';

export enum CardType {
  INFO,
  ERROR,
  CUSTOM,
  AVATAR_WITH_TITLE_SUBTITLE,
  INLINE_TITLE_SUBTITLE,
}

export enum CardIconType {
  LARGE,
  MEDIUM,
  SMALL,
}

export type InfoErrorCardProps = {
  type?: CardType;
  className?: string;
  iconPath?: string;
  title?: string;
  subtitle?: string;
  iconSize?: CardIconType;
  subtitleClassName?: string;
  customSubtitleClassName?: string;
  textWrapperClassName?: string;
  titleClassName?: string;
  showDownIcon?: boolean;
  onClick?: () => void;
  showIcon?: boolean;
  textWrapperStyle?: CSSProperties;
  trailingSection?: ReactNode;
  customIcon?: ReactElement;
  customIconDimensions?: any;
  iconClassName?: string;
};
