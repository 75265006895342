import { FC, useEffect } from 'react';
import { useLazyGetAccountBalanceForAccountIdQuery } from 'api/moveMoney';
import { Text } from 'destiny/dist/components/atoms/text';
import { useAppSelector } from 'hooks/toolkit';
import { AccountBalanceSkeleton } from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/TransferDetailsSkeleton';
import { RootState } from 'store';
import { GetAccountBalanceForAccountIdResponse } from 'types/api';
import { SourceCurrency } from 'types/transactions';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

interface AcoountBalanceProps {
  sourceCurrency?: SourceCurrency;
  setAccountBalance: (data: GetAccountBalanceForAccountIdResponse) => void;
  isOtc?: boolean;
  skeletonClassName?: string;
}

const AccountBalance: FC<AcoountBalanceProps> = ({
  sourceCurrency,
  setAccountBalance,
  isOtc = false,
  skeletonClassName = '',
}) => {
  const [getAccountBalanceForAccountId, { data: accountBalance, isLoading: accountBalanceLoading }] =
    useLazyGetAccountBalanceForAccountIdQuery();

  const { currencyCodeAndNameMap } = useAppSelector((state: RootState) => state.config);

  useEffect(() => {
    const accountId = sourceCurrency?.account_id ?? sourceCurrency?.source_account_id ?? '';

    if (accountId) getAccountBalanceForAccountId({ account_id: accountId });
  }, [sourceCurrency]);

  useEffect(() => accountBalance && setAccountBalance(accountBalance), [accountBalance]);

  return (
    <CommonWrapper
      isLoading={accountBalanceLoading || !sourceCurrency || !accountBalance}
      skeletonType={SkeletonTypes.CUSTOM}
      skeleton={<AccountBalanceSkeleton className={skeletonClassName} />}
    >
      {sourceCurrency &&
        accountBalance &&
        (isOtc ? (
          <Text textClass='tw-text-GREEN_PRIMARY f-12-400 tw-mb-4 tw-py-1 tw-px-8 tw-text-center tw-bg-GREEN_BG tw-shadow-kybBanner tw-rounded'>
            {`Balance : ${accountBalance?.balance} ${currencyCodeAndNameMap[sourceCurrency?.code]} `}
          </Text>
        ) : (
          <Text textClass='tw-text-TEXT_TERTIARY f-12-300 tw-mb-4'>
            {`Available balance : ${currencyCodeAndNameMap[sourceCurrency?.code]} ${accountBalance?.balance}`}
          </Text>
        ))}
    </CommonWrapper>
  );
};

export default AccountBalance;
