export const PAYMENT_INITIATED = {
  v: '5.10.2',
  fr: 60,
  ip: 0,
  op: 319,
  w: 1080,
  h: 1080,
  nm: 'Payment Initated v2',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 14',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [697, 933, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 196, s: [0] },
                  { t: 212.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 199.6, s: [0] },
                  { t: 217, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 196,
      op: 234,
      st: 196,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Shape Layer 7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [697, 933, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 77, s: [0] },
                  { t: 93.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 80.6, s: [0] },
                  { t: 98, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 77,
      op: 115,
      st: 77,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Shape Layer 13',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [435, 805, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 174, s: [0] },
                  { t: 190.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 177.6, s: [0] },
                  { t: 195, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 174,
      op: 212,
      st: 174,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Shape Layer 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [435, 805, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 108, s: [0] },
                  { t: 124.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 111.6, s: [0] },
                  { t: 129, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 108,
      op: 146,
      st: 108,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Shape Layer 12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [522, 726, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 157, s: [0] },
                  { t: 173.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 160.6, s: [0] },
                  { t: 178, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 157,
      op: 195,
      st: 157,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Shape Layer 17',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [522, 726, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 258, s: [0] },
                  { t: 274.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 261.6, s: [0] },
                  { t: 279, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 258,
      op: 296,
      st: 258,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Shape Layer 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [522, 726, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 61, s: [0] },
                  { t: 77.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 64.6, s: [0] },
                  { t: 82, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 61,
      op: 99,
      st: 61,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Shape Layer 11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [540, 865, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 232, s: [0] },
                  { t: 248.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 235.6, s: [0] },
                  { t: 253, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 232,
      op: 270,
      st: 232,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Merged Shape Layer',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.7, y: 0.599 },
              o: { x: 0.375, y: 0 },
              t: 0,
              s: [540, 680, 0],
              to: [0, 0, 0],
              ti: [1, 21.333, 0],
            },
            {
              i: { x: 0.405, y: 0.892 },
              o: { x: 0.159, y: 0.369 },
              t: 20,
              s: [540.5, 561.5, 0],
              to: [-1.352, -28.838, 0],
              ti: [0, 0, 0],
            },
            { i: { x: 0.156, y: 1 }, o: { x: 0.3, y: 0.048 }, t: 44, s: [468, 540, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.557, y: 1 }, o: { x: 0.648, y: 0 }, t: 86, s: [630, 540, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { t: 138, s: [540, 540, 0] },
          ],
        },
        a: { a: 0, k: [541.984, 539.564, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Slider Control',
          np: 3,
          mn: 'ADBE Slider Control',
          ix: 1,
          en: 1,
          ef: [{ ty: 0, nm: 'Slider', mn: 'ADBE Slider Control-0001', ix: 1, v: { a: 0, k: 1.8 } }],
        },
        {
          ty: 5,
          nm: 'Slider Control 2',
          np: 3,
          mn: 'ADBE Slider Control',
          ix: 2,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: 'Slider',
              mn: 'ADBE Slider Control-0001',
              ix: 1,
              v: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 29, s: [0] },
                  { t: 63, s: [25] },
                ],
              },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [8.485, -6.451],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-8.574, -6.331],
                          ],
                          v: [
                            [-17.319, -68.991],
                            [-205.234, 73.886],
                            [205.234, 73.886],
                            [11.463, -69.193],
                          ],
                          c: true,
                        },
                      },
                      nm: 'Path 1',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [0.145098045468, 0.274509817362, 0.960784316063, 1] },
                      o: { a: 0, k: 100 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0] },
                      a: { a: 0, k: [0, 0] },
                      s: { a: 0, k: [100, 100] },
                      r: { a: 0, k: 0 },
                      o: { a: 0, k: 100 },
                      sk: { a: 0, k: 0 },
                      sa: { a: 0, k: 0 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Vector',
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [540.234, 421.927] },
                  a: { a: 0, k: [0, 73.812] },
                  s: {
                    a: 1,
                    k: [
                      { i: { x: [0.629, 0.648], y: [1, 1] }, o: { x: [0.5, 0.5], y: [0, 0] }, t: 10, s: [100, 100] },
                      { t: 35, s: [100, -100] },
                    ],
                  },
                  r: { a: 0, k: 0 },
                  o: {
                    a: 1,
                    k: [
                      { t: 10, s: [0], h: 1 },
                      { t: 23.310546875, s: [100], h: 1 },
                    ],
                  },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  nm: 'Transform',
                },
              ],
              nm: 'Flap 1',
              bm: 0,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [11.723, -9.524],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-11.757, -9.481],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [205.234, 78.441],
                            [19.428, -71.394],
                            [-20.837, -71.321],
                            [-205.234, 78.485],
                          ],
                          c: false,
                        },
                      },
                      nm: 'Path 1',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [0.86274510622, 0.878431379795, 0.886274516582, 1] },
                      o: { a: 0, k: 100 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0] },
                      a: { a: 0, k: [0, 0] },
                      s: { a: 0, k: [100, 100] },
                      r: { a: 0, k: 0 },
                      o: { a: 0, k: 100 },
                      sk: { a: 0, k: 0 },
                      sa: { a: 0, k: 0 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Vector',
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [540.234, 576.893] },
                  a: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  nm: 'Transform',
                },
              ],
              nm: 'Lower Flap',
              bm: 0,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [205.234, -117.637],
                            [205.234, 117.637],
                            [205.234, 115.738],
                            [-12.603, 115.738],
                            [-205.234, 115.738],
                            [-205.234, -117.637],
                            [-2.702, 36.036],
                          ],
                          c: true,
                        },
                      },
                      nm: 'Path 1',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [0.537254929543, 0.709803938866, 0.956862747669, 1] },
                      o: { a: 0, k: 100 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0] },
                      a: { a: 0, k: [0, 0] },
                      s: { a: 0, k: [100, 100] },
                      r: { a: 0, k: 0 },
                      o: { a: 0, k: 100 },
                      sk: { a: 0, k: 0 },
                      sa: { a: 0, k: 0 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Vector',
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [540.234, 539.637] },
                  a: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  nm: 'Transform',
                },
              ],
              nm: 'Light Blue Bg',
              bm: 0,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [375.372, 171.206] },
                      p: { a: 0, k: [0, 0] },
                      r: { a: 0, k: 0 },
                      nm: 'Rectangle Path 1',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [0.133333340287, 0.639215707779, 0.458823531866, 1] },
                      o: { a: 0, k: 100 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0] },
                      a: { a: 0, k: [0, 0] },
                      s: { a: 0, k: [100, 100] },
                      r: { a: 0, k: 0 },
                      o: { a: 0, k: 100 },
                      sk: { a: 0, k: 0 },
                      sa: { a: 0, k: 0 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Rectangle 7461',
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {
                    a: 1,
                    k: [
                      { i: { x: 0.5, y: 1 }, o: { x: 0.5, y: 0 }, t: 0, s: [540, 206.5], to: [0, 0], ti: [0, 0] },
                      { t: 28.484375, s: [540, 547.5] },
                    ],
                  },
                  a: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  nm: 'Transform',
                },
              ],
              nm: 'Money',
              bm: 0,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [8.485, -6.451],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-8.574, -6.331],
                          ],
                          v: [
                            [-17.319, -68.991],
                            [-205.234, 73.886],
                            [205.234, 73.886],
                            [11.463, -69.193],
                          ],
                          c: true,
                        },
                      },
                      nm: 'Path 1',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [0.145098045468, 0.274509817362, 0.960784316063, 1] },
                      o: { a: 0, k: 100 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0] },
                      a: { a: 0, k: [0, 0] },
                      s: { a: 0, k: [100, 100] },
                      r: { a: 0, k: 0 },
                      o: { a: 0, k: 100 },
                      sk: { a: 0, k: 0 },
                      sa: { a: 0, k: 0 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Vector',
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [540.234, 421.927] },
                  a: { a: 0, k: [0, 73.812] },
                  s: {
                    a: 1,
                    k: [
                      { i: { x: [0.629, 0.648], y: [1, 1] }, o: { x: [0.5, 0.5], y: [0, 0] }, t: 10, s: [100, 100] },
                      { t: 35, s: [100, -100] },
                    ],
                  },
                  r: { a: 0, k: 0 },
                  o: {
                    a: 1,
                    k: [
                      { t: 10, s: [100], h: 1 },
                      { t: 23.310546875, s: [0], h: 1 },
                    ],
                  },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  nm: 'Transform',
                },
              ],
              nm: 'Flap 2',
              bm: 0,
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-3.003, 76.88],
                            [-205.234, -76.88],
                            [205.234, -76.88],
                          ],
                          c: true,
                        },
                      },
                      nm: 'Path 1',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [0, 0, 0, 1] },
                      o: { a: 0, k: 100 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0] },
                      a: { a: 0, k: [0, 0] },
                      s: { a: 0, k: [100, 100] },
                      r: { a: 0, k: 0 },
                      o: { a: 0, k: 100 },
                      sk: { a: 0, k: 0 },
                      sa: { a: 0, k: 0 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Vector',
                  bm: 0,
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [540.234, 498.88] },
                  a: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  nm: 'Transform',
                },
              ],
              nm: 'Black Back',
              bm: 0,
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 1,
                k: [
                  { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 0, s: [537.978, 655.313], to: [0, 0], ti: [0, 0] },
                  {
                    i: { x: 0.833, y: 0.995 },
                    o: { x: 0.333, y: 0 },
                    t: 36,
                    s: [540.693, 655.81],
                    to: [19.442, -3.939],
                    ti: [-2.76, 2.458],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.008 },
                    t: 76,
                    s: [530.763, 652.114],
                    to: [0.665, -0.592],
                    ti: [2.322, -1.309],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 91,
                    s: [534.665, 653.25],
                    to: [-17.594, 9.917],
                    ti: [-0.549, 1.834],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 122,
                    s: [533.389, 663.771],
                    to: [1.767, -5.909],
                    ti: [-1.556, -4.1],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 146,
                    s: [522.586, 660.874],
                    to: [5.424, 14.287],
                    ti: [-2.912, 12.453],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.001 },
                    t: 178,
                    s: [540.238, 659.727],
                    to: [0.045, -0.194],
                    ti: [0.136, 0.077],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 184,
                    s: [541.173, 656.312],
                    to: [-1.382, -0.78],
                    ti: [-2.116, -1.366],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 200,
                    s: [539.812, 663.791],
                    to: [3.993, 2.578],
                    ti: [2.874, 2.941],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.001 },
                    t: 226,
                    s: [554.601, 651.831],
                    to: [-3.804, -3.893],
                    ti: [3.223, 10.411],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.004 },
                    t: 254,
                    s: [530.806, 652.527],
                    to: [-0.308, -0.996],
                    ti: [2.695, -1.402],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.003 },
                    t: 271,
                    s: [534.647, 653.218],
                    to: [-1.97, 1.025],
                    ti: [1.915, -1.144],
                  },
                  {
                    i: { x: 0.833, y: 0.995 },
                    o: { x: 0.167, y: 0.004 },
                    t: 281,
                    s: [528.533, 655.86],
                    to: [-2.137, 1.277],
                    ti: [-0.973, -2.405],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.006 },
                    t: 291,
                    s: [526.494, 661.712],
                    to: [2.892, 7.147],
                    ti: [12.675, 3.507],
                  },
                  { t: 318, s: [526.168, 658.243] },
                ],
              },
              a: { a: 0, k: [537.978, 655.313] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: {
                a: 1,
                k: [
                  { i: { x: [0], y: [1] }, o: { x: [0.395], y: [0] }, t: 44, s: [0] },
                  { t: 90, s: [18] },
                ],
              },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          bm: 0,
          hd: false,
        },
      ],
      ip: 0,
      op: 1800,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Shape Layer 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [540, 865, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 217, s: [0] },
                  { t: 233.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 220.6, s: [0] },
                  { t: 238, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 217,
      op: 255,
      st: 217,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Shape Layer 16',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [540, 651, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 279, s: [0] },
                  { t: 295.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 282.6, s: [0] },
                  { t: 300, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 279,
      op: 317,
      st: 279,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Shape Layer 10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [540, 651, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 186, s: [0] },
                  { t: 202.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 189.6, s: [0] },
                  { t: 207, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 186,
      op: 224,
      st: 186,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Shape Layer 15',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [540, 651, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 245, s: [0] },
                  { t: 261.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 248.6, s: [0] },
                  { t: 266, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 245,
      op: 283,
      st: 245,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Shape Layer 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [540, 651, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 152, s: [0] },
                  { t: 168.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 155.6, s: [0] },
                  { t: 173, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 152,
      op: 190,
      st: 152,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Shape Layer 9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [540, 594, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 118, s: [0] },
                  { t: 134.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 121.6, s: [0] },
                  { t: 139, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 118,
      op: 156,
      st: 118,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Shape Layer 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [540, 594, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 57, s: [0] },
                  { t: 73.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 60.6, s: [0] },
                  { t: 78, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 57,
      op: 95,
      st: 57,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Shape Layer 8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [540, 775, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 88, s: [0] },
                  { t: 104.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 91.6, s: [0] },
                  { t: 109, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 88,
      op: 126,
      st: 88,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [540, 775, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [357, -196],
                    [-375, -196],
                  ],
                  c: false,
                },
              },
              nm: 'Path 1',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 47, s: [0] },
                  { t: 63.1875, s: [100] },
                ],
              },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 50.6, s: [0] },
                  { t: 68, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
              nm: 'Trim Paths 1',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 16 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          bm: 0,
          hd: false,
        },
      ],
      ip: 47,
      op: 85,
      st: 47,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
};
