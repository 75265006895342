import { FC } from 'react';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip/index';
import { COLORS } from 'destiny/dist/constants/colors';
import { TooltipPositions } from 'destiny/dist/constants/molecules/tooltip';
import { MapAny } from 'types';
import Currency from 'components/currency/Currency';

const BulkPaymentReviewDataCell: FC<{ data: MapAny }> = ({ data }) => {
  const getComponent = () => {
    switch (data?.column_name) {
      case 'SourceCurrency':
      case 'DestinationCurrency':
        return data?.is_invalid ? (
          <div className='f-14-300 tw-text-RED_PRIMARY'>{data?.value}</div>
        ) : (
          <Currency
            code={data?.value}
            tooltipBodyClassName='!tw-p-2.5'
            tooltipClassName='!tw-bottom-3 -tw-ml-2 tw-z-1'
            tooltipContentClassName='!tw-mb-0.5 last:!tw-mb-0'
          />
        );
      default:
        return <div className={`f-14-300 ${data?.is_invalid ? 'tw-text-RED_PRIMARY' : ''}`}>{data?.value}</div>;
    }
  };

  return (
    <div className='tw-w-fit'>
      <Tooltip
        position={TooltipPositions.RIGHT}
        tooltipBody={data?.error_message}
        disabled={!data?.error_message}
        tooltipBodystyle={`f-12-300 !tw-px-4 !tw-py-3 ${!data?.error_message ? '' : 'tw-min-w-[200px]'}`}
        color={COLORS.TEXT_PRIMARY}
      >
        {getComponent()}
      </Tooltip>
    </div>
  );
};

export default BulkPaymentReviewDataCell;
