import { type FC, useState } from 'react';
import { useUpdateBeneficiaryMutation } from 'api/beneficiary';
import { SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { Button } from 'destiny/dist/components/molecules/button';
import Input from 'destiny/dist/components/organisms/input';
import { defaultFnType } from 'destiny/dist/types';
import { useSessionToken } from 'hooks/useSessionToken';
import { Beneficiary } from 'types/beneficiary-v2';
import { PaymentMethod } from 'types/transactions';
import { trackMixpanel } from 'utils/mixpanel';
import { toast } from 'utils/toast';
import SideDrawer from 'components/SideDrawer';

type BeneficiaryBankAccountWalletEditProps = {
  isOpen: boolean;
  onClose: defaultFnType;
  data: Beneficiary;
};

const BeneficiaryBankAccountWalletEdit: FC<BeneficiaryBankAccountWalletEditProps> = ({ isOpen, onClose, data }) => {
  const [nickname, setNickname] = useState(data?.beneficiary_alias);
  const [updateBeneficiary, { isLoading }] = useUpdateBeneficiaryMutation();
  const { sessionToken, createSessionToken } = useSessionToken(SESSION_CAPABILITY_CONTEXT_KEYS.EDIT_COUNTERPARTY);

  const isFiat = data?.payout_method === PaymentMethod.FIAT;

  const handleSubmitClick = () => {
    updateBeneficiary({ id: data?.id, payload: { beneficiary_alias: nickname }, idempotencyHeader: sessionToken ?? '' })
      .unwrap()
      .then(() => {
        toast.success(`${isFiat ? 'Bank Account' : 'Wallet'} nickname updated successfully`);
        onClose();
      })
      .catch(() => {
        toast.error('Update failed. Please try again.');
        createSessionToken();
      });
  };

  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={onClose}
      bodyClassName='tw-w-sideDrawer tw-h-screen tw-bg-white tw-rounded-l-xl tw-overflow-hidden'
      hideCloseButton={true}
    >
      <div>
        <div className='tw-h-[72px] tw-p-5 tw-bg-BASE_SECONDARY tw-pl-8 tw-flex tw-items-center f-24-500'>
          Edit {isFiat ? 'Bank Account' : 'Wallet'}
        </div>
        <div className='tw-m-8'>
          <Input
            showLabel={true}
            labelProps={{ description: 'Nickname' }}
            inputFieldProps={{
              size: 'MEDIUM',
              inputTagProps: {
                onChange: (e) => setNickname(e.target.value),
                value: nickname,
                id: 'RECIPIENT_BANK_ACCOUNT_WALLET_EDIT_NICKNAME',
              },
            }}
          />
        </div>
        <div className='tw-mt-10 tw-mx-8'>
          <Button
            buttonProps={{
              id: 'RECIPIENT_BANK_ACCOUNT_WALLET_EDIT_SAVE',
              eventCallback: trackMixpanel,
              wrapperClass: 'tw-w-full',
              onClick: handleSubmitClick,
              disabled: !sessionToken,
              isLoading: isLoading,
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </SideDrawer>
  );
};

export default BeneficiaryBankAccountWalletEdit;
