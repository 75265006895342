import { type FC } from 'react';
import { MASKED_DOTS } from 'constants/index';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { PaymentMethod } from 'types/transactions';

const BeneficiaryBankAccountWalletNumberColumn: FC<{
  data: { beneficiary_account_number: string; payout_method: PaymentMethod };
}> = ({ data: { beneficiary_account_number, payout_method } }) => {
  return (
    <div className='tw-flex tw-items-center tw-gap-2.5'>
      {payout_method === PaymentMethod.FIAT ? (
        <SvgSpriteLoader id='building-08' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={20} height={20} />
      ) : (
        <SvgSpriteLoader id='wallet-04' iconCategory={ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE} width={20} height={20} />
      )}
      <div>
        {MASKED_DOTS}
        {beneficiary_account_number?.slice(-4)}
      </div>
    </div>
  );
};

export default BeneficiaryBankAccountWalletNumberColumn;
