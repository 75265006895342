import { FC } from 'react';
import { MapAny } from 'types';
import Currency from 'components/currency/Currency';

interface BeneficiaryCurrencyProps {
  data: MapAny;
}

const BeneficiaryCurrency: FC<BeneficiaryCurrencyProps> = ({ data }) => (
  <Currency code={data?.amount?.source_amount_currency?.symbol} />
);

export default BeneficiaryCurrency;
