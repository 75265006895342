import { FC } from 'react';
import { defaultFn } from 'destiny/dist/constants/index';
import { INTERNAL_TRANSFER_STEPS_TYPES as STEPS_TYPES } from 'modules/single-transfer/internalTransferV2/internalTransfer.constants';
import ReviewAndPay from 'modules/single-transfer/internalTransferV2/steps/ReviewAndPay';
import TransferDetailsWrapper from 'modules/single-transfer/internalTransferV2/steps/TransferDetailsWrapper';
import { defaultFnType } from 'types';
import { CompletedInfoParams } from 'types/transactions';

interface StepwiseViewProps {
  selectedRecipientAccount?: any;
  selectedSourceAccount?: any;
  transferDetails?: any;
  rfqData?: any;
  summaryData?: any;
  step: number;
  handleNextStep: defaultFnType;
  handlePreviousStep: defaultFnType;
  handleSuccess: (data: CompletedInfoParams) => void;
  handleError: defaultFnType;
}

const StepwiseView: FC<StepwiseViewProps> = ({
  selectedRecipientAccount = null,
  selectedSourceAccount = null,
  transferDetails = {},
  rfqData = {},
  summaryData = {},
  step,
  handleNextStep = defaultFn,
  handlePreviousStep = defaultFn,
  handleSuccess = defaultFn,
  handleError = defaultFn,
}) => {
  switch (step) {
    case STEPS_TYPES.TRANSFER_DETAILS:
      return (
        <TransferDetailsWrapper
          selectedRecipientAccount={selectedRecipientAccount}
          selectedSourceAccount={selectedSourceAccount}
          handleSubmit={handleNextStep}
          {...transferDetails}
          {...rfqData}
        />
      );
    case STEPS_TYPES.REVIEW_AND_PAY:
      return (
        <ReviewAndPay
          selectedRecipientAccount={selectedRecipientAccount}
          selectedSourceAccount={selectedSourceAccount}
          handleBack={handlePreviousStep}
          onSuccess={handleSuccess}
          onError={handleError}
          {...transferDetails}
          {...summaryData}
        />
      );

    default:
      return null;
  }
};

export default StepwiseView;
