import { FC, useEffect, useRef, useState } from 'react';
import { useCreateRfqMutation } from 'api/moveMoney';
import {
  THIRD_PARTY_PAYOUTS_STEP_TITLE as STEP_TITLE,
  THIRD_PARTY_PAYOUTS_STEPS_TYPES as STEPS_TYPES,
} from 'constants/beneficiary';
import { X_CLOSE } from 'constants/icons';
import { FIAT_QUOTE_EXPIRY_TIME } from 'constants/transfer';
import { defaultFn } from 'destiny/dist/constants/index';
import SelectedAccounts from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedAccounts';
import ExecuteTransfer from 'modules/single-transfer/thirdPartyPayouts/steps/ExecuteTransfer';
import PaymentInitiated from 'modules/single-transfer/thirdPartyPayouts/steps/PaymentInitiated';
import StepwiseView from 'modules/single-transfer/thirdPartyPayouts/steps/StepwiseView';
import {
  API_FAIL_ERROR_MESSAGE,
  MOVE_MONEY_ERROR_CODES,
  MOVE_MONEY_ERROR_MESSAGE_CODES,
} from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.constants';
import { ApiErrorResponse } from 'types/api';
import { BeneficiaryPayoutEligibilty, BeneficiaryType } from 'types/beneficiary';
import { CounterParty, CounterPartyById } from 'types/beneficiary-v2';
import { CompletedInfoParams, FiatRFQType, RfqPayload, ThirdPartyTransferDetailsParams } from 'types/transactions';
import { ErrorCardTypes } from 'components/banners/types';
import FullScreenPopup from 'components/popup/FullScreenPopup';
import CommonWrapper from 'components/wrappers/CommonWrapper';

interface ThirdPartyPayoutsProps {
  recipient?: BeneficiaryType | null;
  selectedSourceAccountData?: {
    entityId: string;
    vaultId: string;
    accountId: string;
  };
  onClose: (param: null) => void;
  counterParty?: CounterParty;
}
const totalSteps = 4;

const ThirdPartyPayouts: FC<ThirdPartyPayoutsProps> = ({
  recipient = null,
  selectedSourceAccountData,
  onClose = defaultFn,
  counterParty,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(STEPS_TYPES.SELECT_RECIPIENT_ACCOUNT);
  const [selectedRecipientAccount, setSelectedRecipientAccount] = useState<BeneficiaryType | null>(null);
  const [selectedSourceAccount, setSelectedSourceAccount] = useState(null);
  const [transferDetails, setTransferDetails] = useState<ThirdPartyTransferDetailsParams>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [successData, setSuccessData] = useState<CompletedInfoParams>();
  const [prefilledBeneData, setPrefilledBeneData] = useState<BeneficiaryPayoutEligibilty>();
  const [fiatQuoteData, setFiatQuoteData] = useState<FiatRFQType>();
  const [rfqPayload, setRfqPayload] = useState<RfqPayload>({ corridor_id: '', beneficiary_id: '', account_id: '' });
  const [isRfqAutoUpdated, setRfqAutoUpdated] = useState<boolean>(false);
  const [selectedCounterParty, setSelectedCounterParty] = useState<CounterParty>();
  const [selectedCounterPartyById, setSelectedCounterPartyById] = useState<CounterPartyById>();
  const [
    createRfq,
    { data: rfqDetails, isLoading: rfqLoading, isUninitialized: rfqUninitialized, error: rfqError, reset },
  ] = useCreateRfqMutation();

  const rfqTimer = useRef<ReturnType<typeof setTimeout>>();

  const clearRfqTimer = () => {
    if (rfqTimer) clearTimeout(rfqTimer.current);
  };

  const handleRfqTimer = (payload: RfqPayload) => {
    clearRfqTimer();

    const timeOut = setTimeout(async () => {
      createRfq(payload)
        .unwrap()
        .then(() => setRfqAutoUpdated(true))
        .catch((e) => console.log(e));
    }, FIAT_QUOTE_EXPIRY_TIME);

    rfqTimer.current = timeOut;
  };

  useEffect(() => {
    setRfqAutoUpdated(false);
  }, [rfqPayload]);

  useEffect(() => {
    if (rfqDetails?.id && rfqPayload) handleRfqTimer(rfqPayload);
  }, [rfqDetails, rfqPayload]);

  useEffect(() => {
    if (!!recipient && !selectedRecipientAccount) {
      setSelectedRecipientAccount(recipient);
      setCurrentStep(STEPS_TYPES.SELECT_SOURCE_ACCOUNT);
    }
  }, [recipient]);

  const handleReset = (newStep: number) => {
    if (newStep === STEPS_TYPES.SELECT_RECIPIENT_ACCOUNT) setSelectedSourceAccount(null);

    if (newStep === STEPS_TYPES.SELECT_RECIPIENT_ACCOUNT || newStep === STEPS_TYPES.SELECT_SOURCE_ACCOUNT) {
      setTransferDetails(undefined);
      reset();
    }
  };

  const handlePreviousStep = () => {
    let newStep = currentStep - 1;

    if (currentStep === STEPS_TYPES.ADDITIONAL_BENE_DETAILS) {
      newStep = STEPS_TYPES.TRANSFER_DETAILS;
    }
    handleReset(newStep);
    setCurrentStep(newStep);
  };

  const handleNextStep = (stepData?: any) => {
    let newStep = currentStep + 1;

    if (currentStep === STEPS_TYPES.SELECT_RECIPIENT_ACCOUNT) setSelectedRecipientAccount(stepData);
    else if (currentStep === STEPS_TYPES.SELECT_SOURCE_ACCOUNT) setSelectedSourceAccount(stepData);
    else if (currentStep === STEPS_TYPES.TRANSFER_DETAILS) {
      setTransferDetails(stepData);
      if (stepData?.needAdditionalDetails) {
        newStep = STEPS_TYPES.ADDITIONAL_BENE_DETAILS;
        setPrefilledBeneData(stepData?.beneData);
      }
    } else if (currentStep === STEPS_TYPES.ADDITIONAL_BENE_DETAILS) {
      newStep = STEPS_TYPES.REVIEW_AND_PAY;
    }

    handleReset(newStep);
    setCurrentStep(newStep);
  };

  const handleGoToStep = (goToStep: number) => {
    handleReset(goToStep);
    setCurrentStep(goToStep);
  };

  const handleSuccess = (data: CompletedInfoParams) => {
    setSuccessData(data);
    setIsSuccess(true);
  };

  const handleError = (e?: ApiErrorResponse) => {
    let errorMessageText = API_FAIL_ERROR_MESSAGE;

    if (e?.data?.error) {
      const { code, message } = e.data.error;

      if (MOVE_MONEY_ERROR_MESSAGE_CODES.includes(code as MOVE_MONEY_ERROR_CODES)) errorMessageText = message;
      else if (code === MOVE_MONEY_ERROR_CODES.INSUFFICIENT_BALANCE)
        errorMessageText = 'Insufficient balance in your account.';
    }
    setErrorMessage(errorMessageText);
    setIsError(true);
  };

  const handleRetryOnError = () => {
    setIsError(false);
    handleGoToStep(STEPS_TYPES.TRANSFER_DETAILS);
  };

  const handleClose = () => onClose(null);

  const handleRecipientClick = () => handleGoToStep(STEPS_TYPES.SELECT_RECIPIENT_ACCOUNT);

  const handleCounterPartyClick = () => {
    handleRecipientClick();
    setSelectedCounterParty(undefined);
  };

  useEffect(() => {
    if (!!counterParty && !selectedCounterParty) {
      setSelectedCounterParty(counterParty);
    }
  }, [counterParty]);

  return (
    <FullScreenPopup
      onClose={handleClose}
      logoWrapperClassName='tw-w-[78.03px] tw-h-[19px] tw-absolute tw-top-8 tw-left-[42px]'
      logoDimensions={{ width: 78.03, height: 19 }}
      closeWrapperClassName='tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-GRAY_2 tw-w-[33px] tw-h-[33px] tw-fixed tw-top-[25px] tw-right-[25px]'
      closeIconSrc={X_CLOSE}
      closeIconDimensions={{ width: 14.67, height: 14.67 }}
      bodyClassName={`tw-h-full ${isSuccess || isError ? 'tw-justify-center' : ''} ${
        currentStep === STEPS_TYPES.REVIEW_AND_PAY ? '!tw-flex-row tw-justify-center !tw-items-start tw-gap-[90px]' : ''
      }`}
    >
      <div className='tw-w-[390px]'>
        <CommonWrapper
          isSuccess={isSuccess}
          successCard={
            <PaymentInitiated
              onClose={handleClose}
              recipientAccount={selectedRecipientAccount}
              sourceAccount={selectedSourceAccount}
              counterParty={selectedCounterParty}
              {...successData}
            />
          }
          isError={isError}
          errorCardType={ErrorCardTypes.PAYMENT_FAIL}
          errorCardProps={{
            subtitle: errorMessage,
            customWrapperClassName: '',
            customImageDimensions: { width: 144, height: 140 },
            customTitleClassName: 'f-20-400 tw-text-TEXT_PRIMARY tw-mt-[10px] tw-mb-8',
            customSubtitleClassName: 'f-16-300 tw-text-TEXT_TERTIARY tw-text-center',
          }}
          refetchFunnction={handleRetryOnError}
        >
          <div className='tw-mt-[75px] tw-mb-4'>
            <div className='f-13-500 tw-mb-3'>
              <span className='tw-text-ZAMP_PRIMARY' data-testid='single-transfer-step-count'>{`STEP 0${Math.floor(
                currentStep
              )}`}</span>
              <span
                className='tw-text-TEXT_TERTIARY'
                data-testid='single-transfer-total-steps'
              >{` / 0${totalSteps}`}</span>
            </div>

            <div className='f-18-500 tw-text-TEXT_PRIMARY tw-mb-4' data-testid='single-transfer-step-title'>
              {STEP_TITLE[currentStep]}
            </div>

            {currentStep !== STEPS_TYPES.SELECT_RECIPIENT_ACCOUNT &&
              currentStep !== STEPS_TYPES.ADDITIONAL_BENE_DETAILS && (
                <SelectedAccounts
                  recipientAccount={selectedRecipientAccount}
                  sourceAccount={currentStep === STEPS_TYPES.SELECT_SOURCE_ACCOUNT ? null : selectedSourceAccount}
                  recipientProps={{ isDropdown: currentStep !== STEPS_TYPES.REVIEW_AND_PAY }}
                  sourceProps={{ isDropdown: currentStep !== STEPS_TYPES.REVIEW_AND_PAY }}
                  isSummary={currentStep === STEPS_TYPES.REVIEW_AND_PAY}
                  onRecipientClick={handleRecipientClick}
                  onSourceClick={() => handleGoToStep(STEPS_TYPES.SELECT_SOURCE_ACCOUNT)}
                  selectedCounterParty={selectedCounterParty}
                  onClickCounterParty={handleCounterPartyClick}
                />
              )}

            <StepwiseView
              selectedRecipientAccount={selectedRecipientAccount}
              selectedSourceAccount={selectedSourceAccount}
              selectedSourceAccountData={selectedSourceAccountData}
              transferDetails={transferDetails}
              rfqData={{
                rfqDetails,
                rfqLoading,
                rfqError,
                rfqPayload,
                rfqUninitialized,
                setRfqPayload,
                clearRfqTimer,
                createRfq,
              }}
              summaryData={{
                quotePayload: rfqPayload,
                quoteId: rfqDetails?.id ?? '',
                isQuoteAutoUpdated: isRfqAutoUpdated,
                isQuoteIndicative: rfqDetails?.is_quote_indicative,
              }}
              step={currentStep}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              handleSuccess={handleSuccess}
              handleError={handleError}
              prefilledBeneData={prefilledBeneData}
              setFiatQuoteData={setFiatQuoteData}
              setSelectedCounterParty={setSelectedCounterParty}
              selectedCounterParty={selectedCounterParty}
              selectedCounterPartyById={selectedCounterPartyById}
              setSelectedCounterPartyById={setSelectedCounterPartyById}
            />
          </div>
        </CommonWrapper>
      </div>
      {currentStep === STEPS_TYPES.REVIEW_AND_PAY && !isError && !isSuccess && (
        <ExecuteTransfer
          quotePayload={rfqPayload}
          onSuccess={handleSuccess}
          onError={handleError}
          handleBack={handlePreviousStep}
          {...transferDetails}
          selectedSourceAccount={selectedSourceAccount}
          data={fiatQuoteData}
        />
      )}
    </FullScreenPopup>
  );
};

export default ThirdPartyPayouts;
