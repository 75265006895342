import { FC, memo } from 'react';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_FLAGS } from 'constants/config';
import mixpanelEvents from 'constants/mixpanel';
import { ROUTES_PATH } from 'constants/routeConfig';
import { Text } from 'destiny/dist/components/atoms/text';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { useAppSelector } from 'hooks/toolkit';
import useConfiguration, { CONFIG_DEFINITIONS } from 'hooks/useConfiguration';
import { useLogout } from 'hooks/useLogout';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { defaultFnType } from 'types';
import { trackMixpanel } from 'utils/mixpanel';
import Avatar from 'components/avatar/Avatar';
import AccessWrapper from 'components/wrappers/AccessWrapper';

export interface SidebarProfileSectionProps {
  expanded: boolean;
  userName: string;
  dropdownState: boolean;
  toggleDropdown: defaultFnType;
}

const SidebarProfileSection: FC<SidebarProfileSectionProps> = ({
  expanded,
  userName = 'Loading ...',
  dropdownState,
  toggleDropdown,
}) => {
  const router = useRouter();
  const doLogout = useLogout();

  const handleLogout = async () => {
    try {
      doLogout();
      trackMixpanel(mixpanelEvents.LOGOUT_BUTTON_CLICK, {});
      trackMixpanel(mixpanelEvents.RESET, {});
    } catch (e) {
      console.log(e);
    }
  };

  const { checkConfigEnabled } = useConfiguration();
  const { userAccessFlags } = useAppSelector((state: RootState) => state.user);
  const isPayoutsEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_PAYOUTS_ENABLED];

  const isPoliciesEnabled = checkConfigEnabled(CONFIG_DEFINITIONS.POLICIES_ENABLED);

  return (
    <div
      className={`tw-pointer-events-none tw-transition-all tw-select-none tw-duration-300 tw-absolute  ${
        expanded ? 'tw-w-[248px] tw-bottom-4 tw-left-3' : 'tw-w-[68px] xl:tw-w-[248px] tw-bottom-4 tw-left-3'
      }`}
    >
      <div
        className={`tw-ease-out tw-bg-white tw-duration-300 lavander_1-box-shadow tw-rounded-lg tw-overflow-hidden ${
          dropdownState ? 'tw-pointer-events-auto tw-mb-2 tw-h-auto' : 'tw-pointer-events-none tw-h-0'
        }`}
      >
        <AccessWrapper
          permissionId={FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId}
          scope={FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope}
          hideDeniedComponent
        >
          <div
            className='tw-relative tw-min-h-[56px] tw-flex tw-justify-start tw-py-4 tw-px-6 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out tw-items-center'
            role='button'
            tabIndex={0}
            onClick={() => router.push(ROUTES_PATH.COMPANY_DETAILS)}
          >
            <SvgSpriteLoader id='building-05' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={20} height={20} />
            <div className={`tw-ml-[18px] ${expanded ? 'tw-block' : 'tw-hidden xl:tw-block'}`}>Company Details</div>
          </div>
        </AccessWrapper>

        <AccessWrapper
          permissionId={FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.permissionId}
          scope={FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.scope}
          hideDeniedComponent
        >
          <div
            className='tw-relative tw-min-h-[56px] tw-flex tw-justify-start tw-py-4 tw-px-6 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out tw-items-center'
            role='button'
            tabIndex={0}
            onClick={() => router.push(ROUTES_PATH.TEAM)}
          >
            <SvgSpriteLoader id='users-01' iconCategory={ICON_SPRITE_TYPES.USERS} width={20} height={20} />
            <div className={`tw-ml-[18px] ${expanded ? 'tw-block' : 'tw-hidden xl:tw-block'}`}>Team</div>
          </div>
        </AccessWrapper>
        {isPoliciesEnabled && isPayoutsEnabled && (
          <AccessWrapper
            permissionId={FLOW_PERMISSION_PROPERTIES.MANAGE_POLICIES.permissionId}
            scope={FLOW_PERMISSION_PROPERTIES.MANAGE_POLICIES.scope}
            hideDeniedComponent
          >
            <div
              className='tw-relative tw-min-h-[56px] tw-flex tw-justify-start tw-py-4 tw-px-6 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out tw-items-center'
              role='button'
              tabIndex={0}
              onClick={() => router.push(ROUTES_PATH.POLICIES)}
            >
              <SvgSpriteLoader id='shield-tick' iconCategory={ICON_SPRITE_TYPES.SECURITY} width={20} height={20} />
              <div className={`tw-ml-[18px] ${expanded ? 'tw-block' : 'tw-hidden xl:tw-block'}`}>Payment Policies</div>
            </div>
          </AccessWrapper>
        )}
        <div
          className='tw-relative tw-min-h-[56px] tw-flex tw-justify-start tw-py-4 tw-px-6 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out tw-items-center'
          role='button'
          tabIndex={0}
          onClick={() => router.push(ROUTES_PATH.SETTINGS)}
        >
          <SvgSpriteLoader id='settings-01' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={20} height={20} />
          <div className={`tw-ml-[18px] ${expanded ? 'tw-block' : 'tw-hidden xl:tw-block'}`}>Settings</div>
        </div>
        <div
          className='tw-relative tw-min-h-[56px] tw-flex tw-justify-start tw-py-4 tw-px-6 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out  tw-items-center'
          role='button'
          tabIndex={0}
          onClick={handleLogout}
        >
          <SvgSpriteLoader
            id='log-out-02'
            iconCategory={ICON_SPRITE_TYPES.GENERAL}
            width={20}
            height={20}
            color={COLORS.RED_PRIMARY}
          />
          <div className={`tw-ml-[18px] tw-text-ERROR_RED ${expanded ? 'tw-block' : 'tw-hidden xl:tw-block'}`}>
            Logout
          </div>
        </div>
      </div>
      <div
        className={`tw-pointer-events-auto tw-overflow-hidden !tw-min-h-[68px] tw-bg-BASE_SECONDARY tw-transition-all tw-duration-300 tw-relative tw-border tw-border-GRAY_1 tw-items-center tw-flex tw-rounded-lg tw-p-3 ${
          expanded ? 'tw-w-full tw-justify-start' : ' xl:tw-w-full tw-justify-center xl:tw-justify-start'
        }`}
        tabIndex={0}
        role='button'
        onClick={toggleDropdown}
      >
        <div className='tw-flex tw-items-center'>
          <div className='!tw-min-w-10 !tw-min-h-10 tw-z-50'>
            <Avatar name={userName ?? ''} className='!tw-w-10 !tw-h-10' />
          </div>

          <div className={`tw-ml-3 tw-min-w-10 tw-text-left ${expanded ? 'tw-block' : 'tw-hidden xl:tw-block'}`}>
            <Text textClass='f-14-400 tw-max-w-[150px] tw-truncate tw-whitespace-nowrap tw-capitalize'>
              {userName ? userName : 'Loading...'}
            </Text>
          </div>
        </div>

        <div
          className={`tw-transition-all tw-duration-300 tw-absolute hover:tw-bg-GRAY_1 tw-right-3 tw-top-5 tw-w-7 tw-h-7 tw-flex tw-items-center tw-justify-center tw-rounded-full ${
            expanded ? 'tw-flex' : 'tw-hidden xl:tw-flex'
          }
        ${dropdownState ? ' tw-rotate-180' : ''}
        `}
        >
          <SvgSpriteLoader id='chevron-down' iconCategory={ICON_SPRITE_TYPES.ARROWS} width={16} height={16} />
        </div>
      </div>
    </div>
  );
};

export default memo(SidebarProfileSection);
