import { type FC } from 'react';
import Wallet from 'components/wallet-item/Wallet';

const BeneficiaryWalletCurrencyColumn: FC<{ data: string }> = ({ data }) => {
  return (
    <div className='tw-flex tw-items-center tw-gap-1'>
      <Wallet wallet={data} />
      <div>wallet</div>
    </div>
  );
};

export default BeneficiaryWalletCurrencyColumn;
