import { type FC, useState } from 'react';
import { useGetCounterPartyByIdQuery } from 'api/beneficiary-v2';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { PLUS_CIRCLE, SEARCH_MD } from 'constants/icons';
import { StatusType } from 'constants/kyb';
import { COLORS } from 'destiny/dist/constants/colors';
import { useAppSelector } from 'hooks/toolkit';
import useConfiguration, { CONFIG_DEFINITIONS } from 'hooks/useConfiguration';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import BeneficiaryCreateWithContext from 'modules/beneficiary-v2/BeneficiaryCreateWithContext';
import BeneficiaryListingZeroData from 'modules/beneficiary-v2/BeneficiaryListingZeroData';
import BeneficiaryListItem from 'modules/single-transfer/thirdPartyPayouts/steps/BeneficiaryListItem';
import SelectRecipientAccountSkeleton from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/SelectRecipientAccountSkeleton';
import { getFilteredBeneficiaryList } from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.utils';
import Image from 'next/image';
import { RootState } from 'store';
import { CounterParty } from 'types/beneficiary-v2';
import Input from 'components/input/Input';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

type SelectBankAccountWalletProps = {
  selectedCounterParty: CounterParty;
  onRecipientAccountSelect: (args: any) => void;
  searchClassName?: string;
  beneIconColor?: string;
  destCurrencyCode?: string;
  networkCode?: string;
};
const SelectBankAccountWallet: FC<SelectBankAccountWalletProps> = ({
  selectedCounterParty,
  onRecipientAccountSelect,
  searchClassName = 'tw-bg-GRAY_2',
  beneIconColor = COLORS.ORANGE_PRIMARY,
  destCurrencyCode,
  networkCode,
}) => {
  const [search, setSearch] = useState<string>('');
  const [showAddRecipient, setShowAddRecipient] = useState<boolean>(false);
  const { data, isFetching, isError, refetch } = useGetCounterPartyByIdQuery(selectedCounterParty?.id);

  const { checkConfigEnabled } = useConfiguration();
  const { bankingKybStatus } = useAppSelector((state: RootState) => state.kyb);
  const isBankingPreKyb = bankingKybStatus !== StatusType.SUCCESS;
  const canManageRecipients = useUserAccessFromPermission(
    FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId,
    FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope
  );
  const canAddRecipient =
    checkConfigEnabled(CONFIG_DEFINITIONS.ADD_RECIPIENTS) && !isBankingPreKyb && canManageRecipients;

  const noRecipients = selectedCounterParty?.crypto_wallets_count + selectedCounterParty?.fiat_accounts_count === 0;

  const toggleAddBene = () => setShowAddRecipient((prev) => !prev);

  const handleRecipientSelect = (recipient: any) => onRecipientAccountSelect(recipient);

  return (
    <>
      <div className='tw-w-full tw-flex tw-flex-col'>
        <div className='tw-w-full tw-grow tw-relative tw-mb-2'>
          <div className='tw-h-full tw-left-6 tw-absolute tw-z-50 tw-flex tw-items-center'>
            <Image src={SEARCH_MD} alt='search' width={20} height={20} priority />
          </div>
          <Input
            id='search'
            value={search}
            onChange={(evt: any) => setSearch(evt?.target?.value)}
            className={`f-16-300 tw-border-none tw-text-TEXT_PRIMARY !tw-h-[52px] !tw-rounded-[5px] tw-pl-[60px] !tw-pr-6 !tw-py-4 ${searchClassName}`}
            placeHolder='Search by nickname, account no. /wallet'
          />
        </div>
      </div>

      <div className='tw-bg-white tw-rounded-2.5 tw-border tw-DIVIDER_GRAY tw-shadow-menu'>
        {canAddRecipient && !noRecipients && (
          <div
            role='presentation'
            onClick={toggleAddBene}
            className='tw-flex tw-items-center tw-px-6 tw-py-4 tw-border-b tw-DIVIDER_GRAY tw-cursor-pointer'
            data-testid='add-recipient-button'
          >
            <Image src={PLUS_CIRCLE} width={24} height={24} className='tw-mr-3' alt='add' priority />
            <div className='f-14-400 tw-text-TEXT_PRIMARY' id='add-a-new-recipient'>
              Add a new account / wallet
            </div>
          </div>
        )}
        <CommonWrapper
          skeletonType={SkeletonTypes.CUSTOM}
          skeleton={<SelectRecipientAccountSkeleton />}
          isNoData={noRecipients}
          noDataBanner={<BeneficiaryListingZeroData selectedCounterParty={selectedCounterParty} />}
          refetchFunnction={refetch}
          isLoading={isFetching}
          isError={isError}
        >
          <div className='tw-max-h-[354px] tw-overflow-y-auto'>
            {getFilteredBeneficiaryList(search, data, destCurrencyCode, networkCode)?.map((item) => (
              <BeneficiaryListItem
                key={item?.id}
                beneficiary={item}
                onClick={handleRecipientSelect}
                iconColor={beneIconColor}
              />
            ))}
          </div>
        </CommonWrapper>
      </div>
      {showAddRecipient && <BeneficiaryCreateWithContext onClose={toggleAddBene} counterParty={selectedCounterParty} />}
    </>
  );
};

export default SelectBankAccountWallet;
