export enum PendingPayoutTypes {
  BULK_PAYOUT = 'bulk_payout',
  BULK_PAYOUT_V2 = 'bulk_payout_v2',
  SINGLE_PAYOUT_CRYPTO = 'single_payout_crypto',
  SINGLE_PAYOUT_FIAT = 'single_payout_fiat',
  SINGLE_PAYOUT = 'single_payout',
}

export enum ApprovalPageTypes {
  PENDING_LIST = 'PENDING_LIST',
  REVIEW = 'REVIEW',
  INITATED = 'INITATED',
  ERROR = 'ERROR',
}

export interface PendingPaymentType {
  id: string;
  request_id: string;
  date: string;
  status: string;
  to_from: string;
  initiator: string;
  source_amount: number;
  source_currency: string;
  receiving_amount: number;
  receiving_currency: string;
  request_type: PendingPayoutTypes;
  beneficiary_count: number;
  quote_request_data: QuotePayloadType;
  account_id?: string;
  source_currency_code: string;
  payout_count: number;
}

export interface PendingRequestType {
  request_id: string;
  request_type: string;
  requested_date: string;
  status: string;
  request_data: {
    bulk_payout_id: string;
    beneficiary_count: number;
    initiator: string;
    payment_method: string;
    quote_request_data: QuotePayloadType;
    receiving_amount: number;
    source_currency: number;
    receiving_currency_code: string;
    receiving_currency_display_name: string;
    source_amount: number;
    source_currency_code: string;
    source_currency_display_name: string;
    to_from: string;
    account_id: string;
    payout_count: number;
  };
}

export interface PendingPaymentResponseType {
  approval_requests: PendingPaymentType[];
}

export interface QuotePayloadType {
  beneficiary_id: string;
  corridor_id: string;
  source_amount: number;
  receiving_amount: number;
  account_id: string;
}

export interface GasFeeResponseType {
  created_at: string;
  total_amount: number;
  total_beneficiaries: number;
  total_fees: number;
  total_fees_currency: string;
  updated_at: string;
  id: string;
}

export interface GasFeeRequestType {
  txnId: string;
}

export interface PendingTxnCountResponse {
  pending_requests_count: number;
  total_count?: number;
  pending_count?: number;
}
