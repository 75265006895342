import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { InitiateTOTPResponseType, ValidateTOTPRequestType } from 'types/api';

const mfa = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    initiateTotp: builder.mutation<InitiateTOTPResponseType, void>({
      query: (payload) => ({
        url: `v1/b2b-user/initiate-totp`,
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
    }),
    validateTotp: builder.mutation<any, ValidateTOTPRequestType>({
      query: (payload) => ({
        url: `v1/b2b-user/validate-totp-setup`,
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      invalidatesTags: (result, error) => {
        return error ? [] : [APITags.GET_USER];
      },
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const { useInitiateTotpMutation, useValidateTotpMutation } = mfa;
