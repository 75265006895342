import { CSSProperties } from 'react';

export type formatFunction = (value: string) => string;

export type Coloumn = {
  label: string;
  value?: string;
  align?: DataAlign;
  formatFunction?: formatFunction;
  renderComponent?: DataComponents;
  className?: string;
  headerClassName?: string;
  dependentValue?: string;
  wrapperStyle?: CSSProperties;
  value2?: string;
};

export type DataItem = Record<string, string | number>;

export interface TableProps {
  data: DataItem[];
  columns: Coloumn[];
  className?: string;
  tableClass?: string;
  onClickRow?: (item: DataItem) => void;
}

export enum DataComponents {
  TRANSACTION_STATUS_TAG,
  PAYMENT_METHOD,
  TRANSACTION_TYPE,
  CURRENCY,
  DESCRIPTION,
  AMOUNT,
  TOFROM,
  PAYMENT_REQUEST_STATUS,
}

export enum DataAlign {
  LEFT,
  CENTER,
  RIGHT,
}
