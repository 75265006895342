import { FC, useState } from 'react';
import { useInitiateBulkPaymentMutation, useLazyGetBulkPaymentByIdQuery } from 'api/bulk-payment';
import { FILE_SIZE, SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { Label } from 'destiny/dist/components/molecules/label';
import { useAppDispatch } from 'hooks/toolkit';
import usePolling from 'hooks/usePolling';
import { useSessionToken } from 'hooks/useSessionToken';
import BulkFileUploader from 'modules/bulk-payment/BulkFileUploader';
import {
  BULK_PAYMENT_DOWNLOAD_TEMPLATE,
  BULK_PAYMENT_INSTRUCTIONS,
  BULK_PAYMENT_STAGES,
  BULK_PAYMENT_UPLOAD_POLLING_LIMITS,
} from 'modules/bulk-payment/BulkPayment.constants';
import { BulkPaymentStagesCommonProps } from 'modules/bulk-payment/BulkPayment.types';
import { updateInitiatedPayouts } from 'store/slices/bulk-payouts';
import { ApiErrorResponse, GetPayoutsByBulkIdResponse } from 'types/api';
import { INPUT_FILE_FORMATS, MIME } from 'types/mime';
import { TxnStatus } from 'types/transactions';
import DownloadButton from 'components/button/DownloadButton';
import FileUploaderWrapper from 'components/file-uploader/FileUploaderWrapper';
import InstructionsTemplate from 'components/instructions-template/InstructionsTemplate';

interface BulkPaymentUploadProps extends BulkPaymentStagesCommonProps {
  setDisableClose: (flag: boolean) => void;
}

const BulkPaymentUpload: FC<BulkPaymentUploadProps> = ({ setDisableClose, setCurrentStage }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileProcessing, setIsFileProcessing] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [initiateBulkPayout] = useInitiateBulkPaymentMutation();
  const [getPayoutsByBulkId] = useLazyGetBulkPaymentByIdQuery();
  const { sessionToken, createSessionToken } = useSessionToken(SESSION_CAPABILITY_CONTEXT_KEYS.BULK_PAYOUT_CREATE);
  const { startPolling } = usePolling();

  const dispatch: any = useAppDispatch();

  const handleError = (error: ApiErrorResponse) => {
    createSessionToken();
    setError(error?.data?.error?.message ?? error);
    setIsFileProcessing(false);
  };

  const handleFileChange = (file: string | null) => {
    if (file) {
      setError('');
      setIsFileProcessing(true);
      initiateBulkPayout({ data: { key: file }, idempotencyHeader: sessionToken ?? '' })
        .unwrap()
        .then(({ id = '' }) => {
          if (id)
            startPolling({
              fn: () => getPayoutsByBulkId({ id }),
              validate: ({ status = '' }) => {
                return status === TxnStatus.VALIDATED || status === TxnStatus.VALIDATION_FAILED;
              },
              maxAttempts: BULK_PAYMENT_UPLOAD_POLLING_LIMITS.MAX_ATTEMPTS,
              interval: BULK_PAYMENT_UPLOAD_POLLING_LIMITS.INTERVAL,
            })
              .then((data) => {
                if (data?.status === TxnStatus.VALIDATION_FAILED && (data?.error_rows?.length ?? 0) < 1)
                  handleError(data?.error);
                else {
                  dispatch(updateInitiatedPayouts(data as GetPayoutsByBulkIdResponse));
                  setIsFileProcessing(false);
                  setIsSuccess(true);
                  setTimeout(
                    () => setCurrentStage(BULK_PAYMENT_STAGES.REVIEW),
                    BULK_PAYMENT_UPLOAD_POLLING_LIMITS.SUCCESS_TIMEOUT
                  );
                }
              })
              .catch(handleError);
        })
        .catch(handleError);
    }
  };

  return (
    <div className='tw-flex tw-mt-28 tw-w-2/3 tw-max-w-[1000px] tw-justify-between'>
      <InstructionsTemplate {...BULK_PAYMENT_INSTRUCTIONS} className='tw-w-96 tw-mr-5' />
      <div className='tw-w-fit'>
        <DownloadButton
          buttonId='BULK_TRANSFER_DOWNLOAD_FILE_BUTTON'
          url={BULK_PAYMENT_DOWNLOAD_TEMPLATE.file}
          mime={MIME.CSV}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          title={
            <Label
              title={BULK_PAYMENT_DOWNLOAD_TEMPLATE.title}
              description={BULK_PAYMENT_DOWNLOAD_TEMPLATE.description}
              wrapperClass='tw-text-left'
              titleClass='f-14-400 tw-mb-1'
            />
          }
          className='tw-mb-4 tw-w-[460px] !tw-rounded-2.5 tw-h-[84px]'
          icon='file-06'
          iconDimension={{ width: 24, height: 24 }}
        />
        <FileUploaderWrapper
          filesSelected={''}
          errorMsg={error}
          onFilesSelect={handleFileChange}
          disableNext={setDisableClose}
          acceptedFormats={INPUT_FILE_FORMATS.CSV}
          isFileUploading={isFileProcessing}
          maxSize={FILE_SIZE.THREE_MB}
          setParentError={setError}
          Component={BulkFileUploader}
          isSuccess={isSuccess}
        />
      </div>
    </div>
  );
};

export default BulkPaymentUpload;
