import { FC } from 'react';
import { VAULT } from 'constants/icons';
import { Text } from 'destiny/dist/components/atoms/text';
import { defaultFn } from 'destiny/dist/constants/index';
import Image from 'next/image';
import { VaultResponse } from 'types/api';

interface VaultCardprops {
  vault: VaultResponse;
  onClick: (vaultData: VaultResponse) => void;
}

const VaultCard: FC<VaultCardprops> = ({ vault, onClick = defaultFn }) => {
  const { name = '', entity_name = '', balance = '' } = vault;

  const handleClick = () => onClick(vault);

  return (
    <div
      className='tw-px-6 tw-py-4 tw-cursor-pointer tw-flex tw-items-center hover:tw-bg-BASE_SECONDARY'
      role='presentation'
      onClick={handleClick}
    >
      <Image src={VAULT} width={32} height={32} priority alt='' className='tw-mr-4' />
      <div
        className={`tw-flex tw-flex-col tw-justify-center ${
          balance ? 'tw-w-[calc(100%-170px)]' : 'tw-w-[calc(100%-48px)]'
        }`}
      >
        <Text textClass='tw-text-TEXT_PRIMARY tw-mb-0.5 f-14-400 tw-truncate'>{name}</Text>
        <Text textClass='tw-text-TEXT_SECONDARY f-12-300 tw-truncate'>{entity_name}</Text>
      </div>
      {!!balance && (
        <div className='tw-flex tw-flex-col tw-justify-center tw-ml-auto tw-min-w-[106px] tw-max-w-[106px]'>
          <Text textClass='tw-text-TEXT_SECONDARY tw-mb-1 f-12-300'>Estimated balance</Text>
          <Text textClass='tw-text-TEXT_SECONDARY f-12-300 tw-truncate'>{balance}</Text>
        </div>
      )}
    </div>
  );
};

export default VaultCard;
