import { memo } from 'react';
import { sidebarEventsMap } from 'constants/index';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { trackMixpanel } from 'utils/mixpanel';

export interface SidebarTabProps {
  isSelected: boolean;
  iconId: string;
  iconCategory: ICON_SPRITE_TYPES;
  name: string;
  accessFlag: string;
  isNew?: boolean;
  expanded?: boolean;
}

const SidebarTab: React.FC<SidebarTabProps> = ({
  isSelected,
  iconId,
  iconCategory,
  name,
  accessFlag,
  isNew = false,
  expanded = false,
}) => {
  return (
    <div
      className={`tw-w-full tw-h-11.5 tw-px-5 tw-transition-[0.2s] tw-flex tw-gap-2 tw-items-center tw-rounded-full hover:tw-bg-ACCENT_BLUE tw-my-2 ${
        isSelected ? 'tw-bg-ACCENT_BLUE tw-text-TEXT_PRIMARY f-16-400' : 'tw-text-TEXT_SECONDARY f-16-300'
      }`}
      onClick={() => {
        trackMixpanel(sidebarEventsMap[accessFlag][name], {});
      }}
      role='presentation'
    >
      {iconId && (
        <SvgSpriteLoader
          id={iconId}
          iconCategory={iconCategory}
          width={16}
          height={16}
          fillColor='#ffffff'
          className='tw-min-w-4'
        />
      )}
      <div className={`tw-text-sm tw-whitespace-nowrap ${expanded ? 'tw-block' : 'tw-hidden xl:tw-block'}`}>{name}</div>
      {isNew && (
        <div
          className={`tw-bg-GREEN_TERTIARY_1 tw-rounded-[15px] f-10-600 tw-py-1 tw-px-2 tw-uppercase tw-text-white tw-mt-0.5  ${
            expanded ? 'tw-block' : 'tw-hidden xl:tw-block'
          }`}
        >
          NEW
        </div>
      )}
    </div>
  );
};

export default memo(SidebarTab);
