import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import {
  ACCESS_HEADER_RESOURCE_ID_KEY,
  ACCESS_HEADER_RESOURCE_KEY,
  MFA_TOTP_KEY,
  PLATFORM_IDEMPOTENCY_HEADER_KEY,
} from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { VaultResponse } from 'types/api';
import {
  EligibleVaultResponse,
  ExecuteInternalTransferPayload,
  InitiateInternalTransferPayload,
  InternalTransferRFQConfigsRequest,
  InternalTransferRFQQuoteRequest,
  InternalTransferRFQQuoteResponse,
  InternalTransferToEntityType,
} from 'types/internalTransferApi.types';
import { accessBasedQueryWrapper } from 'utils/access';

const InternalTransfer = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEligibleInternalTransferVaults: builder.query<EligibleVaultResponse[], string>({
      query: (id) => ({
        url: `v1/internal-transfer/vaults/${id}`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getAllInternalTransferVaults: builder.query<VaultResponse[], void>({
      query: () => ({
        url: `v1/internal-transfer/vaults`,
      }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_INTERNAL_TRANSFER_VAULTS],
    }),
    executeInternalTransfer: builder.mutation<void, InitiateInternalTransferPayload>({
      query: (payload) => ({
        url: `v1/internal-transfer`,
        method: REQUEST_TYPES.POST,
        body: payload.data,
        headers: {
          [PLATFORM_IDEMPOTENCY_HEADER_KEY]: payload.idempotencyHeader,
          [MFA_TOTP_KEY]: payload.totp,
        },
      }),
      invalidatesTags: [APITags.GET_TRANSACTIONS, APITags.GET_TRANSACTIONS_V2],
    }),
    getRecipientInternalTransferAccounts: builder.query<InternalTransferToEntityType[], void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId, {
          url: 'v1/internal-transfer/dest-accounts',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_INTERNAL_TRANSFER_ACCOUNTS],
    }),
    getSourceInternalTransferAccounts: builder.query<InternalTransferToEntityType[], string>({
      query: (id) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId, {
          url: `v1/internal-transfer/dest-accounts/${id}/src-accounts`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getInternalTransferRFQConfigs: builder.query<any, InternalTransferRFQConfigsRequest>({
      query: (params) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId, {
          url: 'v1/internal-transfer/rfq-config',
          params,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    internalTransferQuote: builder.mutation<InternalTransferRFQQuoteResponse, InternalTransferRFQQuoteRequest>({
      query: (payload) => ({
        url: 'v1/internal-transfer/rfq',
        method: REQUEST_TYPES.POST,
        body: payload,
      }),
      transformResponse: ({ data }) => data,
    }),
    executeInternalTransferV2: builder.mutation<void, ExecuteInternalTransferPayload>({
      query: (payload) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.REQUEST_PAYOUT.permissionId, {
          url: 'v1/internal-transfer/execute',
          method: REQUEST_TYPES.POST,
          body: payload?.data,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.REQUEST_PAYOUT.scope,
            [ACCESS_HEADER_RESOURCE_ID_KEY]: payload?.accountId,
            [MFA_TOTP_KEY]: payload?.totp,
          },
        }),
      invalidatesTags: [APITags.GET_TRANSACTIONS, APITags.GET_TRANSACTIONS_V2],
    }),
  }),
});

export const {
  useLazyGetEligibleInternalTransferVaultsQuery,
  useExecuteInternalTransferMutation,
  useGetAllInternalTransferVaultsQuery,
  useGetInternalTransferRFQConfigsQuery,
  useInternalTransferQuoteMutation,
  useExecuteInternalTransferV2Mutation,
  useGetRecipientInternalTransferAccountsQuery,
  useLazyGetSourceInternalTransferAccountsQuery,
} = InternalTransfer;
