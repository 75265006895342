import { type FC } from 'react';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import { getVariation } from 'utils/launchDarkly';
import SidebarTab, { SidebarTabProps } from 'components/layout/SidebarTab';
import SidebarTabOtc, { SidebarTabOtcProps } from 'components/layout/SidebarTabOtc';

interface SidebarTabWrapperProps extends SidebarTabOtcProps, SidebarTabProps {}

const SidebarTabWrapper: FC<SidebarTabWrapperProps> = (props) => {
  const isOtcEnabled = getVariation(LAUNCH_DARKLY_FLAGS.OTC);

  return isOtcEnabled ? <SidebarTabOtc {...props} /> : <SidebarTab {...props} />;
};

export default SidebarTabWrapper;
