import { type FC } from 'react';
import { Label } from 'destiny/dist/components/molecules/label';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { getRoutingCodeDisplayName } from 'modules/beneficiary-v2/beneficiary.utils';
import { EXPECTED_TRANSACTION_TIME } from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.constants';
import { Beneficiary } from 'types/beneficiary-v2';
import { PaymentMethod } from 'types/transactions';
import Wallet from 'components/wallet-item/Wallet';

type BeneficiaryListItemProps = {
  beneficiary: Beneficiary;
  onClick: (args: any) => void;
  className?: string;
  showDropdownIcon?: boolean;
  iconColor?: string;
};
const BeneficiaryListItem: FC<BeneficiaryListItemProps> = ({
  beneficiary,
  onClick,
  className = '',
  showDropdownIcon = false,
  iconColor = COLORS.ORANGE_PRIMARY,
}) => {
  const isFiat = beneficiary?.payout_method === PaymentMethod.FIAT;

  return (
    <div
      className={`tw-px-6 tw-py-4 tw-flex tw-items-center tw-justify-between tw-cursor-pointer ${className}`}
      onClick={() => onClick(beneficiary)}
      data-testid={`select-beneficiary-${beneficiary?.id}`}
    >
      <div className='tw-flex tw-gap-2.5'>
        <SvgSpriteLoader
          id={isFiat ? 'bank' : 'wallet-04'}
          iconCategory={ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE}
          width={16}
          height={16}
          color={iconColor}
        />
        <Label
          titleClass='f-14-300 tw-mb-1'
          descriptionClass='f-12-400 tw-text-TEXT_SECONDARY'
          title={
            isFiat
              ? beneficiary?.beneficiary_bank_name || beneficiary?.beneficiary_alias
              : beneficiary?.beneficiary_alias
          }
          description={
            <div className='tw-flex tw-items-center tw-gap-3'>
              <div className='tw-whitespace-nowrap'>xxxx-{beneficiary?.beneficiary_account_number.slice(-4)}</div>
              <div className='tw-flex tw-items-center tw-gap-1'>
                {isFiat ? (
                  <SvgSpriteLoader
                    id={beneficiary?.destination_currency}
                    iconCategory={ICON_SPRITE_TYPES.FIAT_CURRENCIES}
                    width={12}
                    height={12}
                  />
                ) : (
                  <Wallet
                    wallet={beneficiary?.routing_code_value_1}
                    iconDimensions={{ width: 12, height: 12 }}
                    hideWalletName
                    iconClassName='tw-min-w-[12px]'
                  />
                )}
                <div>{isFiat ? beneficiary?.destination_currency : beneficiary?.routing_code_value_1}</div>
              </div>
            </div>
          }
        />
      </div>
      <div className='tw-flex tw-items-center tw-gap-2'>
        <Label
          titleClass={`f-12-400 tw-mb-[5px] tw-w-fit tw-ml-auto ${
            showDropdownIcon ? '' : 'tw-bg-BASE_PRIMARY tw-px-2.5 tw-py-[1px]'
          }`}
          descriptionClass='f-10-300 tw-text-TEXT_SECONDARY'
          title={getRoutingCodeDisplayName(beneficiary?.routing_code_type_1, beneficiary?.destination_currency)}
          description={EXPECTED_TRANSACTION_TIME[beneficiary?.routing_code_type_1]}
          wrapperClass='tw-w-24 tw-text-right'
        />
        {showDropdownIcon && (
          <SvgSpriteLoader id='chevron-down' iconCategory={ICON_SPRITE_TYPES.ARROWS} width={20} height={20} />
        )}
      </div>
    </div>
  );
};

export default BeneficiaryListItem;
