import { TRANSACTION_STATUS_PROPERTIES } from 'constants/transactions';
import { TxnStatus } from 'types/transactions';

interface TransactionItemProps {
  data: string;
  dependentValue?: number;
}

const TransactionStatus: React.FC<TransactionItemProps> = ({ data, dependentValue }) => {
  const txnStatus: any = TRANSACTION_STATUS_PROPERTIES[data as TxnStatus];

  return (
    <div
      className={`tw-w-fit tw-px-4 tw-rounded-full tw-text-center tw-truncate tw-uppercase f-10-300 tw-font-normal tw-leading-7 tw-h-7 ${txnStatus?.className}`}
    >
      {data === TxnStatus.PARTIAL_SUCCESS ? dependentValue : ''} {txnStatus?.label}
    </div>
  );
};

export default TransactionStatus;
