import { FC } from 'react';
import { Label } from 'destiny/dist/components/molecules/label';
import { defaultFn } from 'destiny/dist/constants/index';
import { LabelProps } from 'destiny/dist/types';
import { defaultFnType } from 'types';
import { ICONS } from 'components/icons/constants';
import IconWrapper from 'components/icons/IconWrapper';
import InfoErrorCard from 'components/info-error-card/InfoErrorCard';
import { CardType, InfoErrorCardProps } from 'components/info-error-card/types';

interface SelectedAccountProps {
  wrapperClassName?: string;
  cardWrapperClassName?: string;
  cardWrapperStyleClassName?: string;
  wrapperStyle?: string;
  showLabel?: boolean;
  labelProps?: LabelProps;
  cardProps?: InfoErrorCardProps;
  showTrailingSection?: boolean;
  isDropdown?: boolean;
  isSummary?: boolean;
  dropdownIconColor?: string;
  editClick?: defaultFnType;
}

const SelectedAccount: FC<SelectedAccountProps> = ({
  wrapperClassName = 'tw-w-full',
  cardWrapperClassName = null,
  cardWrapperStyleClassName = '',
  wrapperStyle = '',
  showLabel = true,
  labelProps = {},
  cardProps = {},
  showTrailingSection = true,
  isDropdown = true,
  isSummary = false,
  dropdownIconColor,
  editClick = defaultFn,
}) => {
  return (
    <div className={`${wrapperClassName} ${wrapperStyle}`}>
      {showLabel && (
        <Label
          titleClass={isSummary ? 'f-12-300 tw-text-TEXT_PRIMARY tw-mb-2' : 'f-12-400 tw-text-TEXT_TERTIARY tw-mb-3'}
          {...labelProps}
        />
      )}
      <InfoErrorCard
        className={`
          ${
            cardWrapperClassName ??
            (isSummary ? '!tw-bg-white !tw-p-0' : '!tw-bg-BASE_PRIMARY !tw-px-6 !tw-py-4 tw-cursor-pointer tw-h-[68px]')
          } ${cardWrapperStyleClassName}`}
        titleClassName='tw-text-TEXT_PRIMARY tw-mb-0.5 f-14-400 tw-truncate'
        customSubtitleClassName='tw-text-TEXT_SECONDARY f-12-300 tw-truncate'
        textWrapperClassName='tw-max-w-[calc(100%-84px)]'
        type={CardType.AVATAR_WITH_TITLE_SUBTITLE}
        trailingSection={
          showTrailingSection ? (
            isDropdown ? (
              <IconWrapper
                icon={ICONS.CHEVRON_DOWN}
                color={dropdownIconColor ?? '#181B28'}
                width={20}
                height={20}
                className='tw-ml-auto'
              />
            ) : (
              <div className='tw-text-ZAMP_PRIMARY f-12-400 tw-ml-auto tw-cursor-pointer' onClick={editClick}>
                Edit
              </div>
            )
          ) : null
        }
        customIconDimensions={{ width: 30, height: 30 }}
        {...cardProps}
      />
    </div>
  );
};

export default SelectedAccount;
