import { EQUALS_BLUE, MINUS_BLUE, SWITCH_BLUE } from 'constants/icons';
import { useAppSelector } from 'hooks/toolkit';
import Image from 'next/image';
import { RootState } from 'store';
import { AmountDetailsProps, CORRIDOR_TYPE } from 'types/transactions';

const AmountDetails: React.FC<AmountDetailsProps> = ({
  transferDetails,
  className = '',
  itemsParentWrapperClassName = 'tw-py-2',
  itemsWrapperClassName = 'tw-w-1/2',
  currencyTextClassName = 'f-14-500',
  labelClassName = 'tw-w-1/2 tw-pl-2 tw-text-TEXT_TERTIARY f-16-300',
  iconClassName = 'tw-w-6 tw-mr-2',
  iconStyle = { width: 24, height: 24 },
  destinationCurrency = '',
  corridor = CORRIDOR_TYPE.FIAT_FIAT,
  sourceCurr = '',
  showIcon = true,
  feeBreakupItemClassName = 'tw-gap-8',
  feeBreakupWrapperClassName = '',
  feeBreakupValueClassName = 'f-12-400 tw-text-TEXT_SECONDARY tw-w-[122px]',
}) => {
  const { currencyCodeAndNameMap } = useAppSelector((state: RootState) => state.config);

  const feesCurrency =
    (transferDetails?.total_fees_currency && currencyCodeAndNameMap[transferDetails.total_fees_currency]) ||
    transferDetails?.total_fees_currency;

  const sourceCurrency = sourceCurr || feesCurrency;

  const feeBreakup = [
    {
      label: 'Gas Fee',
      value: transferDetails?.gas_fees,
      id: 'gas_fees',
    },
    {
      label: 'Transaction Fee',
      value: transferDetails?.platform_fees ?? 0,
      id: 'platform_fees',
    },
  ];

  return (
    <div className={`tw-w-full ${className}`}>
      <div className={`tw-w-full tw-flex tw-items-center ${itemsParentWrapperClassName}`}>
        <div className={`tw-flex tw-items-center ${itemsWrapperClassName}`}>
          {showIcon && <Image src={MINUS_BLUE} alt='minus' className={iconClassName} {...iconStyle} />}
          <div className={currencyTextClassName}>{`${!showIcon ? '-' : ''}${
            transferDetails?.total_fees
          } ${feesCurrency}`}</div>
        </div>
        <div className={labelClassName}>Total Fee</div>
      </div>
      {!!transferDetails?.gas_fees && (
        <div className={`tw-bg-BASE_SECONDARY ${feeBreakupWrapperClassName}`}>
          {feeBreakup.map((fee) => (
            <div className={`tw-flex tw-items-center tw-py-1 ${feeBreakupItemClassName}`} key={fee?.id}>
              <div className={feeBreakupValueClassName}>
                {fee?.value} {feesCurrency}
              </div>
              <div className='f-12-300 tw-text-TEXT_TERTIARY'>{fee?.label}</div>
            </div>
          ))}
        </div>
      )}
      {corridor !== CORRIDOR_TYPE.CRYPTO_CRYPTO && (
        <div className={`tw-w-full tw-flex tw-items-center ${itemsParentWrapperClassName}`}>
          <div className={`tw-flex tw-items-center ${itemsWrapperClassName}`}>
            {showIcon && <Image src={EQUALS_BLUE} alt='equal' className={iconClassName} {...iconStyle} />}
            <div className={currencyTextClassName}>{`${transferDetails?.converted_amount} ${sourceCurrency}`}</div>
          </div>
          <div className={labelClassName}>Amount to be converted</div>
        </div>
      )}
      {(sourceCurrency !== destinationCurrency || corridor === CORRIDOR_TYPE.CRYPTO_CRYPTO_BRIDGE) && (
        <div className={`tw-w-full tw-flex tw-items-center ${itemsParentWrapperClassName}`}>
          <div className={`tw-flex tw-items-center ${itemsWrapperClassName}`}>
            {showIcon && <Image src={SWITCH_BLUE} alt='switch' className={iconClassName} {...iconStyle} />}
            <div
              className={currencyTextClassName}
            >{`1 ${sourceCurrency} = ${transferDetails?.exchange_rate} ${destinationCurrency}`}</div>
          </div>
          <div className={labelClassName}>Exchange Rate</div>
        </div>
      )}
    </div>
  );
};

export default AmountDetails;
