import { useState } from 'react';
import { ALERT, ENABLE_MFA, WAVE_DIVIDER } from 'constants/icons';
import { DIGIT_REGEX } from 'constants/regex';
import { Button } from 'destiny/dist/components/molecules/button';
import { defaultFn } from 'destiny/dist/constants/index';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { useAppSelector } from 'hooks/toolkit';
import { useEnableMFA } from 'hooks/useEnableMFA';
import Image from 'next/image';
import { RootState } from 'store';
import { trackMixpanel } from 'utils/mixpanel';
import OtpInput from 'components/otp-input/OtpInput';

interface CheckMFAProps {
  wrapperClassName?: string;
  showDivider?: boolean;
  label?: any;
  otpError?: any;
  setOtp?: (otp: string) => void;
  onOtpSubmit?: (otp?: string) => void;
  autoFocus?: boolean;
  labelStyle?: string;
  otpInputWrapperClassName?: string;
  otpInputProps?: object;
  otpInputsType?: string;
  enableDescription?: string;
  enableButtonId?: string;
  customEnableMFAStyles?: {
    enableWrapperClass?: string;
    enableIconWrapperClass?: string;
    enableIconWidth?: number;
    enableIconHeight?: number;
    enableLabelClass?: string;
    enableDescriptionClass?: string;
    enableButtonClass?: string;
  };
}

const CheckMFA: React.FC<CheckMFAProps> = ({
  wrapperClassName = '',
  showDivider = true,
  label = null,
  otpError = '',
  setOtp = defaultFn,
  onOtpSubmit = defaultFn,
  autoFocus = true,
  labelStyle = null,
  otpInputWrapperClassName = 'tw-mb-3',
  otpInputProps = {},
  otpInputsType = '',
  enableButtonId = 'ENABLE_2FA_BUTTON',
  enableDescription = 'Before proceeding, please enhance the security of your account by adding an additional layer of protection.',
  customEnableMFAStyles: {
    enableWrapperClass = ' tw-min-h-[159px] tw-p-6',
    enableIconWrapperClass = 'tw-min-w-[82px] tw-min-h-[88px] tw-mr-[32px]',
    enableIconWidth = 82,
    enableIconHeight = 88,
    enableLabelClass = 'f-16-400 tw-mb-[10px] tw-leading-5',
    enableDescriptionClass = 'f-14-300 tw-mb-[10px] tw-leading-[18px]',
    enableButtonClass = 'tw-border tw-py-[16px] tw-px-[10px] tw-min-w-[99px] tw-h-[35px] tw-border-DIVIDER_GRAY tw-bg-white tw-text-TEXT_PRIMARY tw-text-center tw-rounded-full tw-cursor-pointer tw-text-xs !tw-font-medium tw-leading-[15px]',
  } = {},
}) => {
  const { user: userData } = useAppSelector((state: RootState) => state?.user);
  const { loading, handleEnableMFA } = useEnableMFA(true);

  const [otp, setOtpState] = useState('');

  const handleSetOtp = (value: string) => {
    setOtp(value);
    setOtpState(value);
  };

  const handleOtpSubmit = () => {
    onOtpSubmit(otp);
  };

  return (
    <>
      {userData?.is_mfa_enabled ? (
        <div className={`${wrapperClassName} tw-flex tw-flex-col tw-align-start`}>
          {showDivider && (
            <div className='tw-relative tw-w-[100%] tw-h-[9.53px] tw-my-[32px]'>
              <Image src={WAVE_DIVIDER} alt='divider' fill={true} priority={true} />
            </div>
          )}

          <div className={`tw-text-TEXT_SECONDARY ${labelStyle ?? 'f-16-300 tw-mb-[24px]'}`}>
            {label ?? 'Please enter the 2 factor authentication code given by your authenticator app'}
          </div>

          <div className={otpInputWrapperClassName}>
            <OtpInput
              value={otp}
              setOtp={handleSetOtp}
              regex={DIGIT_REGEX}
              className={`${otpError ? 'tw-outline tw-outline-1 tw-outline-ERROR_RED' : 'tw-outline-0'}`}
              onEnter={handleOtpSubmit}
              wrapperClassName='tw-gap-[14px]'
              customStyles={`tw-w-[68px] tw-h-[68px] ${otpInputsType}`}
              autoFocus={autoFocus}
              {...otpInputProps}
            />
          </div>

          {otpError && (
            <div className='f-12-300 tw-text-RED_PRIMARY tw-text-left tw-flex'>
              <Image width={12} height={11} src={ALERT} alt='alert' className='tw-mr-[9px]' />
              {otpError}
            </div>
          )}
        </div>
      ) : (
        <div
          className={`${wrapperClassName} ${enableWrapperClass} tw-border tw-rounded-[10px] tw-w-full tw-bg-BLUE_7 tw-flex tw-items-center`}
        >
          <div className={`${enableIconWrapperClass}`}>
            <Image width={enableIconWidth} height={enableIconHeight} src={ENABLE_MFA} alt='' />
          </div>

          <div className=''>
            <div className={`${enableLabelClass} tw-text-TEXT_PRIMARY`}>2 Factor Authentication</div>
            <div className={`${enableDescriptionClass} tw-text-TEXT_SECONDARY `}>{enableDescription}</div>
            <Button
              buttonProps={{
                eventCallback: trackMixpanel,
                id: enableButtonId,
                btnType: BUTTON_TYPES.SECONDARY,
                size: BUTTON_SIZE_TYPES.SMALL,
                isLoading: loading,
                onClick: handleEnableMFA,
                wrapperClass: enableButtonClass,
              }}
            >
              Enable Now
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckMFA;
