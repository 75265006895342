import { FC } from 'react';
import { Label } from 'destiny/dist/components/molecules/label';
import { defaultFn } from 'destiny/dist/constants/index';
import TransferSuccessWrapper from 'modules/single-transfer/components/transferSuccessWrapper';
import SelectedAccounts from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedAccounts';
import SelectedRecipient from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedRecipient';
import { defaultFnType } from 'types';
import { CounterParty } from 'types/beneficiary-v2';

interface ThirdPartySuccessProps {
  recipientAccount?: any;
  sourceAccount?: any;
  amount?: any;
  currency?: any;
  onClose?: defaultFnType;
  isRecipientAccount?: boolean;
  counterParty?: CounterParty;
}

const ThirdPartySuccess: FC<ThirdPartySuccessProps> = ({
  recipientAccount = null,
  sourceAccount = null,
  amount = '',
  currency = '',
  onClose = defaultFn,
  counterParty,
}) => {
  return (
    <TransferSuccessWrapper onClose={onClose}>
      <div className='tw-w-full tw-bg-BASE_PRIMARY tw-rounded-2.5 tw-mb-8'>
        {counterParty && (
          <SelectedRecipient
            recipientAccount={recipientAccount}
            selectedCounterParty={counterParty}
            className='tw-my-4 tw-px-6'
          />
        )}
        <SelectedAccounts
          sourceAccount={sourceAccount}
          sourceProps={{
            showLabel: false,
            showTrailingSection: false,
            cardWrapperClassName: '!tw-p-0 !tw-bg-BASE_PRIMARY',
            wrapperStyle: 'tw-mb-0',
          }}
          wrapperClassName='tw-py-4 tw-px-6 tw-border-b tw-border-DIVIDER_GRAY'
        />

        <Label
          wrapperClass='tw-py-4 tw-px-6'
          title='Amount'
          titleClass='f-14-400 tw-text-TEXT_SECONDARY tw-mb-2'
          description={`${currency} ${amount}`}
          descriptionClass='f-20-400 tw-text-TEXT_PRIMARY'
        />
      </div>
    </TransferSuccessWrapper>
  );
};

export default ThirdPartySuccess;
