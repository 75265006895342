import { FC, ReactNode } from 'react';
import { PAYMENT_INITIATED } from 'constants/lottie/payment_initated';
import { ROUTES_PATH } from 'constants/routeConfig';
import { Text } from 'destiny/dist/components/atoms/text';
import { Button } from 'destiny/dist/components/molecules/button';
import { defaultFn } from 'destiny/dist/constants';
import { BUTTON_SIZE_TYPES, BUTTON_STATE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { useRouter } from 'next/router';
import { defaultFnType } from 'types';
import { trackMixpanel } from 'utils/mixpanel';
import Player from 'components/DynamicLottiePlayer';
import { ICONS } from 'components/icons/constants';
import IconWrapper from 'components/icons/IconWrapper';

interface TransferSuccessWrapperProps {
  onClose: defaultFnType;
  children: ReactNode;
  className?: string;
}

const TransferSuccessWrapper: FC<TransferSuccessWrapperProps> = ({ onClose = defaultFn, children, className = '' }) => {
  const router = useRouter();

  const handleViewTransactions = () => {
    router.push(ROUTES_PATH.TRANSACTIONS);
    onClose();
  };

  return (
    <div className={`tw-w-full tw-flex tw-flex-col tw-items-center tw-my-auto ${className}`}>
      <div className='tw-w-full tw-flex tw-flex-col tw-items-center tw-mb-8'>
        <Player src={PAYMENT_INITIATED} style={{ height: '146px', width: '146px' }} autoplay keepLastFrame />

        <Text textClass='f-20-400 tw-text-TEXT_PRIMARY'>Payment Initiated</Text>
      </div>

      {children}
      <Button
        buttonProps={{
          btnType: BUTTON_TYPES.PRIMARY,
          size: BUTTON_SIZE_TYPES.MEDIUM,
          state: BUTTON_STATE_TYPES.DEFAULT,
          wrapperClass: 'tw-w-60',
          onClick: handleViewTransactions,
          id: 'MOVE_MONEY_SINGLE_TRANSFER_SUCCESS_TO_TRANSACTIONS_NAV_BUTTON',
          eventCallback: trackMixpanel,
        }}
        showLeadingIcon={true}
        showTrailingIcon={true}
        customLeadingIcon={
          <IconWrapper icon={ICONS.COINS_03} color='#ffffff' width={20} height={20} viewBox='0 0 20 20' />
        }
        customTrailingIcon={
          <IconWrapper icon={ICONS.CHEVRON_RIGHT} color='#ffffff' width={20} height={20} viewBox='0 0 20 20' />
        }
      >
        View Transactions
      </Button>
    </div>
  );
};

export default TransferSuccessWrapper;
