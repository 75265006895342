import { FC, useEffect, useState } from 'react';
import { useCreateVaultMutation, useGetAllEntitiesAndAccountsQuery } from 'api/accounts';
import { REQUEST_CURRENCY, REQUEST_SENT } from 'constants/account';
import { CHEVRON_DOWN, CROSS_GRAY, SELECTED_GREEN } from 'constants/icons';
import { SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { Dropdown } from 'destiny/dist/components/organisms/dropdown';
import Input from 'destiny/dist/components/organisms/input';
import { defaultFn } from 'destiny/dist/constants';
import { useSessionToken } from 'hooks/useSessionToken';
import Image from 'next/image';
import { defaultFnType, MenuItem } from 'types';
import { CreateVaultPayload } from 'types/api';
import { InputOptionsType, Map } from 'types/kyb';
import { Entity } from 'types/romaAccounts';
import { getValuesfromDropwdown } from 'utils/common';
import { trackMixpanel } from 'utils/mixpanel';
import Currency from 'components/currency/Currency';
import { ICON_TYPE } from 'components/currency/types';
import Popup from 'components/popup/Popup';
import PopupActionFooter from 'components/popup/PopupActionFooter';
import PopupHeader from 'components/popup/PopupHeader';
import VaultNoCurrency from 'components/vault/vaultNoCurrency';

interface AddVaultProps {
  isOpen: boolean;
  handleVisibility: defaultFnType;
  setParentLoading: (flag: boolean) => void;
}

const FORM_KEYS: Record<string, string> = {
  VAULT_NAME: 'vault_name',
  ENTITY: 'entity',
  CURRENCIES: 'currencies',
};

const AddVault: FC<AddVaultProps> = ({
  isOpen = false,
  handleVisibility = defaultFn,
  setParentLoading = defaultFn,
}) => {
  const [formState, setFormState] = useState<Map>({});
  const [searchText, setSearchText] = useState<string>('');
  const [buttonText, setButtonText] = useState<string>(REQUEST_CURRENCY);
  const [showEntities, setShowEntities] = useState<boolean>(false);
  const [showCurrencies, setShowCurrencies] = useState<boolean>(false);

  const { data, isSuccess, isLoading } = useGetAllEntitiesAndAccountsQuery();
  const [createVault, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, isError: isCreateErr }] =
    useCreateVaultMutation();

  const { sessionToken, createSessionToken } = useSessionToken(SESSION_CAPABILITY_CONTEXT_KEYS.ADD_VAULT);

  const handleSubmit = () => {
    const data = {
      entity_id: formState[FORM_KEYS.ENTITY].value,
      name: formState[FORM_KEYS.VAULT_NAME],
      currency: getValuesfromDropwdown(formState[FORM_KEYS.CURRENCIES]),
    };

    const payload: CreateVaultPayload = { data, idempotencyHeader: sessionToken || '' };

    createVault(payload);
  };

  const updateFormState = (key: string, value: any) => {
    setFormState((prevValue) => {
      if (key === FORM_KEYS.ENTITY && showEntities)
        if (!showCurrencies) return { ...prevValue, [key]: value, [FORM_KEYS.CURRENCIES]: getCurrencyOptions(value) };
        else return { ...prevValue, [key]: value, [FORM_KEYS.CURRENCIES]: [] };
      else return { ...prevValue, [key]: value };
    });
  };

  const getEntityOptions = (entities: Entity[] = []): MenuItem[] => {
    const options: MenuItem[] = [];

    entities?.forEach((entity) => {
      options.push({
        label: entity.entity_name,
        value: entity.entity_id,
      });
    });

    return options;
  };

  const getCurrencyOptions = (preSelectedEntity?: MenuItem) => {
    const options: any = [];

    let selectedEntity: Entity = {
      entity_id: '',
      entity_name: '',
      currencies: [],
    };

    if (preSelectedEntity)
      data?.entities.forEach((entity) => {
        if (entity.entity_id === preSelectedEntity?.value) selectedEntity = entity;
      });
    else
      data?.entities.forEach((entity) => {
        if (entity.entity_id === formState[FORM_KEYS.ENTITY]?.value) selectedEntity = entity;
      });

    selectedEntity?.currencies?.forEach((item: InputOptionsType) => {
      options.push({
        label: item.value,
        value: item.id,
        icon: <Currency code={item.id} showName={false} iconSize={ICON_TYPE.SMEDIUM} />,
      });
    });

    return options;
  };

  const handleInputChange = (inputValue: string) => {
    setSearchText(inputValue);
    buttonText === REQUEST_SENT && setButtonText(REQUEST_CURRENCY);
  };

  const getDisabledState = () => {
    let completeData = false;
    const keys = Object.keys(FORM_KEYS);
    const lengthOfKeys = keys.length;

    if (formState)
      for (let i = 0; i < lengthOfKeys; i++) {
        const value = formState[FORM_KEYS[keys[i]]];

        if (Array.isArray(value) && value.length === 0) completeData = false;
        else completeData = !!value;

        if (!completeData) break;
      }

    return !completeData;
  };

  const entityAndCurrencyVisibility = (entities: Entity[]) => {
    const lengthOfEntities = entities?.length;
    const showEntities = lengthOfEntities > 1;
    let showCurrencies = !!lengthOfEntities && entities[0]?.currencies?.length > 1;

    if (showEntities)
      for (let i = 0; i < lengthOfEntities; i++) {
        showCurrencies = entities[i].currencies?.length > 1;
        if (showCurrencies) break;
      }

    return { showEntities, showCurrencies };
  };

  useEffect(() => {
    if (isSuccess) {
      const { showEntities, showCurrencies } = entityAndCurrencyVisibility(data.entities);

      !showEntities &&
        setFormState((prevValue) => {
          return { ...prevValue, [FORM_KEYS.ENTITY]: getEntityOptions(data.entities)[0] };
        });
      !showCurrencies &&
        setFormState((prevValue) => {
          const currencies = data.entities?.[0]?.currencies ?? [];
          const currency = Array.isArray(currencies) && currencies?.length > 0 && currencies[0];

          if (currency)
            return { ...prevValue, [FORM_KEYS.CURRENCIES]: [{ label: currency.value, value: currency.id }] };
        });
      setShowEntities(showEntities);
      setShowCurrencies(showCurrencies);
    }
  }, [isSuccess]);

  useEffect(() => {
    isCreateSuccess && handleVisibility();
  }, [isCreateSuccess]);

  useEffect(() => {
    isCreateErr && createSessionToken();
  }, [isCreateErr]);

  useEffect(() => setParentLoading(isLoading), [isLoading]);

  return (
    <Popup
      isOpen={isOpen && !isLoading}
      handleVisibility={handleVisibility}
      className='tw-bg-white tw-w-[476px] tw-pt-8 tw-pb-8 tw-px-12 tw-rounded-2.5'
    >
      <PopupHeader handleClose={handleVisibility} title='Add Account' />
      <Input
        showLabel
        labelProps={{ description: 'Account name' }}
        inputFieldWrapperClass='tw-w-full tw-mt-3'
        inputFieldProps={{
          size: 'SMALL',
          inputTagProps: {
            id: FORM_KEYS.VAULT_NAME,
            value: (formState && formState[FORM_KEYS.VAULT_NAME]) || '',
            onChange: (evt) => updateFormState(FORM_KEYS.VAULT_NAME, evt.target.value),
            eventId: 'ADD_ACCOUNT_NAME_TEXT_INPUT',
            eventCallback: trackMixpanel,
          },
        }}
      />
      {showEntities && (
        <Dropdown
          onChange={(item: any) => updateFormState(FORM_KEYS.ENTITY, item)}
          showLabel
          labelProps={{ description: 'Entity' }}
          options={getEntityOptions(data?.entities)}
          dropdownIndicator={<Image src={CHEVRON_DOWN} alt='dropdownIndicator' height={16} width={16} priority />}
          selectFieldWrapperClass='tw-w-full tw-mt-3'
          wrapperClass='tw-w-full tw-mt-4'
          selectedIcon={
            <Image src={SELECTED_GREEN} alt='selected' height={16} width={16} className='tw-ml-auto tw-mr-4' priority />
          }
          id='ACCOUNTS_ADD_VAULT_ENTITY_DROPDOWN'
          eventCallback={trackMixpanel}
        />
      )}
      {showCurrencies && (
        <Dropdown
          onChange={(item: any) => updateFormState(FORM_KEYS.CURRENCIES, item)}
          showLabel
          labelProps={{
            title: 'Select Currencies',
            description: 'We will create accounts for each of the selected currencies',
          }}
          options={getCurrencyOptions()}
          dropdownIndicator={<Image src={CHEVRON_DOWN} alt='dropdownIndicator' height={16} width={16} priority />}
          closeIcon={<Image src={CROSS_GRAY} alt='closeIcon' height={10} width={10} className='tw-ml-2' priority />}
          isMulti
          wrapperClass='tw-w-full tw-mt-8'
          selectFieldWrapperClass='tw-w-full tw-mt-4'
          handleInputChange={handleInputChange}
          customClassNames={{ noOptionsMessage: 'tw-rounded-[5px] tw-border-0' }}
          customStyles={{
            noOptionsMessage: {
              paddingTop: '16px',
              paddingBottom: '16px',
              color: '',
              backgroundColor: '#ffffff',
              borderRadius: '10px',
            },
            control: {
              padding: '12px 24px',
            },
            valueContainer: { rowGap: '10px', columnGap: '8px' },
            multiValue: { margin: 0 },
          }}
          noOptionsText={
            <VaultNoCurrency
              allowedCurrency={getValuesfromDropwdown(getCurrencyOptions())}
              allCurrency={getValuesfromDropwdown(getCurrencyOptions())}
              searchText={searchText}
              setButtonText={setButtonText}
              buttonText={buttonText}
              entityName={formState[FORM_KEYS.ENTITY]?.label}
            />
          }
          controlled
          value={formState[FORM_KEYS.CURRENCIES]}
          id='ACCOUNTS_ADD_VAULT_CURRENCY_DROPDOWN'
          eventCallback={trackMixpanel}
        />
      )}
      <PopupActionFooter
        handleSecondaryAction={handleVisibility}
        handlePrimaryAction={handleSubmit}
        primaryCtaTitle='Create'
        showPrimaryCta={true}
        isPrimaryCtaLoading={isCreateLoading}
        isPrimaryCtaDisabled={getDisabledState()}
        id='ACCOUNTS_ADD_VAULT'
      />
    </Popup>
  );
};

export default AddVault;
