import { type FC } from 'react';
import { BENEFICIARY_STATUS, BENEFICIARY_STATUS_CONFIGS } from 'modules/beneficiary-v2/beneficiary.constants';

const BeneficiaryBankAccountWalletStatusColumn: FC<{ data: string }> = ({ data }) => (
  <span
    className={`tw-px-2.5 tw-py-[1px] tw-uppercase ${
      BENEFICIARY_STATUS_CONFIGS[data as BENEFICIARY_STATUS]?.className
    }`}
  >
    {BENEFICIARY_STATUS_CONFIGS[data as BENEFICIARY_STATUS]?.label}
  </span>
);

export default BeneficiaryBankAccountWalletStatusColumn;
