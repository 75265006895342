import { PLATFORM_IDEMPOTENCY_HEADER_KEY } from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { Map, StringMap } from 'types/kyb';

const Consent = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getConsentPaymentsForm: builder.query<Map, void>({
      query: () => ({
        url: `v1/form/consent/payments`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getConsentByType: builder.query<StringMap, string>({
      query: (type) => ({
        url: `v1/b2b-user/consent/${type}`,
      }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_CONSENT_STATUS],
    }),
    submitConsent: builder.mutation<void, Map>({
      query: ({ data, headerKey }) => ({
        url: `v1/b2b-user/consent`,
        method: REQUEST_TYPES.POST,
        body: data,
        headers: {
          [PLATFORM_IDEMPOTENCY_HEADER_KEY]: headerKey,
        },
      }),
      invalidatesTags: [APITags.GET_CONSENT_STATUS],
    }),
  }),
});

export const { useGetConsentPaymentsFormQuery, useGetConsentByTypeQuery, useSubmitConsentMutation } = Consent;
