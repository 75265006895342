import { FC } from 'react';
import { RECIPIENT_ADDITION_KEYS } from 'constants/beneficiary';
import Currency from 'components/currency/Currency';
import DropdownWidget from 'components/form/jsonSchemaForm/uiSchemaWidgets/DropdownWidget';
import Wallet from 'components/wallet-item/Wallet';

const CustomDropdownWidget: FC<any> = (props = {}) => {
  const { name = '', schema = {} } = props;

  const updatedSchema = { ...schema };

  const isCurrency = RECIPIENT_ADDITION_KEYS.DESTINATION_CURRENCY === name;
  const isWallet = RECIPIENT_ADDITION_KEYS.NETWORK === name;

  if (isCurrency || isWallet)
    updatedSchema.enum = schema?.enum?.reduce((acc: any, item: any) => {
      acc.push({
        ...item,
        icon: isCurrency ? (
          <Currency code={item?.id} customIconDimensions={{ width: 24, height: 24 }} showName={false} hideTooltip />
        ) : isWallet ? (
          <Wallet wallet={item?.id} hideWalletName />
        ) : null,
      });

      return acc;
    }, []);

  const updatedProps = { ...props, schema: updatedSchema };

  return <DropdownWidget {...updatedProps} />;
};

export default CustomDropdownWidget;
