import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import mixpanelEvents from 'constants/mixpanel';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { useAppSelector } from 'hooks/toolkit';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { trackMixpanel } from 'utils/mixpanel';

interface TransferButtonProps {
  setShowMoveMoney: (flag: boolean) => void;
  open: boolean;
}

const TransferButtonV2: React.FC<TransferButtonProps> = ({ setShowMoveMoney, open }) => {
  const { isBankingKybSuccess } = useAppSelector((state: RootState) => state.kyb);
  const router = useRouter();

  const handleClick = () => {
    trackMixpanel(mixpanelEvents.TRANSFER_BUTTON_CLICK, { SourcePage: router.pathname });
    setShowMoveMoney(true);
  };

  const canDoTransfer =
    useUserAccessFromPermission(
      FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId,
      FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope
    ) && isBankingKybSuccess;

  if (!canDoTransfer) return null;

  return (
    <div
      onClick={handleClick}
      className='tw-cursor-pointer tw-bg-move-money-background tw-rounded-xl tw-h-[26px] tw-w-[114px]'
    >
      <div className='tw-pl-3 tw-pt-1 tw-pb-1.5 tw-pr-2 tw-flex tw-gap-1 tw-text-white f-12-400 tw-rounded-xl tw-bg-GREEN_PRIMARY tw-h-6 tw-w-28 tw-mb-0.5 tw-mx-px'>
        <div>Move money</div>
        <SvgSpriteLoader
          id='chevron-right'
          iconCategory={ICON_SPRITE_TYPES.ARROWS}
          width={16}
          height={16}
          color={COLORS.WHITE}
          className={`tw-ease-in-out tw-duration-300 ${open ? 'tw-rotate-90' : ''}`}
        />
      </div>
    </div>
  );
};

export default TransferButtonV2;
