import { FC } from 'react';
import { Label } from 'destiny/dist/components/molecules/label';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import MaskedAccountNumber from 'modules/accounts-mea/components/MaskedAccountNumber';
import TransferSuccessWrapper from 'modules/single-transfer/components/transferSuccessWrapper';
import { defaultFnType } from 'types';
import Currency from 'components/currency/Currency';
import { ICON_TYPE } from 'components/currency/types';

interface BulkPaymentSuccessProps {
  onClose: defaultFnType;
  amount: number;
  currency: string;
  currencyCode: string;
  totalPayments: number;
  sourceAccountDetails: { name: string; maskedNumber: string };
}

const BulkPaymentSuccess: FC<BulkPaymentSuccessProps> = ({
  onClose,
  amount,
  currency,
  currencyCode,
  totalPayments,
  sourceAccountDetails,
}) => (
  <TransferSuccessWrapper onClose={onClose} className='tw-h-[calc(100vh-60px)] tw-justify-center'>
    <div className='tw-bg-BASE_PRIMARY tw-rounded-2.5 tw-mb-8 tw-w-[390px] tw-py-4'>
      <div className='tw-px-6'>
        <div className='tw-flex tw-items-center tw-mb-6'>
          <SvgSpriteLoader id='arrow-right' iconCategory={ICON_SPRITE_TYPES.ARROWS} width={30} height={30} />
          <div className='tw-ml-4 f-16-400'>{totalPayments} Payments</div>
        </div>
        <div className='tw-flex tw-items-center tw-mb-4'>
          <Currency code={currencyCode} iconSize={ICON_TYPE.LARGE} showName={false} />
          <div className='tw-ml-4'>
            <Label
              title={`From ${sourceAccountDetails?.name}`}
              titleClass='f-14-400 tw-mb-1'
              description={<MaskedAccountNumber maskedAccountNumber={sourceAccountDetails?.maskedNumber} />}
              descriptionClass='f-12-300 tw-text-TEXT_PRIMARY'
            />
          </div>
        </div>
      </div>
      <Label
        wrapperClass='tw-pt-4 tw-border-t tw-px-6'
        title='Amount'
        titleClass='f-14-400 tw-text-TEXT_SECONDARY tw-mb-2'
        description={`${currency} ${amount}`}
        descriptionClass='f-20-400 tw-text-TEXT_PRIMARY'
      />
    </div>
  </TransferSuccessWrapper>
);

export default BulkPaymentSuccess;
