import SkeletonElement from 'components/skeletons/SkeletonElement';

const SKELETON_ARRAY = Array(3).fill('');

const RfqSummarySkeleton = ({ isQuoteAutoUpdated = false }) => {
  return (
    <>
      <div className='tw-mb-3'>
        <div className='tw-flex tw-items-center tw-justify-between tw-mb-3.5'>
          <SkeletonElement className='tw-w-[89px] tw-h-[14px]' />
          <SkeletonElement className='tw-w-[85px] tw-h-[20px]' />
        </div>

        <div className='tw-mb-1'>
          {SKELETON_ARRAY.map((_, index) => {
            return (
              <div className={`tw-w-full tw-flex tw-items-center tw-justify-between tw-mb-2`} key={index}>
                <SkeletonElement
                  className={`${
                    index === 0 ? 'tw-w-[52px]' : index === 1 ? 'tw-w-[136px]' : 'tw-w-[84px]'
                  } tw-h-[12px]`}
                />

                <SkeletonElement
                  className={`${index === 0 ? 'tw-w-[69px]' : index === 1 ? 'tw-w-[74px]' : 'tw-w-[97px]'} tw-h-[13px]`}
                />
              </div>
            );
          })}
        </div>

        <div className='tw-flex tw-items-center tw-justify-between tw-mb-[14.5px]'>
          <SkeletonElement className='tw-w-[79px] tw-h-[13px]' />
          <SkeletonElement className='tw-w-[79px] tw-h-[18px]' />
        </div>
      </div>

      {isQuoteAutoUpdated && <SkeletonElement className='tw-w-full tw-h-[69px] tw-rounded-2.5 tw-mb-3' />}
    </>
  );
};

export default RfqSummarySkeleton;
